import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getAttendanceStatistics = async () => {
  return axios
    .get(`${endpoints.EMPLOYEE_ATTENDACE_STATISTICS}`)
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const submitNewTimesheet = async (fromDate: string, toDate: string, EmployeeId: number) => {
  const params = {fromDate, toDate, EmployeeId}
  return axios
    .post(`${endpoints.SUBMIT_TIMESHEET}`, {}, {params})
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const getAttendanceTimesheet = async (
  fromDate: string,
  toDate: string,
  EmployeeId: number
) => {
  const params = {fromDate, toDate, EmployeeId}
  return axios
    .get(`${endpoints.GET_ATTENDANCE_TIMESHEET}`, {params})
    .then((response) => (response?.data ? JSON.parse(response.data) : null))
    .catch((error) => {
      console.log(error)
    })
}
