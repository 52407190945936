import React, {FC, ReactElement, useEffect, useRef, useState} from 'react'
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import makeAnimated from 'react-select/animated'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {getAllEmployeesByKita} from '../../../../../actions/employee'
import {MessageAttachModal} from '../components/message-attachement/MessageAttachModal'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useAuth} from '../../../auth'
import addNotification from 'react-push-notification'
import {
  getAllMessages,
  getAllMessagesCounts,
  getAllMessagesV2,
  getMessage,
  getSubMessages,
  sendNewMessage,
} from '../../../../../actions/messages'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import {getBase64} from '../../../../utils/getBase64'
import AvatarWithLetters from '../../../../general-components/avatar-with-letters'
import {selectStyle, selectStyleDarkMode} from '../../../../utils/select-style'
import moment from 'moment'
import {getKidContacts} from '../../../../../actions/contacts'
import SelectWithAllOption from '../../../../general-components/select-with-all/SelectWithAllOption'
import {UsageState} from 'webpack'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const animatedComponents = makeAnimated()

const style = {
  control: (base: any) => ({
    ...base,
    width: '100%',
    minWidth: '150px',
    border: 0,
    borderRaduis: '0.5rem',
    padding: '2px',
    boxShadow: 'none',
    backgroundColor: 'var(--bs-gray-100)',
    '&:hover': {
      borderColor: 'var(--bs-gray-100)',
    },
  }),
  menu: (provided: any) => ({...provided, zIndex: 9999}),
}

interface Props {
  parentId?: string
  senderId?: string
  showContact?: boolean
}
const ComposeMessage: FC<Props> = ({parentId, senderId, showContact}): ReactElement => {
  const [editor, setEditor] = useState<any>(null)
  const target = useRef(null)
  const params = useParams()
  const intl = useIntl()
  const {
    companies,
    setCompanies,
    employees,
    setEmployees,
    classes,
    setClasses,
    setKidsList,
    kidsList,
    allSubMessages,
    setAllSubMessages,
    setGlobalLoader,
    currentCompany,
    setContacts,
    contacts,
    setTabMessagesCounts,
  } = useAppContext()
  const {mode} = useThemeMode()
  const {currentUser, auth} = useAuth()
  const [selectedCompany, setSelectedCompany] = useState(companies[0])
  const [selectedEmployee, setSelectedEmployee] = useState<any>([])
  const [selectedClasses, setSelectedClasses] = useState<any>([])
  const [selectedContacts, setSelectedContacts] = useState<any>([])
  const [selectedKids, setSelectedKids] = useState<any>([])
  const [showAttachModal, setShowAttachModal] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [base64Files, setBase64Files] = useState([])
  const [selectError, setSelectError] = useState(true)
  const [showSelectError, setShowSelectError] = useState(false)
  const [highPriority, setHighPriority] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [audio] = useState(new Audio('/audio/message.mp3'))
  const [isLoading, setIsLoading] = useState(false)
  const [initalValues, setInitalValues] = useState({
    subject: '',
    message: '',
  })

  const schema = Yup.object().shape({
    subject: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    message: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
  })

  const formik = useFormik({
    initialValues: initalValues,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        setSubmitting(true)
        setIsSending(true)

        if (!selectError) {
          setShowSelectError(false)
          let classesIdList: any = [],
            kidIdList: any = [],
            employeeIdList: any = [],
            contactIdList: any = []

          selectedEmployee?.map((emp: any) => employeeIdList.push(emp.value))
          selectedClasses?.map((emp: any) => classesIdList.push(emp.value))
          selectedKids?.map((emp: any) => kidIdList.push(emp.value))
          selectedContacts?.map((emp: any) => contactIdList.push(emp.contactId))
          const data = {
            notification: {
              notificationTitle: values.subject,
              notificationTypeId: 1,
              message: values.message,
              companyId: currentCompany?.value,
              dateTime: moment().format('yyyy-MM-DDTHH:mm:ss'),
              notificationStatusId: 2,
              usersId: currentUser?.usersId,
              parentId: parentId || 0,
              highPriority,
            },
            acknowledgeRequired: true,
            classesIdList: classesIdList,
            kidIdList,
            contactIdList: contactIdList,
            employeeIdList: employeeIdList,
            attachmentList: base64Files,
          }

          const resp: any = await sendNewMessage(data)

          if (!!resp) {
            await readMessages()
            audio.play()
            if ('vibrate' in navigator) {
              // vibration API supported
              navigator.vibrate(1000)
            }
            if (params?.id) {
              readSubMessages()
            }
            addNotification({
              title: 'Success',
              subtitle: intl.formatMessage({id: 'COMMON.SUCCESS'}),
              message: intl.formatMessage({id: 'COMMON.SUCCESS'}),
              theme: 'darkblue',
              native: true, // when using native, your OS will handle theming.
            })

            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            setSelectedKids(null)
            setSelectedClasses(null)
            setSelectedEmployee(null)
            formik.resetForm()
            setBase64Files([])
            setSelectedFiles([])
          } else {
            toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
          }
        } else {
          setShowSelectError(true)
        }
      } catch (ex) {
        console.log(ex)
      } finally {
        setSubmitting(false)
        setIsSending(false)
      }
    },
  })

  useEffect(() => {
    if (
      selectedContacts?.length === 0 &&
      selectedClasses?.length === 0 &&
      selectedEmployee?.length === 0 &&
      selectedKids?.length === 0
    )
      setSelectError(true)
    else setSelectError(false)
  }, [selectedContacts, selectedClasses, selectedEmployee, selectedKids])

  useEffect(() => {
    readEmployees()
  }, [])

  useEffect(() => {
    for (const emp of employees) {
      if (emp?.usersId === senderId) {
        setSelectedEmployee([emp])
      }
    }
  }, [senderId, employees])

  useEffect(() => {
    const convertToBase64 = async () => {
      let temp: any = []
      selectedFiles.map(async (singleFile: any) => {
        const nameWithExt = singleFile.name.split('.')
        const ext = nameWithExt[nameWithExt.length - 1]

        getBase64(singleFile, (result: string) => {
          temp.push({fileName: singleFile.name.split('.')[0], imagePath: result, extention: ext})
        })
      })
      setBase64Files(temp)
    }
    convertToBase64()
  }, [selectedFiles])

  useEffect(() => {
    if (parentId && allSubMessages.length > 0)
      setInitalValues((prev) => ({
        ...prev,
        subject: `AW-` + allSubMessages[0].notificationTitle,
      }))
  }, [parentId, allSubMessages])

  const readEmployees = async () => {
    setIsLoading(true)
    let filter = '('
    currentUser?.employee?.companyIds?.map((id: number) => {
      if (filter !== '(') filter += ' OR '
      filter += ` CompanyIds.Any(x => x == ${id}) `
    })
    filter += ')'
    const resp = await getAllEmployeesByKita(
      `employeeReceiveCommunicationFromEmployees=true AND ${filter} `
    )
    let temp: any = []
    resp?.map((item: any) =>
      temp.push({
        value: item.employeeId,
        label: item.employeeName,
        email: item.email,
        imagePath: item.imagePath,
        usersId: item.usersId,
      })
    )

    setEmployees(temp)
    setIsLoading(false)
  }

  const removeFile = (file: any) => {
    const temp = selectedFiles.filter((item: any) => item !== file)
    setSelectedFiles(temp)
  }

  const readMessages = async (filter?: string) => {
    const resp1 = await getAllMessagesCounts(currentUser?.usersId ?? 0)
    setTabMessagesCounts(resp1)
  }

  const readSubMessages = async () => {
    try {
      setGlobalLoader(true)
      const message = await getMessage(`notificationId=${params.id}`)

      if (message?.length > 0) {
        const subMessages = await getSubMessages({
          userId: currentUser?.usersId,
          parentId: params.id,
        })
        setAllSubMessages([...message, ...subMessages])
      }
    } catch (e) {
      console.log(e)
    } finally {
      setGlobalLoader(false)
    }
  }

  const readContacts = async () => {
    const resp = await getKidContacts(`companyId=${currentCompany.value} AND Email!=null`)

    setContacts(resp)
  }

  return (
    <div className='card'>
      {!parentId && (
        <div className='card-header'>
          <div className='card-title'>
            {intl.formatMessage({id: 'KIDLING.HOME.COMPOSE_MESSAGE'})}
          </div>
        </div>
      )}

      <div className='card-body p-1 m-0 m-sm-2'>
        <form
          id='kt_inbox_reply_form'
          className={`rounded ${parentId && 'border-top mt-md-5'}`}
          onSubmit={formik.handleSubmit}
        >
          {showSelectError && (
            <div className='alert alert-danger'>
              {intl.formatMessage({
                id: 'KIDLING.MESSAGE.YOU_NEED_TO_SELECT_AT_LEAST_SINGLE_GROUP_EMPLOYEE_OR_KID',
              })}
            </div>
          )}
          <div className='d-flex align-items-center w-100 flex-wrap'>
            <label className='form-check form-switch form-check-custom form-check-solid   p-6'>
              <input
                className='form-check-input w-30px h-20px'
                type='checkbox'
                name='notifications'
                onChange={() => setHighPriority(!highPriority)}
              />
              <span className='form-check-label text-dark fs-7'>
                {intl.formatMessage({id: 'KIDLING.HIGH.PRIORITY'})}
              </span>
            </label>
          </div>

          <div className='d-block p-2'>
            {/* <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
              <div className='text-dark fw-bold w-100px'>
                {intl.formatMessage({id: 'KIDLING.COMPANY'})}:
              </div>

              <Select
                openMenuOnFocus={true}
                value={selectedCompany}
                onChange={(val: any) => setSelectedCompany(val)}
                options={companies}
                isClearable={true}
                components={animatedComponents}
                placeholder='Select company'
                isLoading={!companies || companies?.length === 0}
                formatOptionLabel={(item: any) => (
                  <div className='d-flex align-items-center '>
                    <div className='d-flex flex-column'>
                      <p className='p-0 m-0'>{item.companyName}</p>
                    </div>
                  </div>
                )}
                isDisabled
                className='w-100'
                styles={selectStyle}
                theme={(theme) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
              />
            </div> */}

            <div className='d-flex align-items-center   px-0 min-h-50px'>
              <SelectWithAllOption
                openMenuOnFocus={true}
                value={selectedEmployee}
                onChange={(val: any) => setSelectedEmployee(val)}
                options={employees}
                isClearable={true}
                components={animatedComponents}
                isMulti
                allowSelectAll
                isLoading={isLoading}
                formatOptionLabel={(item: any) => (
                  <div className='d-flex align-items-center '>
                    {item.value !== '*' && (
                      <AvatarWithLetters
                        image={item.imagePath}
                        alt={`${item.label[0].charAt(0).toUpperCase()}  `}
                        rounded
                        extrasmall
                      />
                    )}

                    <div className='d-flex flex-column ms-2  '>
                      <p className='p-0 m-0'>{item.label}</p>
                      <p className='p-0 m-0 text-dark'>{item.email}</p>
                    </div>
                  </div>
                )}
                className='w-100'
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme: any) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                placeholder={intl.formatMessage({id: 'KIDLING.EMPLOYEE'})}
              />
            </div>

            <div className='border-bottom mt-4 '>
              <input
                className='form-control border-3 border-primary px-4 min-h-50px required mb-2 fs-4'
                placeholder={intl.formatMessage({id: 'KIDLING.HOME.SUBJECT'})}
                name='subject'
                onChange={formik.handleChange}
                value={formik.values.subject}
              />
              {formik.touched.subject && formik.errors.subject && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.subject}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='z-0'>
              <CKEditor
                editor={ClassicEditor}
                data={formik.values.message}
                onReady={(editor: any) => setEditor(editor)}
                onChange={(event: any, editor: any) => {
                  const data = editor.getData()
                  formik.setFieldValue('message', data)
                  editor?.focus()
                }}
                config={{
                  toolbar: [
                    'Heading',
                    'bold',
                    'italic',
                    'link',
                    '|',
                    'bulletedList',
                    'numberedList',
                    'todoList',
                    'alignment',
                    '|',
                    'blockQuote',
                    'insertTable',

                    'undo',
                    'redo',
                  ],
                }}
              />

              {formik.touched.message && formik.errors.message && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.message}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='d-flex flex-column py-4'>
            {selectedFiles.map((selectedFile: any) => (
              <div className='d-flex align-items-center  '>
                <i className='bi bi-paperclip mx-1'></i>
                <p className='m-0'>{selectedFile.name}</p>
                <button className='btn' type='button' onClick={() => removeFile(selectedFile)}>
                  <i className='bi bi-trash-fill text-danger'></i>
                </button>
              </div>
            ))}
          </div>
          <div className='d-flex flex-stack flex-wrap gap-2 py-5 ps-8 pe-5 border-top'>
            <div className='d-flex align-items-center me-3'>
              <div className='btn-group me-4'>
                <button
                  type='submit'
                  className='btn btn-primary d-flex aling-items-center'
                  data-kt-users-modal-action='submit'
                  disabled={formik.isSubmitting}
                >
                  {!isSending && (
                    <span className='indicator-label mx-4'>
                      {intl.formatMessage({id: 'KIDLING.HOME.SEND'})}
                    </span>
                  )}
                  {isSending && (
                    <span className=''>
                      {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>

              <OverlayTrigger
                placement='top'
                trigger='click'
                rootClose
                overlay={
                  <Popover>
                    <Picker
                      data={data}
                      onEmojiSelect={(data: any) => {
                        editor.model.change((writer: any) => {
                          const selection = editor.model.document.selection
                          const currentAttributes = selection.getAttributes()
                          const insertPosition = selection.getFirstPosition()
                          writer.insertText(data.native, currentAttributes, insertPosition)
                        })
                      }}
                    />
                  </Popover>
                }
              >
                <button className='btn px-2' type='button'>
                  <i className='fa-regular fa-face-smile fs-2 text-primary'></i>
                </button>
              </OverlayTrigger>
              <button
                className='btn px-2'
                type='button'
                onClick={() => setShowAttachModal(!showAttachModal)}
              >
                <i className='fa-solid fa-paperclip fs-3 m-0'></i>
              </button>
            </div>
          </div>
        </form>
      </div>
      {showAttachModal && (
        <MessageAttachModal
          setShowAttachModal={setShowAttachModal}
          setSelectedFiles={setSelectedFiles}
        />
      )}
    </div>
  )
}

export default ComposeMessage
