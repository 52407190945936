import moment from 'moment'
import React, {FC, ReactElement, useState} from 'react'
import {useIntl} from 'react-intl'
import {readFileFrom} from '../../../../../../actions/image'
import {decodeBase64} from '../../../../../utils/getBase64'
interface Props {
  employeeShiftFileName: string
  filePath: string
  fromDate: string
  lastUpdateDateTime: string
  toDate: string
}
const ShiftFileCard: FC<Props> = ({
  employeeShiftFileName,
  filePath,
  fromDate,
  lastUpdateDateTime,
  toDate,
}): ReactElement => {
  const intl = useIntl()
  const [isReading, setIsReading] = useState(false)
  const openFile = async () => {
    try {
      setIsReading(true)
      const resp = await readFileFrom(filePath)
      const type = resp.split(';')[0].split(':')[1]

      const name = filePath.split('/')[2].split('___')[0]
      const blob = await decodeBase64({base64: resp, name: name, type: type})

      const url = URL.createObjectURL(blob)
      window.open(url)
      setIsReading(false)

      if ((window as any)['ReactNativeWebView'])
        (window as any)['ReactNativeWebView'].postMessage(
          JSON.stringify({file: {id: filePath, base64: resp}})
        )
    } catch (e) {
      setIsReading(false)
      console.log(e)
    }
  }
  return (
    <div className='card-header d-flex align-items-center justify-content-between'>
      <div className='border-start border-4 border-primary ps-2'>
        <h5 className='m-0 p-0 '>{employeeShiftFileName}</h5>
        <p className='m-0 p-0 text-body'>
          {moment(fromDate).format('DD.MM.YYYY')} - {moment(toDate).format('DD.MM.YYYY')}
        </p>
      </div>
      <button
        type='button'
        className='btn btn-sm btn-primary'
        disabled={isReading}
        onClick={() => openFile()}
      >
        {!isReading && intl.formatMessage({id: 'KIDLING.HOME.DOWNLOAD'})}
        {isReading && (
          <span className='indicator-progress ' style={{display: 'inline-block'}}>
            {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
  )
}

export default ShiftFileCard
