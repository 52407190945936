import React, {ReactElement, FC, useEffect, useState} from 'react'
import moment from 'moment'
import {Avatar, AvatarGroup} from '@mui/material'
import {useIntl} from 'react-intl'
import './styles.scss'
import SweetAlert from 'react-bootstrap-sweetalert'
import {toast} from 'react-toastify'
import GroupMembersDialog from '../GroupMembersDialog'
import ReactPlayer from 'react-player'
import {DownloadChatFile, deleteChatMessage, getAllUserChats} from '../../../../../../actions/chat'
import {decodeBase64} from '../../../../../utils/getBase64'
import DropDownMenu from '../../../../../general-components/dropDownMenu/DropDownMenu'
import ImageWithEnlargeChat from '../../../../../general-components/image-with-enlarge-chat'
import ChatDocument from '../../../../../general-components/Chat-Document/ChatDocument'
import Linkify from '../../../../../general-components/Linkify/Linkify'
import ErrorText from '../../../../../general-components/error-text/ErrorText'
import {useAuth} from '../../../../auth'
import {useParams} from 'react-router-dom'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {useChatContext} from '../../../../../context/ChatProvider'

interface MessageProps {
  id: number
  name: string
  text: string
  img: string
  date: string
  isMine: boolean
  isSent?: boolean
  isRead?: boolean
  userId: number
  mineColor: string
  filePath?: string
  showAvatar?: boolean
  fileExtension: string
  seenByUsers?: any
}
const ChatMessage: FC<MessageProps> = ({
  id,
  name,
  text,
  img,
  date,
  isMine,
  isSent,
  isRead,
  userId,
  mineColor,
  filePath,
  showAvatar,
  fileExtension,
  seenByUsers,
}): ReactElement => {
  const intl = useIntl()
  const {currentUserImage, setGlobalLoader} = useAppContext()
  const {chatDetails, allChats, setAllChats} = useChatContext()
  const [isLoading, setIsLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [showDelete, setShowDelete] = useState(false)
  const [showMembersDialog, setShowMembersDialog] = useState(false)
  const {currentUser} = useAuth()
  const [seenUsers, setSeenUsers] = useState([])
  const params = useParams()

  useEffect(() => {
    if (filePath) openFile(filePath)
  }, [filePath])

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => URL.revokeObjectURL(imageUrl)
  }, [])

  useEffect(() => {
    if (params?.id && chatDetails?.chatGroupId !== params?.id) {
      const users = allChats?.find((i: any) => i?.chatGroupId === Number(params?.id))?.users

      let temp: any = []
      seenByUsers?.forEach((user: any) => {
        const newUser = users?.find((i: any) => i?.usersId === user?.usersId)
        temp.push({...user, ...newUser})
      })

      setSeenUsers(temp)
    }
  }, [params?.id, allChats, seenByUsers])

  const openFile = async (id: string) => {
    try {
      setIsLoading(true)

      const resp: any = await DownloadChatFile(id)

      const blob = await decodeBase64({
        base64: resp,
        name: filePath ?? '',
        type: fileExtension === 'mp4' ? 'video/mp4' : fileExtension,
      })

      const url = URL.createObjectURL(blob)

      setImageUrl(url)

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      console.log(e)
    }
  }

  const chatOptions = isMine
    ? [
        {
          title: (
            <button className='btn p-0 m-0' type='button'>
              <div className='d-flex align-items-center '>
                <i className='fa-solid fa-trash fs-6 text-danger me-2' />
                <span className='text-body'>{intl.formatMessage({id: 'COMMON.DELETE'})}</span>
              </div>
            </button>
          ),

          onClick: () => setShowDelete(true),
        },
      ]
    : []
  const optionsDropDownMenu = {
    title: intl.formatMessage({id: 'KIDLING.CHAT_SETTINGS'}),
    menuButton: <i className='fa fa-angle-down fs-6  '></i>,
    items: filePath
      ? [
          ...chatOptions,
          {
            title: (
              <a href={imageUrl} className='btn p-0 m-0 ' target='_blank' rel='noopener noreferrer'>
                <div className='d-flex align-items-center'>
                  <i className='fa-solid fa-download fs-6 me-2' />
                  <span>{intl.formatMessage({id: 'KIDLING.CHAT.DOWNLOAD'})}</span>
                </div>
              </a>
            ),
          },
        ]
      : chatOptions,
  }

  const DoDelete = async () => {
    const resp = await deleteChatMessage(id)
    if (resp) toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
  }
  const readAllChats = async () => {
    setGlobalLoader(true)
    const resp = await getAllUserChats()
    setAllChats(resp)
    // setCurrentChats(resp)
    setGlobalLoader(false)
  }
  return (
    <div
      className={` ${isMine ? 'mine__message' : 'notMine__message'} ${
        showAvatar ? 'message' : 'compressed__messages'
      }`}
    >
      {showAvatar ? (
        <Avatar
          className='mx-1'
          alt={name?.charAt(0).toUpperCase()}
          src={isMine ? currentUserImage : `${process.env.REACT_APP_API_URL}/users/Image/${userId}`}
        />
      ) : (
        <div className='w-40px h-40px ms-2'></div>
      )}

      <div
        className={`card__content shadow ${
          isMine ? 'card__content__mine' : 'card__content__notMine'
        }`}
        style={{backgroundColor: isMine ? '#8ED6EC' : ''}}
      >
        <div className='top__line'>
          {showAvatar && <h5 className='title'>{isMine ? currentUser?.usersName : name}</h5>}
          <div className='ms-auto'>
            {optionsDropDownMenu.items?.length > 0 && <DropDownMenu data={optionsDropDownMenu} />}
          </div>
        </div>
        {filePath ? (
          <div className='position-relative w-200px h-150px'>
            {fileExtension === 'jpeg' ||
            fileExtension === 'png' ||
            fileExtension === 'jpg' ||
            fileExtension === 'webp' ? (
              <ImageWithEnlargeChat img={isLoading ? '/empty-image.png' : imageUrl} />
            ) : fileExtension === 'pdf' ? (
              <ChatDocument title='' icon='/media/svg/files/pdf.svg' />
            ) : fileExtension === 'docx' ? (
              <ChatDocument title='' icon='/media/svg/files/doc.svg' />
            ) : fileExtension === 'mp4' ? (
              <ReactPlayer
                url={imageUrl}
                width='100%'
                height='100%'
                controls={true}
                config={{
                  file: {
                    attributes: {
                      crossOrigin: 'true',
                    },
                  },
                }}
              />
            ) : (
              <ChatDocument title='' icon='/media/svg/files/upload.svg' />
            )}

            {/* <img
              src={isLoading ? '/empty-image.png' : imageUrl}
              alt='image'
              className='w-200px h-150px object-contain'
            /> */}
            {isLoading && (
              <span
                className='position-absolute top-50   translate-middle-y  '
                style={{left: '48%'}}
              >
                <span className='spinner-border spinner-border-lg align-middle text-primary'></span>
              </span>
            )}
          </div>
        ) : (
          <p className={`${isMine ? 'text-dark' : 'text-muted'}`}>
            <Linkify>{text}</Linkify>
          </p>
        )}
        <div className='footer'>
          <p className='date my-0 mx-2'>{date ? moment(date).format('DD.MM.yyyy HH:mm') : ''}</p>
          {isRead && isMine && (
            <AvatarGroup
              max={3}
              onClick={() =>
                chatDetails?.users?.length > 2 && setShowMembersDialog(!showMembersDialog)
              }
              sx={{
                '& .MuiAvatar-root': {width: 15, height: 15, fontSize: 8},
              }}
            >
              {seenByUsers?.map(
                (av: any, index1: number) =>
                  av.chatGroupMessageStatusReadDatetime && (
                    <Avatar
                      alt={av.usersName?.charAt(0).toUpperCase()}
                      key={index1}
                      src={av?.imageUrl}
                    />
                  )
              )}
            </AvatarGroup>
          )}
        </div>
      </div>
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          DoDelete()
        }}
        onCancel={() => setShowDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
      <GroupMembersDialog
        showModal={showMembersDialog}
        setShowModal={setShowMembersDialog}
        title={intl.formatMessage({id: 'KIDLING.SEEN_BY'})}
        users={seenUsers?.filter((user: any) => user?.chatGroupMessageStatusReadDatetime !== null)}
        hideAdd
        readAllChats={readAllChats}
      />
    </div>
  )
}

export default ChatMessage
