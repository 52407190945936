import {useEffect, useState} from 'react'
import {useAuth} from '../modules/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useNavigate, useSearchParams} from 'react-router-dom'
import moment from 'moment'
import {
  addEmployeeCheckin,
  addEmployeeCheckout,
  getEmployeeAttendanceByDates,
} from '../../actions/employee'
import {ATTENDACE_TYPE_ID} from '../../config/constants'
import {toast} from 'react-toastify'
import {getAttendanceStatistics, getAttendanceTimesheet} from '../../actions/attendace'
import {useIntl} from 'react-intl'

const useEmployeeAttendance = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [searchParams] = useSearchParams()

  const {
    setTodayEmployeeAttendance,
    todayEmployeeAttendance,
    setEmployeeAttendaceStatistics,
    setCurrentTimesheet,
    mobileConfiguration,
  } = useAppContext()
  const [time, setTime] = useState({initialHours: 0, initialMinute: 0, initialSeconds: 0})
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isRunning, setIsRunning] = useState(false)
  const [isBrek, setIsBrek] = useState(false)
  const [breakRunning, setBreakRunning] = useState(false)
  const [resetTimer, setResetTimer] = useState(false)
  const [isLoading1, setIsLoading1] = useState(false)

  useEffect(() => {
    if (
      !(
        (mobileConfiguration[2]?.length > 0 && mobileConfiguration[2][0]?.showInHrApp) ||
        (mobileConfiguration[3]?.length > 0 && mobileConfiguration[3][0]?.showInHrApp) ||
        (mobileConfiguration[4]?.length > 0 && mobileConfiguration[4][0]?.showInHrApp)
      )
    ) {
      navigate('/')
    }
  }, [mobileConfiguration])

  useEffect(() => {
    setIsRunning(false)
    setTime({initialHours: 0, initialMinute: 0, initialSeconds: 0})
    if (
      todayEmployeeAttendance &&
      todayEmployeeAttendance?.groupedEmployeeAttendances &&
      todayEmployeeAttendance?.groupedEmployeeAttendances?.length > 0 &&
      todayEmployeeAttendance?.groupedEmployeeAttendances[0]?.attendances?.length > 0 &&
      !todayEmployeeAttendance?.groupedEmployeeAttendances[0]?.attendances[0]?.out
    ) {
      const att = todayEmployeeAttendance?.groupedEmployeeAttendances[0]?.attendances
      const totalSeconds = moment
        .duration(
          moment().diff(
            moment(
              moment(att[0].date)
                .set('hours', moment(att[0].in).hours())
                .set('minutes', moment(att[0].in).minutes())
            )
          )
        )
        .asSeconds()

      const elapsedSeconds = totalSeconds < 0 ? 0 : Math.floor(Number(totalSeconds) % 60)
      const elapsedMinutes = totalSeconds >= 60 ? Math.floor((Number(totalSeconds) / 60) % 60) : 0
      const elapsedHours = totalSeconds >= 3600 ? Math.floor(Number(totalSeconds) / 3600) : 0

      setTime({
        initialHours: elapsedHours,
        initialMinute: elapsedMinutes,
        initialSeconds: elapsedSeconds,
      })
      if (att[0].attendanceTypeId === 1) {
        setIsRunning(true)
        setIsBrek(false)
        setBreakRunning(false)
      } else {
        setIsRunning(false)
        setIsBrek(true)
        setBreakRunning(true)
      }
    }
  }, [todayEmployeeAttendance])

  const addAttendance = async () => {
    const resp = await addEmployeeCheckin(ATTENDACE_TYPE_ID.checkin)
    if (!!resp) {
      setIsRunning(true)
      readEmployeeAttendance(
        `${moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
        `${moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')}`
      )
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      setResetTimer(true)
      setTime({initialHours: 0, initialMinute: 0, initialSeconds: 0})
      setResetTimer(false)
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  const addCheckout = async () => {
    setIsLoading1(true)
    setResetTimer(true)

    const resp = await addEmployeeCheckout()
    if (!!resp) {
      setTime({initialHours: 0, initialMinute: 0, initialSeconds: 0})
      setResetTimer(false)
      readEmployeeAttendance(
        `${moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
        `${moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')}`
      )
      readEmployeeStatistics()
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      setIsLoading1(false)
      setIsRunning(false)
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }
  const addBreak = async () => {
    setIsLoading(true)
    setResetTimer(true)
    setTime({initialHours: 0, initialMinute: 0, initialSeconds: 0})
    //clock out first

    if (!breakRunning) {
      setIsBrek(true)
      setBreakRunning(true)
      const resp = await addEmployeeCheckout()
      if (resp) {
        const resp1 = await addEmployeeCheckin(ATTENDACE_TYPE_ID.break)

        if (!!resp1) {
          readEmployeeAttendance(
            `${moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
            `${moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')}`
          )
          readEmployeeStatistics()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      }
      setResetTimer(false)
    } else {
      setBreakRunning(false)

      const resp = await addEmployeeCheckout()
      if (resp) {
        const resp1 = await addEmployeeCheckin(ATTENDACE_TYPE_ID.checkin)
        if (!!resp1) {
          readEmployeeAttendance(
            `${moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
            `${moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')}`
          )
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          setIsBrek(false)
          setIsRunning(true)
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      }
      setResetTimer(false)
    }

    setIsRunning(false)

    setIsLoading(false)
  }

  const readEmployeeAttendance = async (startDate: string, endDate: string) => {
    const resp = await getEmployeeAttendanceByDates(startDate, endDate)
    if (resp) {
      setTodayEmployeeAttendance(resp)
    }
  }
  const readEmployeeStatistics = async () => {
    const resp = await getAttendanceStatistics()

    setEmployeeAttendaceStatistics(resp)
  }

  const readTimeSheet = async (startDate: string, endDate: string) => {
    const resp = await getAttendanceTimesheet(
      startDate,
      endDate,
      currentUser?.employee?.employeeId || 0
    )

    setCurrentTimesheet(resp)
  }

  return {
    readEmployeeAttendance,
    readEmployeeStatistics,
    readTimeSheet,
    isRunning,
    isBrek,
    isLoading,
    resetTimer,
    time,
    breakRunning,
    addBreak,
    isLoading1,
    addCheckout,
    addAttendance,
  }
}

export default useEmployeeAttendance
