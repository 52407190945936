import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import AddVacationModal from './AddVacationModal'
import VactaionYearFilter from './VactaionYearFilter'
import VacationsList from './VacationsList'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import useAbsence from '../../../../../hooks/useAbsence'

function Vacations() {
  const company = window.location.pathname.split('/')[1]
  const intl = useIntl()

  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const {employeeAbsenceStatistics, absenceAdjustments, mobileConfiguration} = useAppContext()
  const {readStatistics, readAbsenceAdjustments} = useAbsence()
  useEffect(() => {
    if (mobileConfiguration[1]?.length > 0 && !mobileConfiguration[1][0]?.showInHrApp) {
      navigate('/')
    }
  }, [mobileConfiguration])

  useEffect(() => {
    readStatistics()
    readAbsenceAdjustments()
  }, [])

  return (
    <div className='card '>
      <div className='card-header align-items-center justify-content-between  py-5 gap-5 px-2'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Link to={company ? `/${company}/employee/main` : '/kidling/employee/main'}>
            <i className='fa-solid fa-arrow-left fs-3 mx-4 '></i>
          </Link>

          <button
            className='btn ms-auto d-flex align-items-center'
            type='button'
            onClick={() => setShow(true)}
          >
            <i className='fa-solid fa-plus ms-2 fs-3'></i>
          </button>
        </div>
      </div>
      <div className='card-body px-2'>
        <div className='my-6'>
          {employeeAbsenceStatistics && (
            <div className='d-flex flex-column align-items-center shadow my-2 rounded-top'>
              <h5 className='bg-primary text-white w-100 m-0 p-0 text-center py-2 rounded-top'>
                {employeeAbsenceStatistics?.employeeAbsencePolicyName}
              </h5>
              <div className='row w-100 mt-8 border-bottom'>
                <div className='col-4'>
                  <div className='d-flex flex-column align-items-center'>
                    <h1 className='  text-body' style={{fontSize: '1.8rem'}}>
                      {employeeAbsenceStatistics?.accrued
                        ? employeeAbsenceStatistics?.accrued.toFixed(2)
                        : '--'}
                    </h1>
                    <p className='fs-7  text-body text-center'>
                      {intl.formatMessage({id: 'KIDLING.HR.TOTAL_VACATION_DAYS'})}
                    </p>
                  </div>
                </div>
                <div className='col-4'>
                  <div className='d-flex flex-column align-items-center '>
                    <h1 className=' text-body' style={{fontSize: '1.8rem'}}>
                      {employeeAbsenceStatistics?.available
                        ? employeeAbsenceStatistics?.available.toFixed(2)
                        : '--'}
                    </h1>
                    <p className='fs-7  text-body text-center'>
                      {intl.formatMessage({id: 'KIDLING.HR.AVAILABE_VACATION_DAYS'})}
                    </p>
                  </div>
                </div>

                <div className='col-4'>
                  <div className='d-flex flex-column align-items-center '>
                    <h1 className=' text-body' style={{fontSize: '1.8rem'}}>
                      {employeeAbsenceStatistics?.taken !== 'undefiend'
                        ? employeeAbsenceStatistics?.taken.toFixed(2)
                        : '--'}
                    </h1>
                    <p className='fs-7   text-body text-center'>
                      {intl.formatMessage({id: 'KIDLING.HR.TAKEN_VACATION_DAYS'})}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {absenceAdjustments &&
            absenceAdjustments?.map((item: any, index: number) => (
              <div
                className='d-flex flex-column align-items-center shadow my-2 rounded-top'
                key={index}
              >
                <h5 className='bg-primary text-white w-100 m-0 p-0 text-center py-2 rounded-top'>
                  {item?.employeeAbsenceEntitlementName}
                </h5>
                <div className='row w-100 mt-8 border-bottom'>
                  <div className='col-4'>
                    <div className='d-flex flex-column align-items-center'>
                      <h1 className='  text-body' style={{fontSize: '1.8rem'}}>
                        {item?.accrued ? item?.accrued.toFixed(2) : '--'}
                      </h1>
                      <p className='fs-7  text-body text-center'>
                        {intl.formatMessage({id: 'KIDLING.HR.TOTAL_VACATION_DAYS'})}
                      </p>
                    </div>
                  </div>
                  <div className='col-4'>
                    <div className='d-flex flex-column align-items-center '>
                      <h1 className=' text-body' style={{fontSize: '1.8rem'}}>
                        {item?.available ? item?.available.toFixed(2) : '--'}
                      </h1>
                      <p className='fs-7  text-body text-center'>
                        {intl.formatMessage({id: 'KIDLING.HR.AVAILABE_VACATION_DAYS'})}
                      </p>
                    </div>
                  </div>

                  <div className='col-4'>
                    <div className='d-flex flex-column align-items-center '>
                      <h1 className=' text-body' style={{fontSize: '1.8rem'}}>
                        {item?.taken !== 'undefiend' ? item?.taken.toFixed(2) : '--'}
                      </h1>
                      <p className='fs-7   text-body text-center'>
                        {intl.formatMessage({id: 'KIDLING.HR.TAKEN_VACATION_DAYS'})}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <VactaionYearFilter />
        <VacationsList />
      </div>
      <AddVacationModal show={show} setShow={setShow} />
    </div>
  )
}

export default Vacations
