/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'

import {
  readDaysOff,
  readEventsCategory,
  readHolidays,
  readOpeningHours,
} from '../../../actions/events'

import {useAuth} from '../../modules/auth'
import {getAllMessagesCounts, getAllMessagesV2} from '../../../actions/messages'
import {useNavigate} from 'react-router-dom'
import {groupBy} from '../../utils/common'
import {getMobileConfiguration} from '../../../actions/users'
import {
  getEmployeeAbsencePolicy,
  getEmployeeAbsenceStatisticsAdjustment,
  getEmployeeAttendance,
  getEmployeeAttendanceByDates,
  getEmployeeInfoByUserId,
  getEmployeeShift,
  getEmployeeVacationPolicies,
} from '../../../actions/employee'
import {
  getAllCities,
  getAllCountries,
  getAttendancetype,
  getCostCenter,
  getDepartments,
  getEmployeeTypes,
  getGenders,
  getNationalities,
  getPositions,
  getStates,
} from '../../../actions/common'
import moment from 'moment'
import {getAbsenceReason} from '../../../actions/absense'
import {useCompanyContext} from '../../../_metronic/layout/core/CompanyProvider'
import {getShiftTypes} from '../../../actions/shifts'
import {MESSAGE_FOLDER_ID, MESSAGE_TYPE, momentDateFormat} from '../../../config/constants'
import {getEmployeePinboard} from '../../../actions/pinboard'
import {momentFullDateFormat} from '../../utils/constants'

function GlobalDataLoader() {
  const {
    setOpeningHours,
    setHolidays,
    setDaysOff,
    setEventsCategories,
    setMobileConfiguration,
    setEmployeeInfo,
    setCountries,
    setCities,
    setGenders,
    setDepartments,
    setPositions,
    setStates,
    setNationalities,
    setEmployeeTypes,
    setCostCenter,
    setAttendanceTypes,
    setGlobalLoader,
    setEmployeeAttendance,
    setEmployeeShifts,
    setTodayEmployeeAttendance,
    setAbsencesReasons,
    setTabMessagesCounts,
    daysOff,
    holidays,
    setExecludedDates,
    setAllShiftTypes,
    absencePolicies,
    setAbsencePolicies,
    setAbsenceAdjustments,
    setInboxData,
    setEmployeePinboard,
    mobileConfiguration,
  } = useAppContext()
  const {currentCompany, setCurrentCompany} = useCompanyContext()
  const {currentUser, auth} = useAuth()
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]

  useEffect(() => {
    if (currentUser) {
      getEmployeeInfo()
      readMobileConfiguration()
    }
  }, [currentUser, currentCompany])

  useEffect(() => {
    const doReadData = async () => {
      if (currentUser) {
        readData()
        if (mobileConfiguration[1]?.length > 0 && mobileConfiguration[1][0]?.showInHrApp) {
          const absenceReason = await getAbsenceReason('1=1')
          setAbsencesReasons(absenceReason)
          readAbsencePolicies()
          readAbsenceAdjustments()
        }
        if (
          (mobileConfiguration[2]?.length > 0 && mobileConfiguration[2][0]?.showInHrApp) ||
          (mobileConfiguration[3]?.length > 0 && mobileConfiguration[3][0]?.showInHrApp) ||
          (mobileConfiguration[4]?.length > 0 && mobileConfiguration[4][0]?.showInHrApp)
        ) {
          const attendancetype = await getAttendancetype()
          setAttendanceTypes(attendancetype)
          const employeeAttendance = await getEmployeeAttendance(
            `employeeId=${currentUser?.employee.employeeId}`
          )
          setEmployeeAttendance(employeeAttendance)
          const employeeAttendanceByDates = await getEmployeeAttendanceByDates(
            `${moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
            `${moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')}`
          )
          setTodayEmployeeAttendance(employeeAttendanceByDates)
        }
        if (mobileConfiguration[5]?.length > 0 && mobileConfiguration[5][0]?.showInHrApp)
          readMessageCounts()

        if (mobileConfiguration[7]?.length > 0 && mobileConfiguration[7][0]?.showInHrApp) {
          readEventsCat()
        }

        if (
          (mobileConfiguration[8]?.length > 0 && mobileConfiguration[8][0]?.showInHrApp) ||
          (mobileConfiguration[9]?.length > 0 && mobileConfiguration[9][0]?.showInHrApp)
        ) {
          const employeeShift = await getEmployeeShift(
            `employeeId=${currentUser?.employee.employeeId}`
          )

          setEmployeeShifts(employeeShift)
          const shiftTypes = await getShiftTypes()
          setAllShiftTypes(shiftTypes)
        }

        if (mobileConfiguration[10]?.length > 0 && mobileConfiguration[10][0]?.showInHrApp)
          readPinboards()
      }
    }
    doReadData()
  }, [currentUser, currentCompany, mobileConfiguration])

  useEffect(() => {
    try {
      if (auth) {
        const comp: any = localStorage.getItem('kidling-selectedCompany')
        if (!comp) {
          navigate(company ? `/${company}/company` : '/kidling/company')
        } else {
          setCurrentCompany(JSON.parse(comp))
        }
      }
    } catch (e) {
      console.log('error', e)
    }
  }, [auth])
  useEffect(() => {
    let execulded: any = []
    daysOff?.map((day: any) =>
      execulded.push({
        start: moment(day.dayOffDate).startOf('day').toDate(),
        end: moment(day.dayOffDate).endOf('day').toDate(),
      })
    )
    holidays?.map((holi: any) => {
      if (holi.holidayDate) {
      }
      execulded.push({
        start: moment(holi.holidayDate).set('year', moment().year()).toDate(),
        end:
          holi.numberOfDays > 1
            ? moment(holi.holidayDate)
                .set('year', moment().year())
                .add('days', holi.numberOfDays)
                .toDate()
            : moment(holi.holidayDate).set('year', moment().year()).toDate(),
      })
    })
    setExecludedDates(execulded)
  }, [daysOff, holidays])

  const readAbsencePolicies = async () => {
    const resp = await getEmployeeVacationPolicies(
      moment().startOf('year').format(momentFullDateFormat),
      moment().endOf('year').format(momentFullDateFormat),
      currentCompany?.value,
      currentUser?.employee?.employeeId
    )
    setAbsencePolicies(resp)
  }

  const readMobileConfiguration = async () => {
    const resp = await getMobileConfiguration()

    constructMobileConfiguration(resp)
  }

  const constructMobileConfiguration = (conf: any) => {
    const resp = groupBy(conf, 'mobileConfigurationHrAppMasterId')
    if (resp) setMobileConfiguration(resp)
  }

  const readEventsCat = async () => {
    if (currentUser) {
      const resp = await readEventsCategory(`companyId=${currentCompany?.value}`)

      if (resp) setEventsCategories(resp)
    }
  }
  const readData = async () => {
    Promise.all([
      getAllCities('1=1'),
      getAllCountries(),
      getGenders(),
      getDepartments(),
      getNationalities(),
      getPositions(),
      getStates(),
      getEmployeeTypes(),
      getCostCenter(),
      readHolidays(),
      readDaysOff(),
      readOpeningHours(),
    ])
      .then(function ([
        cities,
        countries,
        genders,
        departments,
        nationalities,
        positions,
        states,
        employeeTypes,
        costCenter,
        holidays,
        daysOff,
        openHours,
      ]) {
        setCities(cities)
        setCountries(countries)
        setGenders(genders)
        setDepartments(departments)
        setNationalities(nationalities)
        setPositions(positions)
        setStates(states)
        setEmployeeTypes(employeeTypes)
        setCostCenter(costCenter)
        setHolidays(holidays)
        setDaysOff(daysOff)
        setOpeningHours(openHours)
      })
      .catch(console.log)
  }

  const getEmployeeInfo = async () => {
    const resp = await getEmployeeInfoByUserId(currentUser?.usersId || 0)
    if (resp) setEmployeeInfo(resp)
    setGlobalLoader(false)
  }

  const readMessageCounts = async () => {
    if (currentUser) {
      const resp1 = await getAllMessagesV2(
        currentUser?.usersId ?? 0,
        '1=1',
        1,
        10,
        MESSAGE_FOLDER_ID.Received,
        MESSAGE_TYPE.Message
      )

      setInboxData(resp1)
      let resp = await getAllMessagesCounts(currentUser?.usersId ?? 0)
      setTabMessagesCounts(resp)
    }
  }
  const readAbsenceAdjustments = async () => {
    const resp = await getEmployeeAbsenceStatisticsAdjustment(
      moment().startOf('year').format(momentDateFormat),
      moment().endOf('year').format(momentDateFormat),
      currentUser?.employee?.employeeId
    )
    setAbsenceAdjustments(resp)
  }

  const readPinboards = async () => {
    const resp = await getEmployeePinboard()

    setEmployeePinboard(resp)
  }

  return <></>
}

export default GlobalDataLoader
