import {useEffect} from 'react'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {selectStyle, selectStyleDarkMode} from '../../../../../utils/select-style'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import useAbsence from '../../../../../hooks/useAbsence'
function VactaionYearFilter() {
  const intl = useIntl()
  const {mode} = useThemeMode()
  const {getVacations} = useAbsence()
  const {
    setCurrentVacationsYear,
    currentVacationsYear,
    absencesReasons,
    currentAbsenceType,
    setCurrentAbsenceType,
  } = useAppContext()

  useEffect(() => {
    getVacations()
  }, [currentVacationsYear, currentAbsenceType])

  return (
    <div className='row mx-1 mx-sm-8'>
      <div className='col-12 col-sm-6'>
        <Select
          name='employeeAbsenceReasonId'
          options={absencesReasons}
          className='basic-multi-select'
          classNamePrefix='select'
          isClearable
          placeholder={intl.formatMessage({id: 'KIDLING.ABSENCE_REASON'})}
          getOptionLabel={(option) => option.employeeAbsenceReasonName}
          getOptionValue={(option) => option.employeeAbsenceReasonId}
          onChange={(val: any) => setCurrentAbsenceType(val?.employeeAbsenceReasonId)}
          styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
          theme={(theme) => ({
            ...theme,

            colors: {
              ...theme.colors,
              primary25: 'var(--bs-dark-light)',
              primary: 'var(--bs-gray)',
              neutral0: 'var(--bs-dark-light)',
            },
          })}
        />
      </div>
      <div className='col-12 col-sm-6 mt-4 mt-sm-0'>
        <ResponsiveDatePicker
          onAccept={(year: Date) => setCurrentVacationsYear(year)}
          value={currentVacationsYear}
          yearOnly
        />
      </div>
    </div>
  )
}

export default VactaionYearFilter
