import React, {FC, ReactElement} from 'react'

type Props = {
  day: string
  monthName: string
}
const MonthDayCard: FC<Props> = ({day, monthName}): ReactElement => {
  return (
    <div
      className='w-50px h-50px shadow   bg-gray-300 d-flex flex-column align-items-center m-1'
      style={{borderRadius: '1rem'}}
    >
      <div
        className=' h-20px w-100 bg-gray-500 text-uppercase  d-flex align-items-center justify-content-center text-white'
        style={{borderRadius: '1rem 1rem 0 0', fontSize: '10px'}}
      >
        {monthName}
      </div>
      <h1>{day}</h1>
    </div>
  )
}

export default MonthDayCard
