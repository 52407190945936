import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_BY_OTP,
  MOBILE_CONFIGURATION,
  SEND_OTP_BY_EMAIL,
  USERS,
} from '../config/endpoints'
import axios from 'axios'

export const updateUserInfo = async (data: any) => {
  return axios
    .put(USERS, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getUserInfo = async (id: number) => {
  return axios
    .get(`${USERS}/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const changePassword = async (data: any) => {
  return axios
    .post(`${CHANGE_PASSWORD}`, data)
    .then((response: any) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const getMobileConfiguration = async () => {
  return axios
    .get(MOBILE_CONFIGURATION)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const sendOtpByEmail = async (data: any) => {
  const axiosInstance = axios.create()
  axiosInstance.interceptors.request.use(
    (config: any) => {
      //config.headers['AuthorizationKey'] = `${process.env.REACT_APP_LANGUAGE_API_KEY}`

      config.headers['email'] = `${data.email}`
      return config
    },
    (err: any) => Promise.reject(err)
  )
  return axiosInstance
    .post(`${SEND_OTP_BY_EMAIL}`)
    .then((response: any) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const changePassowrdByOtp = async (data: any) => {
  const axiosInstance = axios.create()
  axiosInstance.interceptors.request.use(
    (config: any) => {
      //config.headers['AuthorizationKey'] = `${process.env.REACT_APP_LANGUAGE_API_KEY}`
      config.headers['password'] = `${data.password}`
      config.headers['username'] = `${data.email}`
      config.headers['otp'] = `${data.otp}`
      return config
    },
    (err: any) => Promise.reject(err)
  )
  return axiosInstance
    .post(`${CHANGE_PASSWORD_BY_OTP}`)
    .then((response: any) => response)
    .catch((error) => {
      console.log(error)
    })
}
