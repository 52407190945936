import moment from 'moment-timezone'
import React from 'react'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../../../../_metronic/i18n/Metronici18n'
import {UserCustomHeader} from '../../../../../../general-components/table-custom-header/UserCustomHeader'
import {MarkedSelectionCell} from './MarkedSelectionCell'
import {MESSAGE_LENGTH, MOBILE_MESSAGE_LENGTH} from '../../../../../../../config/constants'
import AvatarWithLetters from '../../../../../../general-components/avatar-with-letters'
import {useThemeMode} from '../../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

function _columns({handleSentMarkPinned, handleRecievedMarkPinned, navigate}: any) {
  const intl = useIntl()
  const lang = useLang()
  const {mode} = useThemeMode()
  return [
    {
      id: 'selection',

      Cell: ({...props}) => (
        <MarkedSelectionCell
          id={{
            notificationUsersId: props.data[props.row.index].notificationUsersId,
            notificationId: props.data[props.row.index].notificationId,
          }}
        />
      ),
      width: 5,
    },

    {
      width: 5,

      id: 'Attributes',
      accessor: 'id',
      Cell: ({cell}: any) => {
        const pinned = cell.row.original.pinned
        return (
          <div className='d-flex  align-items-center'>
            <button
              className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
              type='button'
              onClick={() =>
                cell.row.original.notificationUsersId
                  ? handleRecievedMarkPinned(cell.row.original.notificationUsersId, pinned)
                  : handleSentMarkPinned(cell.row.original.notificationId, pinned)
              }
            >
              <i className={`fa-solid fa-star fs-5 m-0 ${pinned && 'text-warning'}`}></i>
            </button>
            {cell.row.original.attachmentExists && (
              <i className={`fa-solid fa-paperclip fs-5 m-0  `}></i>
            )}

            {cell.row.original.highPriority && (
              <i className='fa-sharp fa-solid fa-circle-exclamation text-danger ms-4'></i>
            )}
          </div>
        )
      },
    },
    // {
    //   Header: (props: any) => (
    //     <UserCustomHeader tableProps={props} title='COMMON.NAME'  />
    //   ),
    //   id: 'Name',
    //   accessor: 'name',
    //   width: 50,
    //   Cell: ({cell}: any) => {
    //     return (
    //       <div className='d-flex align-items-center link' onClick={() => navigate('details/1')}>
    //         <div className='symbol  me-4'>
    //           <img src={cell.row.original.image} alt='' />
    //         </div>
    //         <div className='ml-4  d-flex flex-column justify-content-center'>
    //           <h5>{cell.row.original.name}</h5>
    //         </div>
    //       </div>
    //     )
    //   },
    // },
    {
      id: 'type',
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.TYPE' />,
      Cell: ({cell}: any) => {
        return (
          <div className='d-flex flex-column link'>
            <h5>
              {cell.row.original.notificationUsersId > 0 ? (
                <i className='fa-solid fa-arrow-turn-down text-danger '></i>
              ) : (
                <i className='fa-solid fa-arrow-turn-up  text-success'></i>
              )}
            </h5>
          </div>
        )
      },
      width: 5,
    },
    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.TITLE' />,
      accessor: 'title',
      id: 'title',
      width: 300,
      Cell: ({cell}: any) => {
        return (
          <div className='d-flex flex-column link'>
            <p
              onClick={() => navigate(`details/${cell.row.original.notificationId}`)}
              className={`${mode === 'dark' ? 'text-dark' : 'text-dark'} ${
                cell.row.original.readDateTime ? 'fw-normal' : 'fw-bold'
              }`}
            >
              {cell.row.original.notificationTitle}
            </p>
          </div>
        )
      },
    },

    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.MESSAGE' />,
      accessor: 'message',
      id: 'message',
      width: 300,
      Cell: ({cell}: any) => {
        const htmlNode = document.createElement('div')
        htmlNode.innerHTML = cell.row.original.message

        return (
          <div className='d-flex flex-column link'>
            <p
              onClick={() => navigate(`details/${cell.row.original.notificationId}`)}
              dangerouslySetInnerHTML={{
                __html: `${htmlNode.innerText.substring(0, MESSAGE_LENGTH)} ${
                  htmlNode.innerText.length > MESSAGE_LENGTH ? ' ...' : ''
                }`,
              }}
              className={`${mode === 'dark' ? 'text-dark' : 'text-dark'} ${
                cell.row.original.readDateTime ? 'fw-normal' : 'fw-bold'
              }`}
            ></p>
          </div>
        )
      },
    },

    {
      Header: (props: any) => <UserCustomHeader tableProps={props} title='KIDLING.HOME.DATETIME' />,

      id: 'dateTime',
      width: 50,
      accessor: 'dateTime',
      Cell: ({cell}: any) => {
        return (
          <div
            className={`${mode === 'dark' ? 'text-dark' : 'text-dark'} ${
              cell.row.original.readDateTime ? 'fw-normal' : 'fw-bold'
            }`}
          >
            <i className='fa-solid fa-clock me-2'></i>
            {moment
              .duration(moment(new Date()).diff(moment(cell.row.original.dateTime)))
              .locale(lang)
              .humanize()}
          </div>
        )
      },
    },
  ]
}

export default _columns

export function _mobileColumns({handleSentMarkPinned, handleRecievedMarkPinned, navigate}: any) {
  const intl = useIntl()
  const lang = useLang()

  return [
    {
      id: 'Name',
      accessor: 'name',
      width: 50,
      hideHeader: true,
      Cell: ({cell}: any) => {
        const row = cell.row.original
        const nameArray = row?.usersName
          .trim()
          .replace(/\s{2,}/g, ' ')
          .split(' ')
        const htmlNode = document.createElement('div')
        htmlNode.innerHTML = cell.row.original.message
        const pinned = cell.row.original.pinned
        const recipients = cell.row.original.recipients
        return (
          <div className='d-flex align-items-center w-100 shadow-sm p-4'>
            <MarkedSelectionCell
              id={{
                notificationUsersId: row.notificationUsersId,
                notificationId: row.notificationId,
              }}
            />
            <div className='d-flex align-items-center link mx-4  d-flex justify-content-center w-100'>
              <AvatarWithLetters
                image={row.imagePath}
                alt={`${nameArray[0].charAt(0).toUpperCase()} ${
                  nameArray.length > 1 ? nameArray[1].charAt(0).toUpperCase() : ''
                } `}
                extrasmall
              />
              <div className='w-100 ms-4  '>
                <div
                  className=' d-flex align-items-center justify-content-between w-100'
                  onClick={() => navigate(`details/${row.notificationId}`)}
                >
                  <div className='d-flex align-items-center'>
                    <h5
                      className={`fs-7  my-0  'fw-normal text-gray'
                  `}
                    >
                      {row?.usersName}
                    </h5>
                    <div className='d-flex  align-items-center mx-2'>
                      {row.attachmentExists && (
                        <i className={`fa-solid fa-paperclip fs-5 m-0  `}></i>
                      )}

                      {row.highPriority && (
                        <i className='fa-sharp fa-solid fa-circle-exclamation text-danger ms-2'></i>
                      )}

                      {row.notificationUsersId > 0 ? (
                        <i className='fa-solid fa-arrow-turn-down text-danger ms-2 '></i>
                      ) : (
                        <i className='fa-solid fa-arrow-turn-up  text-success  ms-2'></i>
                      )}
                    </div>
                  </div>
                  <div className=' my-0 '>
                    {/* <i className='fa-solid fa-clock me-2'></i> */}
                    {moment
                      .duration(moment(new Date()).diff(moment(row.dateTime)))
                      .locale(lang)
                      .humanize()}
                  </div>
                </div>

                <p
                  className={`my-0  fs-7  ${row.readDateTime && 'fw-normal text-gray'}`}
                  onClick={() => navigate(`details/${row.notificationId}`)}
                >
                  {row.notificationTitle}
                </p>
                <div className='d-flex align-items-center justify-content-between'>
                  <p
                    className='my-0  fs-7'
                    dangerouslySetInnerHTML={{
                      __html: `${htmlNode.innerText.substring(0, MOBILE_MESSAGE_LENGTH)} ${
                        htmlNode.innerText.length > MOBILE_MESSAGE_LENGTH ? ' ...' : ''
                      }`,
                    }}
                    onClick={() => navigate(`details/${row.notificationId}`)}
                  ></p>
                  <button
                    className='btn btn-sm btn-icon btn-clear btn-active-light-primary'
                    type='button'
                    onClick={() =>
                      row.notificationUsersId
                        ? handleRecievedMarkPinned(row.notificationUsersId, pinned)
                        : handleSentMarkPinned(row.notificationId, pinned)
                    }
                  >
                    <i className={`fa-solid fa-star fs-5 m-0 ${pinned && 'text-warning'}`}></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      },
    },
  ]
}
