import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getEmployeePinboard = async () => {
  return axios
    .get(`${endpoints.EMPLOYEE_PINBOARD}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
