import React, {FC, ReactElement} from 'react'

interface TableProps {
  columns: any
  data: any
  headerGroups?: any
  prepareRow: any
  getTableProps: any
}
const TableMobileView: FC<TableProps> = ({
  columns,
  data,
  headerGroups,
  prepareRow,
  getTableProps,
}): ReactElement => {
  return (
    <div className='w-100  ' {...getTableProps()}>
      <div className='d-flex align-items-center ms-4'>
        {columns[0].id === 'selection' && (
          <div className='text-muted'>{headerGroups[0].headers[0].render('Header')}</div>
        )}
        <i className='fa-solid fa-sort'></i>
        {Object.entries(columns).map(([key1, value1]: any, index) => {
          return (
            <div className='ms-1' key={index}>
              {headerGroups[0].headers[key1].id !== 'selection' &&
                headerGroups[0].headers[key1].id !== 'avatar' &&
                headerGroups[0].headers[key1].canSort && (
                  <th
                    {...(!headerGroups[0].headers[key1].canSort
                      ? headerGroups[0].headers[key1].getHeaderProps()
                      : headerGroups[0].headers[key1].getHeaderProps(
                          headerGroups[0].headers[key1].getSortByToggleProps()
                        ))}
                    className='text-muted'
                  >
                    {headerGroups[0].headers[key1].render('Header')}
                  </th>
                )}
            </div>
          )
        })}
      </div>

      {data?.map((item: any, index: number) => {
        prepareRow(item)
        return (
          <tr
            className='card  p-8 bg-body rounded  d-flex flex-column m-4 shadow-sm '
            key={index}
            {...item.getRowProps()}
          >
            <div className='row gap-2'>
              {Object.entries(columns).map(([key1, value1]: any, index) => {
                return (
                  <div className='row' key={index}>
                    {headerGroups[0].headers[key1].id !== 'selection' && (
                      <div className='col-5'>
                        {headerGroups[0].headers[key1].id !== 'avatar' && (
                          <th className='col-4 text-muted'>
                            {headerGroups[0].headers[key1].render('Header')}
                          </th>
                        )}
                      </div>
                    )}

                    <div className='col-7'>
                      <td className='ml-4  d-flex flex-column justify-content-start mt-auto'>
                        {value1?.Cell ? value1.Cell({cell: {row: {...item}}}) : value1}
                      </td>
                    </div>
                  </div>
                )
              })}
            </div>
          </tr>
        )
      })}
    </div>
  )
}

export default TableMobileView
