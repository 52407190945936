import {useEffect, useState} from 'react'
import MonthDayCard from '../../../../../general-components/month-day-card'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {absenceStatusColors} from '../../../../../utils/constants'
import VacationDetailsModal from './VacationDetailsModal'
import useAbsence from '../../../../../hooks/useAbsence'

function VacationsList() {
  const intl = useIntl()
  const {getVacations, getPolicies} = useAbsence()
  const {allVacations, globalLoader} = useAppContext()
  const [showDetials, setShowDetials] = useState({show: false, data: null})

  useEffect(() => {
    getPolicies()
  }, [])

  return (
    <div className='mt-8   rounded border '>
      <p className='text-uppercase text-body   p-2   bg-gray-200 rounded-top rounded-right'>
        {intl.formatMessage({id: 'KIDLING.HR.PAST_TIME_OFF'})}
      </p>
      {globalLoader && <UsersListLoading />}
      {!globalLoader &&
        allVacations?.map((item: any, index: number) => (
          <button
            type='button'
            onClick={() => setShowDetials({show: true, data: item})}
            className='list-group-item list-group-item-action'
            key={index}
          >
            <div className='d-flex align-items-center my-1  p-2 ' key={index}>
              <MonthDayCard
                day={moment(item.employeeAbsenceFromDate).format('D')}
                monthName={moment(item.employeeAbsenceFromDate).format('MMM')}
              />
              <i className='fa-solid fa-arrow-right mx-2'></i>
              <MonthDayCard
                day={moment(item.employeeAbsenceToDate).format('D')}
                monthName={moment(item.employeeAbsenceToDate).format('MMM')}
              />
              <div className='ms-4'>
                <h5 className='m-0 fw-normal'>
                  {item?.reason?.employeeAbsenceReasonName}{' '}
                  {item?.reason?.requireApproval && (
                    <i className='fa-solid fa-circle-check text-primary ms-1'></i>
                  )}
                </h5>
                <p className='m-0 text-body'>
                  {item.numberOfDays} {intl.formatMessage({id: 'KIDLING.DAY'})}
                </p>
              </div>
              <div className='ms-auto d-flex flex-column align-items-end'>
                {item?.employeeApprovals?.length > 0 && (
                  <div
                    className={`badge badge-${
                      absenceStatusColors[item.employeeApprovals[0]?.employeeAbsenceStatusId]?.color
                    } fw-semibold`}
                  >
                    {intl.formatMessage({
                      id: absenceStatusColors[item.employeeApprovals[0]?.employeeAbsenceStatusId]
                        ?.label,
                    })}
                  </div>
                )}
                <button className='btn ms-auto' type='button'>
                  <i className='fa-solid fa-arrow-right-long fs-3'></i>
                </button>
              </div>
            </div>
          </button>
        ))}
      <VacationDetailsModal
        show={showDetials}
        setShow={setShowDetials}
        getVacations={getVacations}
      />
    </div>
  )
}

export default VacationsList
