import {Avatar} from '@mui/material'
import moment from 'moment'
import React, {FC, ReactElement} from 'react'
interface Props {
  data: any
}
const PinboardCard: FC<Props> = ({data}): ReactElement => {
  return (
    <div className='card p-8 my-4'>
      <div className='d-flex align-items-center'>
        <Avatar src={data?.imagePath} alt={data?.employeeName?.charAt(0).toUpperCase()} />
        <h5 className='ms-2 my-0 py-0'>{data?.employeeName}</h5>
        <span className='w-4px h-4px rounded-circle mx-2  bg-gray-500 d-inline-block'></span>
        <p className='p-0 m-0 text-body'>
          {moment(data?.showStartDate).format('DD.MM.yyyy HH:mm')}
        </p>
      </div>
      <div className='mt-4'>
        <h3>{data?.employeePinboardTitle}</h3>
        <div dangerouslySetInnerHTML={{__html: data?.employeePinboardMessage}}></div>
      </div>
    </div>
  )
}

export default PinboardCard
