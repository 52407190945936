import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import interactionPlugin from '@fullcalendar/interaction'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import allLocales from '@fullcalendar/core/locales-all'
import listPlugin from '@fullcalendar/list'
import {UsersListLoading} from '../../../../general-components/loading/UsersListLoading'
import EventCell from './EventCell'
import {useEffect} from 'react'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import useCalendar from '../../../../hooks/useCalendar'

function Calender() {
  const locale = useLang()
  const {calendarFilter, globalLoader} = useAppContext()
  const {
    calendarRef,
    readEvents,
    formattedEvents,
    triggerDateChanged,
    handleDateSelect,
    handleEventClick,
  } = useCalendar()

  useEffect(() => {
    readEvents()
  }, [calendarFilter])

  return (
    <div className='p-4 p-sm-8 p-md-16'>
      <FullCalendar
        ref={calendarRef}
        plugins={[rrulePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listDay',
        }}
        slotMinTime='07:00'
        slotMaxTime='20:00'
        initialView='timeGridWeek'
        allDaySlot={false}
        weekends={true}
        editable={false}
        selectable={true}
        selectMirror={false}
        dayMaxEvents={false}
        initialEvents={formattedEvents}
        events={formattedEvents}
        eventContent={EventCell}
        eventClick={handleEventClick}
        select={handleDateSelect}
        locale={locale}
        locales={allLocales}
        firstDay={1}
        nowIndicator={true}
        datesSet={triggerDateChanged}
      />

      {globalLoader && <UsersListLoading />}
    </div>
  )
}

export default Calender
