import moment from 'moment'
import {
  deleteEvent,
  getAllEventsWithRecurrents,
  readDaysOff,
  readHolidays,
} from '../../actions/events'
import {useEffect, useRef, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'

const useCalendar = () => {
  const intl = useIntl()
  const [formattedEvents, setformattedEvents] = useState<any>([])
  const [holidays, setHolidays] = useState([])
  const [daysOff, setDaysOff] = useState([])
  const {
    allEvents,
    setAllEvents,
    eventsCategories,
    setCalenderDateFilter,
    setGlobalLoader,
    globalLoader,
  } = useAppContext()

  const calendarRef: any = useRef<any>()

  useEffect(() => {
    readHolidayesAndDaysOff()
  }, [])

  useEffect(() => {
    if (eventsCategories?.length > 0) {
      formatEvents()
    }
  }, [allEvents, eventsCategories, holidays, daysOff])

  useEffect(() => {
    if (calendarRef.current) calendarRef.current.render()
  }, [formattedEvents, calendarRef])

  const handleDateSelect = (selectInfo: any) => {
    // if (Math.trunc(moment.duration(moment(selectInfo.start).diff(new Date())).asDays()) >= 0)
    //   setModal({
    //     showModal: true,
    //     data: selectInfo,
    //     enableEdit:
    //       Math.trunc(moment.duration(moment(selectInfo.start).diff(new Date())).asDays()) >= 0,
    //   })
  }

  const handleEventClick = (clickInfo: any) => {
    // if (clickInfo.event._def)
    //   setModal({
    //     showModal: true,
    //     data: clickInfo.event._def,
    //     enableEdit:
    //       Math.round(
    //         moment.duration(moment(clickInfo.event._instance.range.start).diff(new Date())).asDays()
    //       ) >= 0,
    //   })
  }

  const readHolidayesAndDaysOff = async () => {
    const resp1: any = await readHolidays()
    const resp2: any = await readDaysOff()
    setDaysOff(resp2)
    setHolidays(resp1)
  }

  const readEvents = async (dateFilter?: any) => {
    try {
      if (!globalLoader) {
        setGlobalLoader(true)
        setAllEvents([])
        const resp = await getAllEventsWithRecurrents(
          '',
          dateFilter?.start
            ? moment(dateFilter?.startStr).format('yyyy-MM-DDTHH:mm:ss')
            : moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss'),
          dateFilter?.end
            ? moment(dateFilter?.endStr).format('yyyy-MM-DDTHH:mm:ss')
            : moment().endOf('week').format('yyyy-MM-DDTHH:mm:ss')
        )

        setAllEvents(resp)
      }
    } catch (e) {
      console.log('error', e)
    }
  }

  const formatEvents = async () => {
    try {
      let tempEvents: any = []
      if (holidays.length > 0) {
        holidays?.map((event: any) => {
          let tempObj: any = {
            title: event.holidayName,
            start: moment(event.holidayDate)
              .set('hours', 0)
              .set('minutes', 0)
              .format('yyyy-MM-DDTHH:mm:ss'),
            end: moment(event.holidayDate)
              .add(event.numberOfDays - 1, 'days')
              .set('hours', 23)
              .set('minutes', 59)
              .format('yyyy-MM-DDTHH:mm:ss'),
            duration: {
              days: moment
                .duration(
                  moment(moment(event.holidayDate).add(event.numberOfDays - 1, 'days')).diff(
                    moment(event.holidayDate)
                  )
                )
                .asDays(),
            },
            allDay: true,
            extendedProps: {...event},
            colorCode: '#ff0000',
            display: 'background',
            textColor: 'black',
          }

          if (event.recurrent === true) {
            tempObj = {
              ...tempObj,
              rrule: {
                freq: 'yearly',
                interval: 1,
                dtstart: event.holidayDate,
              },
            }
          }

          tempEvents.push(tempObj)
        })
      }
      if (daysOff.length > 0) {
        daysOff?.map((event: any) => {
          let tempObj: any = {
            title: event.dayOffName,
            start: moment(event.dayOffDate)
              .set('hours', 0)
              .set('minutes', 0)
              .format('yyyy-MM-DDTHH:mm:ss'),
            end: moment(event.dayOffDate)
              .add(event.numberOfDays, 'days')
              .set('hours', 23)
              .set('minutes', 59)
              .format('yyyy-MM-DDTHH:mm:ss'),
            allDay: true,
            extendedProps: {...event},
            colorCode: '#ff0000',
            display: 'background',
            textColor: 'black',
          }

          tempEvents.push(tempObj)
        })
      }
      allEvents?.map((event: any) => {
        let eventRecurrent: any = []

        if (event?.recurrings?.length > 0) {
          event?.recurrings.map((item: any) =>
            eventRecurrent.push(item.weekdayId === 0 ? 6 : item.weekdayId - 1)
          )
        }
        const category = eventsCategories?.find(
          (item: any) => item.eventsCategoryId === event.eventsCategoryId
        )

        let tempObj: any = {
          title: event.eventsName,
          start: event.startsOn,
          // end: moment(event.endsOn),
          end: moment(event.startsOn)
            .set('hours', moment(event.endsOn).hours())
            .set('minutes', moment(event.endsOn).minutes())
            .format('yyyy-MM-DDTHH:mm:ss'),
          duration: {
            hours: moment
              .duration(
                moment(
                  moment(event.startsOn)
                    .set('hours', moment(event.endsOn).hours())
                    .set('minutes', moment(event.endsOn).minutes())
                    .format('yyyy-MM-DDTHH:mm:ss')
                ).diff(event.startsOn)
              )
              .asHours(),
          },
          colorCode: category?.colorCode || '#fff',
          allDay: false,
          displayEventEnd: true,
          extendedProps: {...event},
        }

        if (eventRecurrent.length === 0) {
          tempObj = {
            ...tempObj,
            rrule: {
              freq: 'daily',
              interval: 1,
              dtstart: event.startsOn,
              until: event.endsOn,
            },
          }
        }
        if (eventRecurrent.length > 0) {
          tempObj = {
            ...tempObj,
            rrule: {
              freq: 'weekly',
              interval: 1,
              byweekday: eventRecurrent,
              dtstart: event.startsOn,
              until: event.endsOn,
            },
          }
        }

        tempEvents.push(tempObj)
      })

      setformattedEvents(tempEvents)
      setGlobalLoader(false)
    } catch (e) {
      console.log(e)
    }
  }

  const triggerDateChanged = async (dateObj: any) => {
    setCalenderDateFilter(dateObj)
    readEvents(dateObj)
  }
  const DoDelete = async (id: number) => {
    setGlobalLoader(true)
    const resp: any = await deleteEvent(id)
    if (resp.status === 200) {
      readEvents()
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    }
    setGlobalLoader(true)
  }
  return {
    triggerDateChanged,
    formattedEvents,
    handleDateSelect,
    handleEventClick,
    readEvents,
    calendarRef,
    DoDelete,
  }
}

export default useCalendar
