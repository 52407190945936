import React, {FC, ReactElement} from 'react'
import {Link} from 'react-router-dom'
import './style.scss'
type Props = {
  title: string
  url: string
  stat: any
  icon: string
  extrnalLink?: boolean
  subtitle?: string
}
const HrCard: FC<Props> = ({title, url, stat, icon, extrnalLink, subtitle}): ReactElement => {
  const company = window.location.pathname.split('/')[1]

  return (
    <Link
      to={extrnalLink ? url : company ? `/${company}/employee/${url}` : `/kidling/employee/${url}`}
    >
      <div className='home_card  rounded   h-100 w-100 pt-8 '>
        <div className='d-flex flex-column justify-content-center align-items-center content w-100'>
          <i className={`text-primary icon   ${icon}`}></i>
          <h2 className='mt-2  fw-normal '> {title}</h2>
          <div className='p-2 p-sm-4 border-top border-secondary w-100 d-flex justify-content-center'>
            <div className='row w-100 '>
              {stat.map((s: any, index1: number) => (
                <div className={`col-12  my-1  `} key={index1}>
                  <div className='d-flex align-items-center w-100'>
                    {s?.icon}
                    <div className=' d-flex justify-content-between align-items-center  w-100 ms-2 '>
                      <div className='fs-7 m-0 p-0 text-primary w-100'>{s.title}</div>
                      {s.count && (
                        <p className={`fs-7 text-primary m-0 p-0 ${s.color}  `}>{s.count}</p>
                      )}
                      {s.text && <p className={`fs-7 text-primary m-0 p-0 ${s.color}`}>{s.text}</p>}
                    </div>
                  </div>

                  {s.subtitle && (
                    <p className={`fs-7 text-primary m-0 p-0 ${s.color}`}>{s.subtitle}</p>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default HrCard
