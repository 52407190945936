export const momentFullDateFormat = 'yyyy-MM-DDTHH:mm:ss'
export const statusColors = [
  {color: 'secondary', label: 'COMMON.NOSTATUS'},
  {color: 'info', label: 'COMMON.PENDING'},
  {color: 'primary', label: 'COMMON.SUBMITTED'},
  {color: 'success', label: 'COMMON.APPROVED'},
  {color: 'danger', label: 'COMMON.REJECTED'},
  {color: 'warning', label: 'COMMON.INPROGRESS'},
]

export const absenceStatusColors = [
  {color: 'secondary', label: 'COMMON.NOSTATUS'},
  {color: 'info', label: 'COMMON.PENDING'},
  {color: 'success', label: 'COMMON.APPROVED'},
  {color: 'danger', label: 'COMMON.REJECTED'},
]
