/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import ToggleSwitch from '../../../../../general-components/ToggleSwitch'
import Timer from '../../../../../general-components/Timer'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {TableLoader} from '../../../../../general-components/table-loader/TableLoader'
import AttendanceDetailsModal from './details-modal/AttendanceDetailsModal'
import useEmployeeAttendance from '../../../../../hooks/useEmployeeAttendance'

function EmployeeClockin() {
  const intl = useIntl()
  const company = window.location.pathname.split('/')[1]
  const [show, setShow] = useState<any>({show: false, data: null})
  const {
    readEmployeeAttendance,
    readEmployeeStatistics,
    readTimeSheet,
    isBrek,
    isRunning,
    isLoading,
    resetTimer,
    time,
    breakRunning,
    addBreak,
    isLoading1,
    addCheckout,
    addAttendance,
  } = useEmployeeAttendance()
  const {
    globalLoader,
    todayEmployeeAttendance,
    employeeAttendaceStatistics,
    currentTimesheet,
    mobileConfiguration,
  } = useAppContext()
  useEffect(() => {
    readEmployeeAttendance(
      `${moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
      `${moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')}`
    )
    readEmployeeStatistics()
    readTimeSheet(
      moment().startOf('month').format('yyyy-MM-DD'),
      moment().endOf('month').format('yyyy-MM-DD')
    )
  }, [])

  return (
    <div className='card'>
      <div className='card-header align-items-center justify-content-between  py-5 gap-5'>
        <div className='d-flex  align-items-center justify-content-between w-100'>
          <Link to={company ? `/${company}/employee/main` : '/kidling/employee/main'}>
            <i className='fa-solid fa-arrow-left fs-3 me-4'></i>
          </Link>

          <h5>{moment().format('dddd-DD MMM')}</h5>
        </div>
      </div>

      <div className='card-body'>
        <div className='d-flex flex-column align-items-center'>
          <div className='d-flex align-items-center mt-8 w-md-400px w-100 shadow p-4 rounded'>
            <div
              className={`alert alert-${
                isBrek && !isLoading ? 'danger' : 'success'
              } px-4 border-0 rounded mb-0`}
              role='alert'
            >
              <i
                className={` ${
                  isBrek && !isLoading
                    ? 'fa-solid fa-mug-hot text-danger'
                    : 'fa-regular fa-clock text-success'
                }  `}
              ></i>
            </div>

            <div className='ms-4'>
              <p className='my-0'>
                {isBrek && !isLoading
                  ? intl.formatMessage({id: 'KIDLING.BREAK_TIME'})
                  : intl.formatMessage({id: 'COMMON.TODAY'})}
              </p>

              {isRunning || breakRunning ? (
                <div className='d-flex align-items-center '>
                  <Timer
                    initialSeconds={time.initialSeconds}
                    initialMinute={time.initialMinute}
                    initialHours={time.initialHours}
                    reset={resetTimer}
                  />
                  <span className='ms-2'>
                    <i
                      className={`fa-solid fa-circle ${
                        isBrek && !isLoading ? 'text-warning' : 'text-success'
                      }`}
                    ></i>
                  </span>
                </div>
              ) : (
                <h1 className='my-0'>
                  {employeeAttendaceStatistics?.today >= 0
                    ? `${Math.floor((employeeAttendaceStatistics?.today * 60) / 60)}h 
                ${Math.floor((employeeAttendaceStatistics?.today * 60) % 60)}m`
                    : '--'}
                </h1>
              )}
            </div>
            <button className='btn ms-auto'>
              <i className='fa-solid fa-chevron-down '></i>
            </button>
          </div>
          {!globalLoader &&
            mobileConfiguration[3]?.length > 0 &&
            mobileConfiguration[3][0]?.showInHrApp &&
            currentTimesheet?.Status !== 2 &&
            currentTimesheet?.Status !== 3 && (
              <div className='border-top  mt-8 w-md-400px w-100'>
                {isBrek ? (
                  <button
                    type='button'
                    className='btn btn-outline-primary border border-primary  w-100'
                    onClick={() => {
                      addBreak()
                    }}
                  >
                    {isLoading ? (
                      <span className='indicator-progress ' style={{display: 'inline-block'}}>
                        {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    ) : breakRunning ? (
                      intl.formatMessage({id: 'KIDLING.CLOCKIN'})
                    ) : (
                      intl.formatMessage({id: 'KIDLING.PAUSE'})
                    )}
                  </button>
                ) : isRunning ? (
                  <div className='d-flex  align-items-center w-100'>
                    <button
                      type='button'
                      className='btn btn-outline-primary border border-primary mx-2  w-100'
                      onClick={() => {
                        addBreak()
                      }}
                    >
                      {isLoading ? (
                        <span className='indicator-progress ' style={{display: 'inline-block'}}>
                          {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      ) : breakRunning ? (
                        intl.formatMessage({id: 'KIDLING.CLOCKIN'})
                      ) : (
                        intl.formatMessage({id: 'KIDLING.PAUSE'})
                      )}
                    </button>
                    <button
                      type='button'
                      className='btn btn-primary mx-2 w-100'
                      onClick={() => {
                        addCheckout()
                      }}
                    >
                      {!isLoading1 ? (
                        intl.formatMessage({id: 'KIDLING.CLOCK_OUT'})
                      ) : (
                        <span className='indicator-progress ' style={{display: 'inline-block'}}>
                          {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                ) : (
                  <ToggleSwitch
                    name='clockin'
                    noText={intl.formatMessage({id: 'KIDLING.CLOCKIN'})}
                    yesText={intl.formatMessage({id: 'KIDLING.CLOCK_OUT'})}
                    onChange={() => {
                      addAttendance()
                    }}
                  />
                )}
              </div>
            )}

          <div className='d-flex flex-column align-items-center w-100 border-top border-light mt-6'>
            {mobileConfiguration[3]?.length > 0 &&
              mobileConfiguration[3][0]?.showInHrApp &&
              mobileConfiguration[4]?.length > 0 &&
              mobileConfiguration[4][0]?.showInHrApp &&
              currentTimesheet?.Status !== 2 &&
              currentTimesheet?.Status !== 3 && (
                <button
                  className='btn btn-icon btn-primary ms-auto my-2'
                  onClick={() => setShow({show: true, data: {isNew: true}})}
                >
                  <i className='fa-solid fa-plus fs-3'></i>
                </button>
              )}
            <div className='list-group w-100'>
              {globalLoader && <TableLoader colNum={1} rows={5} noProfile />}
              {!globalLoader &&
                todayEmployeeAttendance?.groupedEmployeeAttendances &&
                todayEmployeeAttendance?.groupedEmployeeAttendances[0]?.attendances?.map(
                  (item: any, index: number) => (
                    <button
                      type='button'
                      onClick={() => setShow({show: true, data: item})}
                      className='list-group-item list-group-item-action w-100'
                      key={index}
                    >
                      <div className='d-flex align-items-center'>
                        <i
                          className={`${
                            item.attendanceTypeId === 1
                              ? 'fa-regular fa-clock text-success'
                              : 'fa-solid fa-mug-hot text-danger'
                          }`}
                        ></i>
                        <p className='m-0 ms-4'>
                          <div className='d-flex flex-column'>
                            <h6 className='mb-0 '>{moment(item.date).format('DD.MM.yyyy')}</h6>
                            <p className='my-0 text-body'>
                              {moment(item.in).format('HH:mm')} -{' '}
                              {item.out && moment(item.out).format('HH:mm')}{' '}
                              {!item.out && (
                                <span className='text-bold'>
                                  <i
                                    className={`fa-solid fa-circle ${
                                      item.attendanceTypeId === 2 ? 'text-danger' : 'text-success'
                                    }`}
                                  ></i>{' '}
                                  {intl.formatMessage({id: 'KIDLING.NOW'})}
                                </span>
                              )}
                            </p>
                          </div>
                        </p>
                        <div className='d-flex align-items-center ms-auto'>
                          <span
                            className={`badge ${
                              item.attendanceTypeId === 1 ? 'badge-success' : 'badge-danger'
                            } text-white`}
                          >
                            {item?.out
                              ? `${Math.floor(item?.numberOfMinutes / 60)}h 
                ${Math.floor(item?.numberOfMinutes % 60)}m`
                              : `${Math.floor(
                                  Math.ceil(
                                    moment
                                      .duration(
                                        moment().diff(
                                          moment(
                                            moment(item?.date)
                                              .set('hours', moment(item?.in).hours())
                                              .set('minutes', moment(item?.in).minutes())
                                          )
                                        )
                                      )
                                      .asMinutes()
                                  ) / 60
                                )}h 
                  ${Math.floor(
                    Math.ceil(
                      moment
                        .duration(
                          moment().diff(
                            moment(
                              moment(item?.date)
                                .set('hours', moment(item?.in).hours())
                                .set('minutes', moment(item?.in).minutes())
                            )
                          )
                        )
                        .asMinutes()
                    ) % 60
                  )}m`}
                          </span>
                          <button className='btn ms-auto'>
                            <i className='fa-solid fa-arrow-right'></i>
                          </button>
                        </div>
                      </div>
                    </button>
                  )
                )}
            </div>
            <Link
              className='btn btn-secondary mt-8 w-100 w-sm-200px'
              to={
                company ? `/${company}/employee/clockin/sheet` : `/kidling/employee/clockin/sheet`
              }
            >
              {intl.formatMessage({id: 'KIDLING.VIEW_SHEET'})}
            </Link>
          </div>
        </div>
      </div>
      <AttendanceDetailsModal
        show={show}
        setShow={setShow}
        readEmployeeAttendance={readEmployeeAttendance}
        readOnly={
          !show?.data?.out || currentTimesheet?.Status === 3 || currentTimesheet?.Status === 2
        }
      />
    </div>
  )
}

export default EmployeeClockin
