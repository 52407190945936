import React, {FC, ReactElement, useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Avatar, Badge} from '@mui/material'
import moment from 'moment'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import {SmallAvatar, StyledBadge} from '../../../../general-components/BadgeAvatars/BadgeAvatars'
import {useAuth} from '../../../auth'
import DropDownMenu from '../../../../general-components/dropDownMenu/DropDownMenu'
import {useIntl} from 'react-intl'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../../../../general-components/error-text/ErrorText'
import {deleteChatGroup} from '../../../../../actions/chat'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {useChatContext} from '../../../../context/ChatProvider'

interface Props {
  chats: any
  readAllChats: any
}
const PrivateChats: FC<Props> = ({chats, readAllChats}): ReactElement => {
  const {onlineUsers, connection} = useChatContext()
  const company = window.location.pathname.split('/')[1]
  const [currentChats, setCurrentChats] = useState<any>([])
  const params = useParams()
  const lang = useLang()
  const {currentUser} = useAuth()
  const intl = useIntl()
  const [showDelete, setShowDelete] = useState({show: false, id: '-1'})
  const navigate = useNavigate()
  const {mode} = useThemeMode()
  useEffect(() => {
    if (connection) {
      connection.on('CreatedNewChat', function (response: any) {
        const newChat: any = {
          id: response?.chatGroupId,
          name: response?.chatGroupName,
          image: '/media/avatars/300-5.jpg',
          subtitle:
            response?.messages?.length > 0
              ? `${response?.messages[
                  response?.messages?.length - 1
                ]?.chatGroupMessageText?.substring(0, 15)} ${
                  response?.messages[response?.messages?.length - 1]?.chatGroupMessageText.length >
                  15
                    ? ' ...'
                    : ''
                }`
              : '',
          date: moment
            .duration(
              moment(moment().format('yyyy-MM-DDTHH:mm:ss')).diff(
                moment(response?.messages[response?.messages?.length - 1]?.dateTime)
              )
            )
            .locale(lang)
            .humanize(),
          unReadCount: response?.countOfNewMessages,
          users: response?.users,
        }
        const temp = [...currentChats]

        const exists = temp?.find((i: any) => Number(i?.id) === Number(response?.chatGroupId))

        if (!exists) setCurrentChats([newChat, ...temp])
      })
      connection.on('ChatRemoved', (response: any) => {
        const temp = [...currentChats]
        const newChat = temp.filter((i: any) => i.id !== response.chatGroupId)

        setCurrentChats(newChat)
        navigate(company ? `/${company}/chat` : '/kidling/chat')
      })
    }
  }, [currentChats, connection])

  useEffect(() => {
    let temp: any = []
    if (chats?.length > 0) {
      chats?.map((item: any) => {
        temp.push({
          id: item?.chatGroupId,
          name: item?.chatGroupName,
          image: '/media/avatars/300-5.jpg',
          subtitle:
            item?.messages?.length > 0
              ? `${item?.messages[item?.messages?.length - 1]?.chatGroupMessageText?.substring(
                  0,
                  15
                )} ${
                  item?.messages[item?.messages?.length - 1]?.chatGroupMessageText.length > 15
                    ? ' ...'
                    : ''
                }`
              : '',
          date:
            item?.messages?.length > 0
              ? moment
                  .duration(
                    moment(moment().format('yyyy-MM-DDTHH:mm:ss')).diff(
                      moment(item?.messages[item?.messages?.length - 1]?.dateTime)
                    )
                  )
                  .locale(lang)
                  .humanize()
              : null,
          unReadCount: item?.countOfNewMessages,
          users: item?.users,
          isAdmin: item?.users?.find((u: any) => u.usersId === currentUser?.usersId)?.admin,
        })
      })
      setCurrentChats(temp)
    }
  }, [chats])

  const optionsDropDownMenu = (id: string) => {
    return {
      title: intl.formatMessage({id: 'KIDLING.CHAT_SETTINGS'}),
      menuButton: <i className='bi bi-three-dots-vertical  fs-6  '></i>,
      items: [
        {
          title: (
            <button className='btn p-0 m-0' type='button'>
              <div className='d-flex align-items-center'>
                {/* <i className='fa-solid fa-trash fs-6 text-danger  ' /> */}

                <span>{intl.formatMessage({id: 'COMMON.DELETE'})}</span>
              </div>
            </button>
          ),

          onClick: () => setShowDelete({show: true, id}),
        },
      ],
    }
  }

  const DoDelete = async (id: string) => {
    const resp = await deleteChatGroup(Number(id))
    if (!!resp) {
      readAllChats()
    }
  }
  return (
    <div className=' pt-4'>
      <div className='menu menu-column menu-rounded menu-state-bg menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary mb-10  '>
        {currentChats?.map((item: any, index: number) => {
          const otherUser = item.users?.find((i: any) => i.usersId !== currentUser?.usersId)

          const otherUserId = otherUser?.usersId
          return (
            <div
              className={` py-4 w-100 d-flex align-items-start justify-content-between fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 pe-4 ps-8 py-0 chat__header__contact ${
                item.id === Number(params['*']?.split('/')[1] ?? -1) && 'selected__chat'
              }`}
              key={index}
            >
              <Link
                to={
                  company
                    ? `/${company}/chat/private/${item.id}`
                    : `/kidling/chat/private/${item.id}`
                }
                style={{width: '90%'}}
              >
                <div className='d-flex align-items-end flex-stack'>
                  <div className='d-flex align-items-center'>
                    {item.users?.length > 2 ? (
                      <Badge
                        overlap='circular'
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        badgeContent={<SmallAvatar alt='group1' />}
                      >
                        <Badge
                          overlap='circular'
                          anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                          badgeContent={
                            <span
                              className={`badge badge-light-primary rounded-pill fs-7 ${
                                mode === 'dark' && 'bg-white text-dark'
                              }`}
                            >
                              {item?.users?.length}
                            </span>
                          }
                        >
                          <Avatar alt='group2' />
                        </Badge>
                      </Badge>
                    ) : (
                      // <BadgeAvatars image1={item.users[0].imageUrl} image2={item.users[1].imageUrl} />
                      <StyledBadge
                        overlap='circular'
                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                        variant='dot'
                        color={
                          onlineUsers?.includes(Number(otherUserId ?? -1)) ? 'success' : 'error'
                        }
                      >
                        <Avatar
                          alt={item.name.charAt(0).toUpperCase()}
                          src={`${process.env.REACT_APP_API_URL}${otherUser?.imageUrl}`}
                        />
                      </StyledBadge>
                    )}

                    <div className='ms-5 w-75'>
                      <div className='d-flex align-items-start'>
                        <span className=' '>
                          {item?.users?.length > 2 ? item.name : otherUser?.usersName}
                        </span>
                      </div>
                      <div className='fw-normal text-body fs-6 subtitle'>
                        {item?.users?.length > 2 ? item.subtitle : item?.name}
                      </div>
                    </div>
                  </div>
                  <div className='d-flex'>
                    <div className='d-flex flex-column align-items-end ms-2'>
                      {item.unReadCount > 0 && (
                        <span
                          className={`badge badge-sm badge-circle badge-primary fs-7 ${
                            mode === 'dark' && 'bg-white text-dark'
                          }`}
                        >
                          {item.unReadCount}
                        </span>
                      )}
                      <span className='fw-normal text-body fs-7 mb-1 date'>{item.date}</span>
                    </div>
                  </div>
                </div>
              </Link>
              {item?.isAdmin && <DropDownMenu data={optionsDropDownMenu(item.id)} />}
            </div>
          )
        })}
      </div>
      <SweetAlert
        show={showDelete?.show}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          DoDelete(showDelete?.id)
          setShowDelete({show: false, id: '-1'})
        }}
        onCancel={() => setShowDelete({show: false, id: '-1'})}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </div>
  )
}

export default PrivateChats
