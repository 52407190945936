import React, {FC, ReactElement, useCallback, useEffect, useState} from 'react'
import {Modal, ModalHeader, ModalBody} from 'reactstrap'
import Select from 'react-select'
import './vacationModal.scss'
import {selectStyle, selectStyleDarkMode} from '../../../../../utils/select-style'
import CustomTabs from '../../../../../general-components/custom-tabs'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {momentFullDateFormat} from '../../../../../utils/constants'
import {toast} from 'react-toastify'
import {useCompanyContext} from '../../../../../../_metronic/layout/core/CompanyProvider'
import {
  addEmployeeAbsence,
  getEmployeeAbsenceByDates,
  getEmployeeAbsencePolicy,
} from '../../../../../../actions/employee'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {Dayjs} from 'dayjs'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type Props = {
  show: boolean
  setShow: (v: boolean) => void
}
const AddVacationModal: FC<Props> = ({show, setShow}): ReactElement => {
  const intl = useIntl()
  const {mode} = useThemeMode()
  const {
    absencesReasons,
    setAllVacations,
    setGlobalLoader,
    currentVacationsYear,
    execludedDates,
    openingHours,
    absencePolicies,
    absenceAdjustments,
    employeeInfo,
  } = useAppContext()
  const {currentCompany, currentAbsenceType} = useCompanyContext()

  const [selectedTab, setSelectedTab] = useState(0)
  // useEffect(() => {
  //   const read = async () => {
  //     const resp = await getEmployeeAbsencePolicy('1=1')
  //     setAbsencePolicies(resp)
  //   }
  //   if (absencePolicies?.length === 0) read()
  // }, [])

  const handleClose = () => {
    setInitialValues({
      employeeAbsenceReason: null,
      employeeAbsenceName: '',
      employeeAbsenceEmployeeComments: '',
      employeeAbsenceFromDate: new Date(),
      employeeAbsenceToDate: new Date(),
    })
    formik.resetForm()
    setShow(false)
  }
  const [initialValues, setInitialValues] = useState<any>({
    employeeAbsenceReason: null,
    employeeAbsencePolicy: null,
    employeeAbsenceName: '',
    employeeAbsenceEmployeeComments: '',
    employeeAbsenceFromDate: new Date(),
    employeeAbsenceToDate: new Date(),
  })

  const Schema = Yup.object().shape({
    employeeAbsenceReason: Yup.mixed().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    employeeAbsencePolicy: Yup.mixed(),
    // employeeAbsenceName: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    employeeAbsenceEmployeeComments: Yup.string().nullable(),
    employeeAbsenceFromDate: Yup.date()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    employeeAbsenceToDate:
      selectedTab !== 0
        ? Yup.mixed()
        : Yup.date()
            .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
            .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'}))
            .test(
              'isValidDate',
              intl.formatMessage({id: 'KIDLING.END_TIME_MUST_BE_GREATER-THEN_START_TIME'}),
              (value: any, context: any) => {
                const totalminutes = moment
                  .duration(moment(value).diff(moment(context.parent.employeeAbsenceFromDate)))
                  .asMinutes()

                if (totalminutes >= 0) {
                  return true
                }
                return false
              }
            ),
  })
  const formik: any = useFormik({
    initialValues: initialValues,

    validationSchema: Schema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data: any = {
          employeeAbsenceEmployeeComments: values?.employeeAbsenceEmployeeComments,
          employeeAbsenceReasonId: values?.employeeAbsenceReason?.employeeAbsenceReasonId,
          companyId: currentCompany?.value,
          employeeAbsenceFromDate: moment(values.employeeAbsenceFromDate).format(
            momentFullDateFormat
          ),
          employeeAbsenceToDate: moment(
            selectedTab !== 0
              ? moment(values.employeeAbsenceFromDate).endOf('day')
              : values.employeeAbsenceToDate
          ).format(momentFullDateFormat),
          dayAbsenceType: selectedTab !== 0 ? 0 : 1,
        }

        if (!values.employeeAbsenceReason?.deductAbsenceDaysQuota) {
          data.employeeAbsenceAdjustmentId = 0
          data.employeeAbsencePolicyId = 0
        } else if (values?.employeeAbsencePolicy?.employeeAbsenceAdjustmentId)
          data.employeeAbsenceAdjustmentId =
            values?.employeeAbsencePolicy?.employeeAbsenceAdjustmentId
        else if (values?.employeeAbsencePolicy?.employeeAbsencePolicyId)
          data.employeeAbsencePolicyId = values?.employeeAbsencePolicy?.employeeAbsencePolicyId

        const resp = await addEmployeeAbsence(data)
        if (!!resp) {
          if (getVacations) getVacations()
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
          handleClose()
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
        setSubmitting(false)
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })
  const getVacations = async () => {
    setGlobalLoader(true)
    const resp = await getEmployeeAbsenceByDates(
      currentAbsenceType,
      moment(currentVacationsYear).format(momentFullDateFormat),
      moment(currentVacationsYear).endOf('year').format(momentFullDateFormat)
    )
    setAllVacations(resp)
    setGlobalLoader(false)
  }

  const shouldDisableDate = useCallback(
    (date: Dayjs) => {
      let dateExecluded = false
      execludedDates.map((item: any) => {
        if (
          moment(date.toDate()).isSameOrAfter(moment(item.start)) &&
          moment(date.toDate()).isSameOrBefore(moment(item.end))
        ) {
          dateExecluded = true
        }
      })
      if (!dateExecluded) {
        const day = date.day()

        if (openingHours?.length === 0) {
          return day === 0 || day === 6
        } else {
          const exists = openingHours?.find((item: any) => item.weekdayId === day)
          return exists ? false : true
        }
      } else {
        return true
      }
    },
    [openingHours, execludedDates]
  )

  return (
    <Modal
      isOpen={show}
      toggle={handleClose}
      fullscreen={true}
      zIndex={1000}
      onClosed={handleClose}
      fade
      modalClassName='vacaionModal'
    >
      <form
        id='kt_modal_add_user_form'
        className='form container'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <ModalHeader>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <a
              onClick={handleClose}
              className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Back'
            >
              <i className='fa-solid fa-close fs-5 m-0'></i>
            </a>
            {employeeInfo?.managers?.length <= 0 &&
              formik.values.employeeAbsenceReason?.requireApproval && (
                <div className='alert alert-danger' role='alert'>
                  {intl.formatMessage({id: 'KIDLING.HR.DONT_HAVE_MANAGER'})}
                </div>
              )}
            {((employeeInfo?.managers?.length > 0 &&
              formik.values.employeeAbsenceReason?.requireApproval) ||
              !formik.values.employeeAbsenceReason?.requireApproval) && (
              <button
                className='btn ms-auto d-flex align-items-center'
                type='submit'
                disabled={formik.isSubmitting}
              >
                {!formik.isSubmitting && <i className='fa-solid fa-check ms-2 fs-3 text-dark'></i>}
                {formik.isSubmitting && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            )}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='w-100 d-flex justify-content-center'>
            <div className='d-flex flex-column w-100 mw-500px'>
              <Select
                name='employeeAbsenceReason'
                options={absencesReasons}
                className='basic-multi-select'
                classNamePrefix='select'
                isClearable
                placeholder={intl.formatMessage({id: 'KIDLING.ABSENCE_REASON'})}
                getOptionLabel={(option) => option.employeeAbsenceReasonName}
                getOptionValue={(option) => option.employeeAbsenceReasonId}
                onChange={(val: any) => formik.setFieldValue('employeeAbsenceReason', val)}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
              />
              {formik.touched.employeeAbsenceReason && formik.errors.employeeAbsenceReason && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.employeeAbsenceReason}</span>
                  </div>
                </div>
              )}
              <div className='my-3 d-flex flex-wrap'>
                {absencePolicies &&
                  formik.values.employeeAbsenceReason?.deductAbsenceDaysQuota &&
                  absencePolicies?.map((p: any, index: number) => (
                    <label
                      key={index}
                      onClick={() => {
                        formik.setFieldValue('employeeAbsencePolicy', p)
                      }}
                    >
                      <input
                        className={`form-check-input  m-2 me-0`}
                        type='radio'
                        name='vacationFilter'
                        checked={formik.values.employeeAbsencePolicy === p}
                      />

                      <span className='btn btn-sm btn-color-muted   px-4'>
                        {p?.employeeAbsencePolicyName}
                      </span>
                    </label>
                  ))}
                {absenceAdjustments &&
                  formik.values.employeeAbsenceReason?.deductAbsenceDaysQuota &&
                  absenceAdjustments?.map((p: any, index: number) => (
                    <label
                      key={index}
                      onClick={() => {
                        formik.setFieldValue('employeeAbsencePolicy', p)
                      }}
                    >
                      <input
                        className={`form-check-input  m-2 me-0`}
                        type='radio'
                        name='vacationFilter'
                        checked={formik.values.employeeAbsencePolicy === p}
                      />

                      <span className='btn btn-sm btn-color-muted   px-4'>
                        {p?.employeeAbsenceEntitlementName}
                      </span>
                    </label>
                  ))}

                {/* <Select
                  name='employeeAbsencePolicyId'
                  options={absencePolicies}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  isClearable
                  placeholder={intl.formatMessage({id: 'KIDLING.ABSENCE_POLICY'})}
                  getOptionLabel={(option) => option.employeeAbsencePolicyName}
                  getOptionValue={(option) => option.employeeAbsencePolicyId}
                  onChange={(val: any) =>
                    formik.setFieldValue('employeeAbsencePolicyId', val.employeeAbsencePolicyId)
                  }
                  styles={selectStyle}
                  theme={(theme) => ({
                    ...theme,

                    colors: {
                      ...theme.colors,
                      primary25: 'var(--bs-dark-light)',
                      primary: 'var(--bs-gray)',
                      neutral0: 'var(--bs-dark-light)',
                    },
                  })}
                /> */}
                {formik.touched.employeeAbsencePolicyId && formik.errors.employeeAbsencePolicyId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.employeeAbsencePolicyId}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='my-3'>
                <textarea
                  className='form-control '
                  rows={2}
                  name='employeeAbsenceEmployeeComments'
                  placeholder={intl.formatMessage({id: 'KIDLING.HOME.DESCRIPTION'})}
                  onChange={formik.handleChange}
                ></textarea>
                {formik.touched.employeeAbsenceEmployeeComments &&
                  formik.errors.employeeAbsenceEmployeeComments && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='alert-danger'>
                          {formik.errors.employeeAbsenceEmployeeComments}
                        </span>
                      </div>
                    </div>
                  )}
              </div>

              <div className='my-2'>
                <CustomTabs
                  onChange={setSelectedTab}
                  options={[
                    intl.formatMessage({id: 'KIDLING.DAY'}),
                    intl.formatMessage({id: 'KIDLING.HALF_DAY'}),
                  ]}
                  fullWidth
                />
              </div>
              <div className='my-2'>
                {selectedTab === 1 && (
                  <ul className='ps-0 '>
                    <li className='  mb-2 p-2  ps-3 border rounded d-flex align-items-center justify-content-end w-100'>
                      <div className='d-flex align-items-center justify-content-between w-100 custom__select '>
                        <p className='mb-0'> {intl.formatMessage({id: 'KIDLING.HOME.DATE'})}</p>
                        <div>
                          <ResponsiveDatePicker
                            onAccept={(val: any) =>
                              formik.setFieldValue('employeeAbsenceFromDate', val)
                            }
                            value={formik.values.employeeAbsenceFromDate}
                            minDate={moment().toDate()}
                            // shouldDisableDate={shouldDisableDate}
                          />

                          {formik.touched.employeeAbsenceFromDate &&
                            formik.errors.employeeAbsenceFromDate && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span className='alert-danger'>
                                    {formik.errors.employeeAbsenceFromDate}
                                  </span>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </li>
                    {/* <li className=' d-flex align-items-center w-100'>
                    <div className='d-flex align-items-center justify-content-between w-100 custom__select'>
                      <p>Teil des Tages</p>
                      <div>
                        <Select
                          name='day'
                          options={partOfTheDay}
                          className='basic-multi-select'
                          classNamePrefix='select'
                          placeholder='Leave Type'
                          onChange={(val: any) => console.log(val)}
                          styles={selectStyleNoBorder}
                          value={partOfTheDay[0]}
                          theme={(theme) => ({
                            ...theme,

                            colors: {
                              ...theme.colors,
                              primary25: 'var(--bs-dark-light)',
                              primary: 'var(--bs-gray)',
                              neutral0: 'var(--bs-dark-light)',
                            },
                          })}
                        />
                      </div>
                    </div>
                  </li> */}
                  </ul>
                )}
                {selectedTab === 0 && (
                  <ul className=' ps-0  '>
                    <li className=' mb-2 p-2 ps-3 border rounded d-flex align-items-center justify-content-end w-100'>
                      <div className='d-flex align-items-center justify-content-between w-100 custom__select '>
                        <p className='m-0'>{intl.formatMessage({id: 'KIDLING.HOME.FROMDATE'})}</p>
                        <div>
                          <ResponsiveDatePicker
                            onAccept={(val: any) =>
                              formik.setFieldValue('employeeAbsenceFromDate', val)
                            }
                            value={formik.values.employeeAbsenceFromDate}
                            minDate={moment().toDate()}
                            // shouldDisableDate={shouldDisableDate}
                          />

                          {formik.touched.employeeAbsenceFromDate &&
                            formik.errors.employeeAbsenceFromDate && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span className='alert-danger'>
                                    {formik.errors.employeeAbsenceFromDate}
                                  </span>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </li>
                    <li className='p-2  ps-3 border rounded d-flex align-items-center w-100'>
                      <div className='d-flex align-items-center justify-content-between w-100 custom__select'>
                        <p className='m-0'>{intl.formatMessage({id: 'KIDLING.HOME.TODATE'})}</p>
                        <div>
                          <ResponsiveDatePicker
                            onAccept={(val: any) =>
                              formik.setFieldValue('employeeAbsenceToDate', val)
                            }
                            value={formik.values.employeeAbsenceToDate}
                            minDate={moment(formik.values.employeeAbsenceFromDate).toDate()}
                            // shouldDisableDate={shouldDisableDate}
                          />

                          {formik.touched.employeeAbsenceFromDate &&
                            formik.errors.employeeAbsenceFromDate && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span className='alert-danger'>
                                    {formik.errors.employeeAbsenceFromDate}
                                  </span>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </form>
    </Modal>
  )
}

export default AddVacationModal
