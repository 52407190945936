/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {useIntl} from 'react-intl'
import {readFileFrom} from '../../../../actions/image'
import {useAppContext} from '../../../layout/core/AppContextProvider'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const {currentUserImage, setCurrentUserImage} = useAppContext()
  const intl = useIntl()
  const company = window.location.pathname.split('/')[1]

  useEffect(() => {
    getImage()
  }, [])

  const getImage = async () => {
    const resp = await readFileFrom(currentUser?.imagePath ?? '')
    if (resp) setCurrentUserImage(resp)
  }
  return (
    <div className='bg-body py-2 menu menu-sub  d-block menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-0 fs-6 w-250px w-sm-275px'>
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='Logo'
              src={currentUserImage ?? '/media/svg/avatars/blank.svg'}
              className='object-fit-cover'
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.last_name}
              {/* <span className='badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href='#' className='fw-semibold text-muted text-hover-primary fs-7'>
              {currentUser?.usersName}
            </a>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-5'>
        <Link to={company ? `/${company}/profile` : '/kidling/profile'} className='menu-link px-5'>
          {intl.formatMessage({id: 'KIDLING.HOME.MY_PROFILE'})}
        </Link>
      </div>

      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bold fs-7'>3</span>
          </span>
        </a>
      </div> */}
      {/* <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div> */}

      <div className='separator my-2'></div>
      <Languages />

      {/* <div className='menu-item px-5 my-1'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Account Settings
        </Link>
      </div> */}
      <div className='menu-item px-5'>
        <a
          onClick={async () => {
            setCurrentUserImage(null)
            if ((window as any)['ReactNativeWebView'])
              (window as any)['ReactNativeWebView'].postMessage(JSON.stringify({logout: true}))
            logout()
          }}
          className='menu-link px-5'
        >
          {intl.formatMessage({id: 'KIDLING.HOME.SIGN_OUT'})}
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
