import {UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem} from 'reactstrap'
import moment from 'moment'
import {useIntl} from 'react-intl'
import useCompanyInfo from '../../../../hooks/useCompanyInfo'
function CompanyInformation() {
  const intl = useIntl()
  const {companyInfo, daysOff, holidays} = useCompanyInfo()
  return (
    <div className='p-8'>
      <UncontrolledAccordion>
        <AccordionItem>
          <AccordionHeader targetId={`0`}>
            <h6 className='ms-4'>{intl.formatMessage({id: 'KIDLING.HOLIDAYS'})}</h6>
          </AccordionHeader>
          <AccordionBody accordionId={`0`}>
            {holidays?.map((item: any, index: number) => (
              <div
                key={index}
                className='d-flex justify-content-between shadow rounded m-2 py-2 px-4 align-items-center'
              >
                <div className='m-0 p-0 fs-4 text-bold'>{item.holidayName}</div>
                <div className='d-flex  flex-column align-items-end'>
                  {/* <p className=' badge badge-primary mb-0'>
                    <span className=' me-1'> {item.numberOfDays}</span>

                    {intl.formatMessage({id: 'KIDLING.DAY'})}
                  </p> */}
                  <p className='m-0 p-0 text-body'>
                    <span className='badge badge-primary mb-0'>
                      {moment(item.holidayDate).format('ddd  MMM DD, yyyy')}
                    </span>
                    {item.numberOfDays > 1 && (
                      <>
                        <span className='mx-2'>
                          <i className='fa-solid fa-angles-right text-body'></i>
                        </span>
                        <span className='badge badge-primary mb-0'>
                          {moment(
                            moment(item.holidayDate).add(Number(item.numberOfDays) - 1, 'days')
                          ).format('ddd  MMM DD, yyyy')}
                        </span>
                      </>
                    )}
                  </p>
                </div>
              </div>
            ))}
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId={`1`}>
            <h6 className='ms-4'>{intl.formatMessage({id: 'KIDLING.DAYS_OFF'})}</h6>
          </AccordionHeader>
          <AccordionBody accordionId={`1`}>
            {daysOff?.map((item: any, index: number) => (
              <div
                key={index}
                className='d-flex justify-content-between shadow rounded m-2 py-2 px-4 align-items-center'
              >
                <div className='m-0 p-0 fs-4 text-bold'>{item.dayOffName}</div>
                <div className='d-flex  flex-column align-items-end'>
                  <span className='badge badge-primary mb-0'>
                    {moment(item.dayOffDate).format('ddd  MMM DD, yyyy')}
                  </span>
                </div>
              </div>
            ))}
          </AccordionBody>
        </AccordionItem>
        {companyInfo &&
          companyInfo?.map((item: any, index1) => (
            <AccordionItem key={index1 + 2}>
              <AccordionHeader targetId={`${index1 + 2}`}>
                <h6 className='ms-4'>{item?.companyInfoTitle}</h6>
              </AccordionHeader>
              <AccordionBody accordionId={`${index1 + 2}`}>
                <p
                  className='text-body'
                  dangerouslySetInnerHTML={{__html: item?.companyInfoDescription}}
                ></p>
              </AccordionBody>
            </AccordionItem>
          ))}
      </UncontrolledAccordion>
    </div>
  )
}

export default CompanyInformation
