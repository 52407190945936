import React, {useEffect, useState} from 'react'
import {HubConnectionBuilder, LogLevel} from '@microsoft/signalr'
import {useAuth} from '../../modules/auth'
import {useChatContext} from '../../context/ChatProvider'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'

function ChatInitializer() {
  const {setConnection, setOnlineUsers, connection} = useChatContext()
  const {mobileConfiguration} = useAppContext()
  const [isConnecting, setIsConnecting] = useState(false)
  const {auth, socketUrl} = useAuth()

  useEffect(() => {
    if (
      ((connection?._connectionState !== 'Connected' &&
        connection?._connectionState !== 'Connecting') ||
        !connection) &&
      auth &&
      mobileConfiguration &&
      mobileConfiguration[6]?.length > 0 &&
      mobileConfiguration[6][0]?.showInHrApp &&
      !!socketUrl
    ) {
      console.log('in the global', connection)

      startConnection()
    }
  }, [connection, auth, mobileConfiguration, socketUrl])
  const startConnection = async () => {
    try {
      setIsConnecting(true)
      if (!!socketUrl) {
        const connection: any = new HubConnectionBuilder()
          .withUrl(socketUrl + 'chatHub', {
            accessTokenFactory: () => `${auth?.token}`,
          })
          .configureLogging(LogLevel.Error)
          .build()
        setConnection(connection)
        await connection.start()

        connection.on('OnlineUsers', function (response: any) {
          setOnlineUsers(response)
        })
      }
      setIsConnecting(false)
    } catch (e) {
      setIsConnecting(false)
    }
  }
  return <></>
}

export default ChatInitializer
