import {Fragment, useEffect, useRef} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import allLocales from '@fullcalendar/core/locales-all'
import {useLang} from '../../../../../../_metronic/i18n/Metronici18n'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import Select from 'react-select'
import {selectStyle, selectStyleDarkMode} from '../../../../../utils/select-style'
import ShiftCell from './ShiftCell'
import ShiftFileCard from './ShiftFileCard'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import useShifts from '../../../../../hooks/useShifts'

function Shifts() {
  const {mode} = useThemeMode()
  const calendarRef = useRef<any>()
  const intl = useIntl()
  const locale = useLang()
  const navigate = useNavigate()
  const {
    employeeShiftsFiles,
    dateFilter,
    selectedShiftType,
    readAllShiftsFiles,
    readAllShifts,
    triggerDateChanged,
    setSelectedShiftType,
    isLoading,
    formattedShifts,
  } = useShifts()
  const company = window.location.pathname.split('/')[1]
  const {allShiftTypes, mobileConfiguration} = useAppContext()
  useEffect(() => {
    if (
      !(
        (mobileConfiguration[8]?.length > 0 && mobileConfiguration[8][0]?.showInHrApp) ||
        (mobileConfiguration[9]?.length > 0 && mobileConfiguration[9][0]?.showInHrApp)
      )
    ) {
      navigate('/')
    }
  }, [mobileConfiguration])
  useEffect(() => {
    readAllShifts()
    readAllShiftsFiles()
  }, [dateFilter, selectedShiftType])
  return (
    <div className='card'>
      <div className='card-header align-items-center justify-content-between  py-5 gap-5 w-100'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <Link to={company ? `/${company}/employee/main` : '/kidling/employee/main'}>
            <i className='fa-solid fa-arrow-left fs-3 me-4'></i>
          </Link>
        </div>
      </div>
      <div className='card-body'>
        <div className='d-flex flex-column align-items-center w-100'>
          <div className='w-100 '>
            {employeeShiftsFiles?.map((item: any, index: number) => (
              <Fragment key={index}>
                <ShiftFileCard {...item} />
              </Fragment>
            ))}
            <div className=' w-100   my-3  '>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.HR_SHIFT_TYPE'})}
              </label>
              <Select
                name='shiftType'
                options={allShiftTypes}
                getOptionLabel={(option) => option?.employeeShiftTypeName}
                getOptionValue={(option) => option?.employeeShiftTypeId}
                className='basic-multi-select'
                classNamePrefix='select'
                isClearable
                onChange={(val: any) => setSelectedShiftType(val)}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                value={selectedShiftType}
              />
            </div>
            <FullCalendar
              ref={calendarRef}
              plugins={[rrulePlugin, dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay,listDay',
              }}
              slotMinTime='07:00'
              slotMaxTime='20:00'
              initialView='timeGridWeek'
              allDaySlot={false}
              weekends={true}
              editable={false}
              selectable={true}
              selectMirror={false}
              dayMaxEvents={false}
              initialEvents={formattedShifts}
              events={formattedShifts}
              eventContent={ShiftCell}
              locale={locale}
              locales={allLocales}
              firstDay={1}
              nowIndicator={true}
              datesSet={triggerDateChanged}
            />
          </div>
        </div>
      </div>
      {isLoading && <UsersListLoading />}
    </div>
  )
}

export default Shifts
