import moment from 'moment'
import React, {useState, createContext, useContext, FC} from 'react'
const AppContext: any = createContext<any>(null)

export const useAppContext = (): any => {
  return useContext(AppContext)
}

export default function AppContextProvider({children}: any) {
  const [kidsList, setKidsList] = useState<any>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [filters, setFilters] = useState('')
  const [languages, setLanguages] = useState([])
  const [translations, setTranslations] = useState([])
  const [mealtime, setMealtime] = useState([])
  const [attendanceLocations, setAttendanceLocations] = useState([])
  const [absentReasons, setAbsentReasons] = useState([])
  const [activities, setActivities] = useState([])
  const [moods, setMoods] = useState([])
  const [requestItems, setRequestItems] = useState([])
  const [allEvents, setAllEvents] = useState([])
  const [mealPortions, setMealPortions] = useState([])
  const [allRooms, setAllRooms] = useState([])
  const [foodItems, setFoodItems] = useState([])
  const [sleepHabits, setSleepHabits] = useState([])
  const [currentUserImage, setCurrentUserImage] = useState(null)
  const [employeeInfo, setEmployeeInfo] = useState(null)
  const [globalLoader, setGlobalLoader] = useState(false)
  const [day, setDay] = useState(1)
  const [allAttendance, setAllAttendance] = useState([])
  const [allAbsense, setAllAbsense] = useState([])
  const [kidsActivites, setKidsActivites] = useState([])
  const [kidsMealTimes, setKidsMealTimes] = useState([])
  const [kidsSleeptimes, setKidsSleeptimes] = useState([])
  const [kidsDrinks, setKidsDrinks] = useState([])
  const [kidsProtocol, setKidsProtocol] = useState([])
  const [kidsRequests, setKidsRequests] = useState([])
  const [kidsServices, setKidsServices] = useState([])
  const [kidsMedicalRecords, setKidsMedicalRecords] = useState([])
  const [kidsDeregistrations, setKidsDeregistrations] = useState([])

  const [internalPagesFilters, setInternalPagesFilters] = useState({
    activity: {},
    attendance: {},
    absense: {},
    mealtimes: {},
    sleeptimes: {},
    drinks: {},
    protocols: {},
    requests: {},
    services: {},
    medical: {},
    deregistration: {},
  })
  const [internalPagesDatesFilters, setInternalPagesDatesFilters] = useState({
    activity: {},
    attendance: {},
    absense: {},
    mealtimes: {},
    sleeptimes: {},
    drinks: {},
    protocols: {},
    requests: {},
    services: {},
    medical: {},
    deregistration: {},
  })
  const [mainSearchTerm, setMainSearchTerm] = useState('')
  const [companies, setCompanies] = useState([])
  const [employees, setEmployees] = useState([])
  const [classes, setClasses] = useState([])
  const [medicalTypes, setMedicalTypes] = useState([])
  const [delivered, setDelivered] = useState(0)
  const [tabMessages, setTabMessages] = useState(null)
  const [allSubMessages, setAllSubMessages] = useState([])
  const [messagesFilters, setMessagesFilters] = useState({
    inbox: {},
    sent: {},
    marked: {},
    deleted: {},
  })
  const [globalLoader2, setGlobalLoader2] = useState(false)
  const [contacts, setContacts] = useState([])
  const [companyMetaData, setCompanyMetaData] = useState({})
  const [countries, setCountries] = useState([])
  const [cities, setCities] = useState([])
  const [eventsCategories, setEventsCategories] = useState([])

  const [calendarFilter, setCalendarFilter] = useState(null)
  const [allAssessments, setAllAssessments] = useState([])
  const [assessmentCategory, setAssessmentCategory] = useState([])
  const [assessmentItem, setAssessmentItem] = useState([])
  const [assessmentItemRate, setAssessmentItemRate] = useState([])
  const [assessmentSubCategory, setAssessmentSubCategory] = useState([])
  const [assessmentRateMethod, setAssessmentRateMethod] = useState([])
  const [genders, setGenders] = useState([])
  const [departments, setDepartments] = useState([])
  const [nationalities, setNationalities] = useState([])
  const [positions, setPositions] = useState([])
  const [states, setStates] = useState([])
  const [employeeTypes, setEmployeeTypes] = useState([])
  const [costCenter, setCostCenter] = useState([])
  const [attendanceTypes, setAttendanceTypes] = useState([])
  const [employeeAttendance, setEmployeeAttendance] = useState([])
  const [employeeShifts, setEmployeeShifts] = useState([])
  const [allAssessmentsWithCats, setAllAssessmentsWithCats] = useState([])
  const [initialAssessments, setInitialAssessments] = useState([])
  const [selectedKidAssessments, setSelectedKidAssessments] = useState<any>([])
  const [mobileConfiguration, setMobileConfiguration] = useState(null)
  const [calenderDateFilter, setCalenderDateFilter] = useState({})
  const [todayEmployeeAttendance, setTodayEmployeeAttendance] = useState([])
  const [employeeAttendanceByDate, setEmployeeAttendanceByDate] = useState([])
  const [absencesReasons, setAbsencesReasons] = useState([])
  const [allVacations, setAllVacations] = useState([])
  const [currentVacationsYear, setCurrentVacationsYear] = useState(
    moment().startOf('year').toDate()
  )
  const [tabMessagesCounts, setTabMessagesCounts] = useState(null)
  const [currentAbsenceType, setCurrentAbsenceType] = useState(null)
  const [employeeAbsenceStatistics, setEmployeeAbsenceStatistics] = useState(null)
  const [employeeAttendaceStatistics, setEmployeeAttendaceStatistics] = useState<any>({})
  const [holidays, setHolidays] = useState([])
  const [daysOff, setDaysOff] = useState([])
  const [openingHours, setOpeningHours] = useState([])
  const [execludedDates, setExecludedDates] = useState([])
  const [allShiftTypes, setAllShiftTypes] = useState([])
  const [isFinishedLoadingMetaData, setIsFinishedLoadingMetaData] = useState(false)
  const [inboxData, setInboxData] = useState<any>([])
  const [absencePolicies, setAbsencePolicies] = useState([])
  const [absenceAdjustments, setAbsenceAdjustments] = useState([])
  const [employeePinboard, setEmployeePinboard] = useState([])
  const [currentTimesheet, setCurrentTimesheet] = useState<any>({})
  return (
    <AppContext.Provider
      value={{
        filters,
        kidsList,
        attendanceLocations,
        mealtime,
        translations,
        languages,
        searchTerm,
        activities,
        absentReasons,
        moods,
        requestItems,
        allEvents,
        mealPortions,
        allRooms,
        foodItems,
        sleepHabits,
        currentUserImage,
        employeeInfo,
        allAttendance,
        globalLoader,
        day,
        allAbsense,
        kidsActivites,
        kidsMealTimes,
        kidsSleeptimes,
        kidsDrinks,
        kidsProtocol,
        kidsRequests,
        kidsServices,
        internalPagesFilters,
        companies,
        employees,
        classes,
        medicalTypes,
        kidsMedicalRecords,
        delivered,
        tabMessages,
        allSubMessages,
        messagesFilters,
        globalLoader2,
        mainSearchTerm,
        internalPagesDatesFilters,
        contacts,
        tabMessagesCounts,
        companyMetaData,
        countries,
        cities,
        kidsDeregistrations,
        eventsCategories,
        calendarFilter,
        allAssessments,
        assessmentCategory,
        assessmentItem,
        assessmentItemRate,
        assessmentSubCategory,
        assessmentRateMethod,
        genders,
        departments,
        nationalities,
        positions,
        states,
        employeeTypes,
        costCenter,
        attendanceTypes,
        employeeAttendance,
        employeeShifts,
        allAssessmentsWithCats,
        initialAssessments,
        selectedKidAssessments,
        mobileConfiguration,
        calenderDateFilter,
        todayEmployeeAttendance,
        employeeAttendanceByDate,
        absencesReasons,
        allVacations,
        currentVacationsYear,
        currentAbsenceType,
        employeeAbsenceStatistics,
        employeeAttendaceStatistics,
        openingHours,
        daysOff,
        holidays,
        execludedDates,
        allShiftTypes,
        isFinishedLoadingMetaData,
        inboxData,
        absencePolicies,
        absenceAdjustments,
        employeePinboard,
        currentTimesheet,
        setKidsList,
        setSearchTerm,
        setLanguages,
        setTranslations,
        setAttendanceLocations,
        setFilters,
        setMealtime,
        setAbsentReasons,
        setActivities,
        setMoods,
        setRequestItems,
        setAllEvents,
        setMealPortions,
        setAllRooms,
        setFoodItems,
        setSleepHabits,
        setCurrentUserImage,
        setEmployeeInfo,
        setAllAttendance,
        setGlobalLoader,
        setDay,
        setAllAbsense,
        setKidsActivites,
        setKidsMealTimes,
        setKidsSleeptimes,
        setKidsDrinks,
        setKidsProtocol,
        setKidsRequests,
        setKidsServices,
        setInternalPagesFilters,
        setCompanies,
        setEmployees,
        setClasses,
        setMedicalTypes,
        setKidsMedicalRecords,
        setDelivered,
        setTabMessages,
        setAllSubMessages,
        setMessagesFilters,
        setGlobalLoader2,
        setMainSearchTerm,
        setInternalPagesDatesFilters,
        setContacts,
        setTabMessagesCounts,
        setCompanyMetaData,
        setCountries,
        setCities,
        setKidsDeregistrations,
        setEventsCategories,
        setCalendarFilter,
        setAssessmentSubCategory,
        setAssessmentItemRate,
        setAssessmentItem,
        setAssessmentCategory,
        setAllAssessments,
        setAssessmentRateMethod,
        setGenders,
        setDepartments,
        setNationalities,
        setPositions,
        setStates,
        setEmployeeTypes,
        setCostCenter,
        setAttendanceTypes,
        setEmployeeAttendance,
        setEmployeeShifts,
        setAllAssessmentsWithCats,
        setSelectedKidAssessments,
        setInitialAssessments,
        setMobileConfiguration,
        setCalenderDateFilter,
        setTodayEmployeeAttendance,
        setEmployeeAttendanceByDate,
        setAbsencesReasons,
        setAllVacations,
        setCurrentVacationsYear,
        setCurrentAbsenceType,
        setEmployeeAbsenceStatistics,
        setEmployeeAttendaceStatistics,
        setOpeningHours,
        setDaysOff,
        setHolidays,
        setExecludedDates,
        setAllShiftTypes,
        setIsFinishedLoadingMetaData,
        setInboxData,
        setAbsencePolicies,
        setAbsenceAdjustments,
        setEmployeePinboard,
        setCurrentTimesheet,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
