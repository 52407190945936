import React, {useEffect, useState, FC, ReactElement} from 'react'
import {selectStyle, selectStyleDarkMode} from '../../../../utils/select-style'
import makeAnimated from 'react-select/animated'
import SelectWithAllOption from '../../../../general-components/select-with-all/SelectWithAllOption'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import AvatarWithLetters from '../../../../general-components/avatar-with-letters'
import {useAuth} from '../../../auth'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {toast} from 'react-toastify'
import {getAllEmployeesByKita} from '../../../../../actions/employee'
import {addNewChat, addUsersToChat} from '../../../../../actions/chat'
import {useCompanyContext} from '../../../../../_metronic/layout/core/CompanyProvider'

const animatedComponents = makeAnimated()

interface Props {
  showModal: boolean
  setShowModal: any
  chatId?: number
}
const NewChatDialog: FC<Props> = ({showModal, setShowModal, chatId}): ReactElement => {
  const {mode} = useThemeMode()
  const intl = useIntl()
  const {currentUser, auth} = useAuth()
  const {currentCompany} = useCompanyContext()
  const {employees, setEmployees} = useAppContext()
  const [selectedEmployee, setSelectedEmployee] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [groupName, setGroupName] = useState('')

  useEffect(() => {
    if (currentCompany?.value) readEmployees()
  }, [currentCompany])

  useEffect(() => {
    setSelectedEmployee(employees.filter((i: any) => i?.usersId === currentUser?.usersId))
  }, [employees, currentUser])

  const readEmployees = async () => {
    try {
      let filter = '('
      currentUser?.employee?.companyIds?.map((id: number) => {
        if (filter !== '(') filter += ' OR '
        filter += ` CompanyIds.Any(x => x.id == ${id}) `
      })
      filter += ')'

      const resp = await getAllEmployeesByKita(filter)

      let temp: any = []
      resp?.map(
        (item: any) =>
          item.employeeId !== currentUser?.employee?.employeeId &&
          item.active &&
          temp.push({
            value: item.employeeId,
            label: item.employeeName,
            email: item.email,
            imagePath: item.imagePath,
            usersId: item.usersId,
            companyId: item.companyId,
          })
      )

      temp.sort((a: any, b: any) => a.label.localeCompare(b.label))

      setEmployees(temp)
    } catch (e) {}
  }

  const close = () => {
    setShowModal(false)
    setGroupName('')
    setSelectedEmployee([])
  }

  const addChat = async () => {
    setIsLoading(true)
    let employeeIds: any = [currentUser?.employee?.employeeTypeId]

    selectedEmployee?.map((i: any) => employeeIds.push(i?.value))
    if (chatId) {
      const data = {
        chatGroupId: chatId,
        classesIds: [],
        employeeIds: [],
        kidIds: [],
      }

      const resp = await addUsersToChat(data)

      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      close()
    } else {
      const data = {
        chatGroupName: groupName,
        isPublic: false,
        companyId: selectedEmployee?.companyId,
        classesIds: [],
        employeeIds: employeeIds,
        kidIds: [],
      }

      const resp = await addNewChat(data)
      if (resp) {
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        close()
      }
    }

    setIsLoading(false)
  }

  return (
    <Modal isOpen={showModal} toggle={close}>
      <ModalHeader toggle={close}>
        {intl.formatMessage({id: chatId ? 'KIDLIGN.ADD_USERS_TO_GROUP' : 'KIDLING.CHAT_NEW_CHAT'})}
      </ModalHeader>
      <ModalBody>
        <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
          <SelectWithAllOption
            openMenuOnFocus={true}
            value={selectedEmployee}
            onChange={(val: any) => setSelectedEmployee(val)}
            options={employees?.length > 0 ? employees : []}
            isClearable={true}
            components={animatedComponents}
            isLoading={false}
            isMulti
            allowSelectAll
            formatOptionLabel={(item: any) => (
              <div className='d-flex align-items-center '>
                {item.value !== '*' && (
                  <AvatarWithLetters
                    image={item.imagePath}
                    alt={`${item.label[0].charAt(0).toUpperCase()}  `}
                    rounded
                    extrasmall
                  />
                )}

                <div className='d-flex flex-column ms-2  '>
                  <p className='p-0 m-0'>{item.label}</p>
                  <p className='p-0 m-0 text-muted'>{item.email}</p>
                </div>
              </div>
            )}
            className='w-100'
            styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
            theme={(theme: any) => ({
              ...theme,

              colors: {
                ...theme.colors,
                primary25: 'var(--bs-dark-light)',
                primary: 'var(--bs-gray)',
                neutral0: 'var(--bs-dark-light)',
              },
            })}
            placeholder={intl.formatMessage({id: 'KIDLING.EMPLOYEE'})}
          />
        </div>
        {!chatId && (
          <div className='d-flex flex-column align-items-center border-bottom px-8 min-h-50px '>
            <input
              type='text'
              className={'form-control w-100 text-body  mb-1'}
              name='groupName'
              autoComplete='off'
              placeholder={intl.formatMessage({id: 'KIDLING.CHAT_NAME'})}
              onChange={(e) => setGroupName(e.target.value)}
              value={groupName}
            />
          </div>
        )}
        {/* <div className='d-flex align-items-center border-bottom px-8 min-h-50px'>
          <SelectWithAllOption
            openMenuOnFocus={true}
            value={selectedContacts}
            getOptionLabel={(option: any) => option?.contactName}
            getOptionValue={(option: any) => option?.contactId}
            onChange={(val: any) => setSelectedContacts(val)}
            options={contacts?.length > 0 ? contacts : []}
            isClearable={true}
            components={animatedComponents}
            isLoading={false}
            isMulti
            allowSelectAll
            formatOptionLabel={(item: any) => (
              <div className='d-flex align-items-center '>
                {item.value !== '*' && (
                  <AvatarWithLetters
                    image={item.imagePath}
                    alt={`${
                      item.contactName && item?.contactName.split(' ')[0]?.charAt(0).toUpperCase()
                    }  `}
                    rounded
                    extrasmall
                  />
                )}

                <div className='d-flex flex-column ms-2  '>
                  <p className='p-0 m-0'>{item.contactName}</p>
                  <p className='p-0 m-0 text-muted'>{item.email}</p>
                </div>
              </div>
            )}
            className='w-100'
            styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
            theme={(theme: any) => ({
              ...theme,

              colors: {
                ...theme.colors,
                primary25: 'var(--bs-dark-light)',
                primary: 'var(--bs-gray)',
                neutral0: 'var(--bs-dark-light)',
              },
            })}
            placeholder={intl.formatMessage({id: 'KIDLING.HOME.CONTACT'})}
          />
        </div> */}
      </ModalBody>
      <ModalFooter>
        <Button color='secondary' onClick={close}>
          {intl.formatMessage({id: 'COMMON.CANCEL'})}
        </Button>
        <Button color='primary' type='submit' onClick={addChat}>
          {!isLoading &&
            intl.formatMessage({
              id: 'AUTH.GENERAL.SUBMIT_BUTTON',
            })}

          {isLoading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default NewChatDialog
