import {FC} from 'preact/compat'
import React, {ReactElement, useState} from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useAuth} from '../../../auth'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {getAllMessages, updateReceivedMessages} from '../../../../../actions/messages'
import ErrorText from '../../../../general-components/error-text/ErrorText'

interface Props {
  markUnread: () => void

  id: string
}
const MessageDetailsHeader: FC<Props> = ({markUnread, id}): ReactElement => {
  const navigate = useNavigate()
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {setGlobalLoader2, setTabMessages, messagesFilters} = useAppContext()
  const [showDelete, setShowDelete] = useState(false)
  const deteleCurrentMessage = async () => {
    const data = {ids: [id], notificationStatusId: 3}
    const resp = await updateReceivedMessages(data)

    if (resp) {
      readMessages()

      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  const readMessages = async () => {
    const filter = '1=1'
    setGlobalLoader2(true)
    const res = await getAllMessages(
      currentUser?.usersId ?? 0,
      filter,
      `ReceivedExpression=${messagesFilters.inbox.searchTerm}`
    )
    setTabMessages(res)
    navigate(-1)

    setGlobalLoader2(false)
  }
  const handleRead = () => {
    markUnread()
    navigate(-1)
  }
  return (
    <div className='card-header align-items-center justify-content-between  py-5 gap-5'>
      <div className='d-flex '>
        <a
          onClick={() => navigate(-1)}
          className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Back'
        >
          <i className='fa-solid fa-arrow-left fs-5 m-0'></i>
        </a>
        <a
          href='#'
          className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title={intl.formatMessage({id: 'KIDLING.MARK_AS_UNREAD'})}
          onClick={() => handleRead()}
        >
          <i className='fa-solid fa-envelope fs-5 m-0'></i>
        </a>
        {/* <a
          href='#'
          className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Spam'
        >
          <i className='fa-solid fa-circle-info fs-5 m-0'></i>
        </a> */}
        <a
          href='#'
          className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title={intl.formatMessage({id: 'COMMON.DELETE'})}
          onClick={() => setShowDelete(true)}
        >
          <i className='fa-solid fa-trash fs-5 m-0'></i>
        </a>

        {/* <a
          href='#'
          className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Mark as read'
        >
          <i className='fa-solid fa-copy fs-5 m-0'></i>
        </a> */}
        {/* <a
          href='#'
          className='btn btn-sm btn-icon btn-light btn-active-light-primary'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Move'
        >
          <i className='fa-solid fa-share-from-square fs-5 m-0'></i>
        </a> */}
      </div>
      {/* <div className='d-flex align-items-center '>
        <span className='fw-semibold text-body me-2'>1 - 50 of 235</span>
        <a
          href='#'
          className='btn btn-sm btn-icon btn-light btn-active-light-primary me-3'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Previous message'
        >
          <i className='fa-solid fa-chevron-left  m-0'></i>
        </a>
        <a
          href='#'
          className='btn btn-sm btn-icon btn-light btn-active-light-primary me-2'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          title='Next message'
        >
          <i className='fa-solid fa-chevron-right   m-0'></i>
        </a>

        <div>
          <div className='dropdown'>
            <button
              className='btn    m-0 p-0 '
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <i className='fa-solid fa-ellipsis fs-4 m-0'></i>
            </button>
            <ul
              className='dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px p-4'
              aria-labelledby='dropdownMenuButton1'
            >
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  <i className='fa-solid fa-file fs-5 me-3'></i>
                  New Group
                </a>
              </div>
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  <i className='fa-solid fa-address-card fs-5 me-3'></i>
                  Contacts
                </a>
              </div>
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  <i className='fa-solid fa-people-group fs-5 me-3'></i>
                  Groups
                  <span className='badge badge-light-primary ms-auto'>new</span>
                </a>
              </div>
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  <i className='fa-solid fa-phone  fs-3 me-3'></i>
                  Calls
                </a>
              </div>
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  <i className='fa-solid fa-gear fs-3 me-3'></i>
                  Settings
                </a>
              </div>
              <div className='separator my-5'></div>
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  <i className='fa-solid fa-magnifying-glass fs-3 me-3'></i>
                  Help
                </a>
              </div>
              <div className='menu-item px-3'>
                <a href='#' className='menu-link px-3'>
                  <i className='fa-solid fa-shield fs-3 me-3'></i>
                  Privacy
                  <span className='badge badge-light-danger ms-auto'>5</span>
                </a>
              </div>
            </ul>
          </div>
        </div>

        <a
          href='#'
          className='btn btn-sm btn-icon btn-color-primary btn-light btn-active-light-primary d-lg-none'
          data-bs-toggle='tooltip'
          data-bs-dismiss='click'
          data-bs-placement='top'
          title='Toggle inbox menu'
          id='kt_inbox_aside_toggle'
        >
          <i className='fa-solid fa-bars fs-5 m-0'></i>
        </a>
      </div> */}
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          deteleCurrentMessage()
          navigate(-1)
        }}
        onCancel={() => setShowDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </div>
  )
}

export default MessageDetailsHeader
