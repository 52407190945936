import {Suspense, useEffect, useState} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, Logout, useAuth} from './modules/auth'
import AppContextProvider, {useAppContext} from '../_metronic/layout/core/AppContextProvider'
import {registerLocale} from 'react-datepicker'
import de from 'date-fns/locale/de' // the locale you want
import TranslationDialog from './general-components/translation-dialog/TranslationDialog'
import GlobalDataLoader from './general-components/global-data-loader'
import {ToastContainer} from 'react-toastify'
import InstallWpaButton from './general-components/inatall-wpa/InstallWpaButton'
import {Helmet} from 'react-helmet'
import 'moment/locale/de'
import {useLang} from '../_metronic/i18n/Metronici18n'
import {ErrorBoundary} from 'react-error-boundary'
import Whitelabel from './general-components/whitelabel/Whitelabel'
import moment from 'moment-timezone'
import {Notifications} from 'react-push-notification'
import PageLoader from './general-components/pageLoader/PageLoader'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-placeholder/lib/reactPlaceholder.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './editor.css'
import CompanyContextProvider from '../_metronic/layout/core/CompanyProvider'
import ChatContextProvider from './context/ChatProvider'
import ChatInitializer from './general-components/Chat-Initializer/ChatInitializer'
registerLocale('de', de)

const App = () => {
  const locale = useLang()
  const [showTranslations, setShowTranslations] = useState(false)

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
  }, [])

  useEffect(() => {
    locale === 'en' ? moment.locale('en') : moment.locale(locale)
  }, [locale])

  const handleKeyDown = (event: any) => {
    if (event.keyCode === 84 && event.shiftKey && event.ctrlKey && event.altKey)
      setShowTranslations(true)
  }

  function Fallback({error, resetErrorBoundary}: any) {
    return (
      <div className='d-flex justify-content-center align-items-center shadow bg-white h-100 w-100'>
        <div role='alert ' className='d-flex align-items-center flex-column'>
          <i className='fa-solid fa-circle-exclamation text-danger  ' style={{fontSize: '5rem'}} />
          <p className='fs-1 bw-bold'>Something went wrong </p>
          <pre style={{color: 'red'}}>{error.message}</pre>
          <button
            className='btn btn-primary'
            onClick={() => {
              window.location.reload()
            }}
          >
            Try again
          </button>
        </div>
      </div>
    )
  }
  const logError = (error: Error, info: {componentStack: string}) => {
    console.log('error', error)
  }

  return (
    <Suspense fallback={<PageLoader />}>
      <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
        <CompanyContextProvider>
          <AppContextProvider>
            <ChatContextProvider>
              <I18nProvider>
                <Helmet
                  htmlAttributes={{
                    lang: locale ?? 'de',
                  }}
                >
                  <meta charSet='utf-8' />
                  <title>Kita-Hr Kidling</title>
                  <meta
                    name='description'
                    content='Die Kita App von Kidling für Erzieher ist eine moderne All-in-One Kita App. Sie umfasst: Kinder-Zeiterfassung in der Kita, Elternkommunikation, Terminplanung, Fotogalerie, Dokumentenverwaltung und Bildungsdokumentation'
                  />
                  <meta http-equiv='content-language' content={locale ?? 'de'} />
                  <meta
                    name='viewport'
                    content='width=device-width,minimum-scale=1,maximum-scale=1,initial-scale=1'
                  />
                  <meta name='keywords' content='Kita' />
                </Helmet>
                <InstallWpaButton />
                <LayoutProvider>
                  <AuthInit>
                    <Outlet />
                    <Notifications />
                    <Whitelabel />
                    <MasterInit />
                    <TranslationDialog
                      showModal={showTranslations}
                      setShowModal={setShowTranslations}
                    />
                    <GlobalDataLoader />
                    <ToastContainer />
                  </AuthInit>
                </LayoutProvider>
                <ChatInitializer />
              </I18nProvider>
            </ChatContextProvider>
          </AppContextProvider>
        </CompanyContextProvider>
      </ErrorBoundary>
    </Suspense>
  )
}

export {App}
