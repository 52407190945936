import React, {FC, ReactElement, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap'
import {Avatar, IconButton} from '@mui/material'
import moment from 'moment'
import {useAuth} from '../../../auth'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../../../../general-components/error-text/ErrorText'
import {deleteUsersFromChat} from '../../../../../actions/chat'
import {useParams} from 'react-router-dom'
import NewChatDialog from './NewChatDialog'
import {UsersListLoading} from '../../../../general-components/loading/UsersListLoading'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'

interface Props {
  showModal: boolean
  setShowModal: any
  enableDelete?: boolean
  title?: string
  users?: any
  hideAdd?: boolean
  readAllChats?: any
}
const GroupMembersDialog: FC<Props> = ({
  showModal,
  setShowModal,
  enableDelete,
  title,
  users,
  hideAdd,
  readAllChats,
}): ReactElement => {
  const {globalLoader} = useAppContext()
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [isAdmin, setIsAdmin] = useState(false)
  const [showDelete, setShowDelete] = useState({show: false, id: -1})
  const [showNewChatDialog, setShowNewChatDialog] = useState(false)
  const [contacts, setContacts] = useState([])
  const [employees, setEmployees] = useState([])
  const params = useParams()

  useEffect(() => {
    if (users?.length > 0) {
      setIsAdmin(users?.find((u: any) => u.usersId === currentUser?.usersId)?.admin)
      let temp1: any = [],
        temp2: any = []
      users?.map((i: any) => {
        if (i?.parentRelation?.contactId) temp1.push(i)
        else temp2.push(i)
      })

      setContacts(temp1)
      setEmployees(temp2)
    }
  }, [users])

  const close = () => {
    setShowModal(false)
  }

  const DoDelete = async (id: number) => {
    const resp = await deleteUsersFromChat(id, Number(params?.id))
    if (readAllChats) readAllChats()
  }

  return (
    <Modal isOpen={showModal} toggle={close} centered>
      <ModalHeader toggle={close}>{title}</ModalHeader>
      <ModalBody>
        <div className='overflow-y-auto'>
          {!hideAdd && (
            <button
              onClick={() => setShowNewChatDialog(!showNewChatDialog)}
              className='btn btn-primary w-100 py-2 mb-2'
              type='button'
            >
              <i className='fa fa-plus fs-3 text-white ' />
            </button>
          )}
          {employees?.map((item: any, index: number) => {
            return (
              <div
                className='d-flex align-items-center justify-content-between chat__header__contact p-2 '
                key={index}
              >
                <div className='d-flex align-items-center '>
                  <Avatar src={item?.imageUrl} alt={item?.usersName.charAt(9).toUpperCase()} />
                  <div>
                    <h4 className='mx-2 my-0'>{item?.usersName}</h4>
                    {item?.chatGroupMessageStatusReadDatetime && (
                      <p className='mx-3 my-0 text-body'>
                        {moment(item?.chatGroupMessageStatusReadDatetime).format(
                          'DD.MM.yyyy HH:mm'
                        )}
                      </p>
                    )}
                  </div>
                </div>
                {item?.admin && (
                  <span className='badge badge-sm badge badge-success fs-7'>
                    {intl.formatMessage({id: 'KIDLING.CHAT.GROUP_ADMIN'})}
                  </span>
                )}
                {isAdmin && item.usersId !== currentUser?.usersId && (
                  <button
                    className='btn'
                    type='button'
                    onClick={() => setShowDelete({show: true, id: item.usersId})}
                  >
                    <i className='fa-solid fa-trash text-danger' />
                  </button>
                )}
              </div>
            )
          })}
          {contacts?.length > 0 && (
            <h5 className='  border-top border-bottom  py-1 my-2 bg-secondary text-center  '>
              {intl.formatMessage({id: 'COMMON.CONTACTS'})}
            </h5>
          )}
          {contacts?.map((item: any, index: number) => {
            return (
              <div
                className='d-flex align-items-center justify-content-between chat__header__contact p-2 '
                key={index}
              >
                <div className='d-flex align-items-center '>
                  <Avatar src={item?.imageUrl} alt={item?.usersName.charAt(9).toUpperCase()} />
                  <div>
                    <h4 className='mx-2 my-0'>{item?.usersName}</h4>
                    {item?.chatGroupMessageStatusReadDatetime && (
                      <p className='mx-3 my-0 text-body'>
                        {moment(item?.chatGroupMessageStatusReadDatetime).format(
                          'DD.MM.yyyy HH:mm'
                        )}
                      </p>
                    )}
                  </div>
                </div>
                {item?.admin && (
                  <span className='badge badge-sm badge badge-success fs-7'>
                    {intl.formatMessage({id: 'KIDLING.CHAT.GROUP_ADMIN'})}
                  </span>
                )}
                {isAdmin && item.usersId !== currentUser?.usersId && (
                  <button
                    className='btn'
                    type='button'
                    onClick={() => setShowDelete({show: true, id: item.usersId})}
                  >
                    <i className='fa-solid fa-trash text-danger' />
                  </button>
                )}
              </div>
            )
          })}
          {globalLoader && <UsersListLoading />}
        </div>
      </ModalBody>
      <NewChatDialog
        setShowModal={setShowNewChatDialog}
        showModal={showNewChatDialog}
        chatId={Number(params?.id)}
      />
      <SweetAlert
        show={showDelete.show}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          DoDelete(showDelete.id)
          setShowDelete({show: false, id: -1})
        }}
        onCancel={() => setShowDelete({show: false, id: -1})}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </Modal>
  )
}

export default GroupMembersDialog
