import React, {FC, ReactElement, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import PrivateChats from './PrivateChats'
import NewChatDialog from './NewChatDialog'
import {useAuth} from '../../../auth'
import {getAllUserChats} from '../../../../../actions/chat'
import DropDownMenu from '../../../../general-components/dropDownMenu/DropDownMenu'
import SearchBox from '../../../../general-components/search-textbox/SearchBox'
import {Avatar, IconButton} from '@mui/material'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {useChatContext} from '../../../../context/ChatProvider'

const ChatSidebar: FC = (): ReactElement => {
  const {currentUserImage} = useAppContext()
  const {allChats, setAllChats} = useChatContext()
  const [currentChats, setCurrentChats] = useState([])
  const [showNewChatDialog, setShowNewChatDialog] = useState(false)
  const {currentUser, logout} = useAuth()
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]
  const intl = useIntl()
  const [selectedTab, setSelectedTab] = useState(0)
  const [showNewGroupDialog, setShowNewGroupDialog] = useState(false)
  const [search, setSearch] = useState('')

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }
  const gotoProfilePage = () => {
    navigate(company ? `/${company}/profile` : '/kidling/profile')
  }

  const userDropDownMenu = {
    title: intl.formatMessage({id: 'KIDLING.CHAT_ACCOUNT_SETTINGS'}),
    menuButton: (
      <Avatar sx={{width: 32, height: 32}} src={currentUserImage}>
        {currentUser?.usersName?.charAt(0).toUpperCase() ?? ''}
      </Avatar>
    ),
    items: [
      {
        title: intl.formatMessage({id: 'KIDLING.HOME.MY_PROFILE'}),
        icon: (
          <Avatar sx={{width: 32, height: 32}} src={currentUserImage}>
            {currentUser?.usersName?.charAt(0).toUpperCase() ?? ''}
          </Avatar>
        ),
        onClick: gotoProfilePage,
      },
      {
        title: intl.formatMessage({id: 'KIDLING.HOME.SIGN_OUT'}),
        icon: <i className='fa-solid fa-arrow-right-from-bracket'></i>,
        onClick: logout,
      },
    ],
  }
  // const optionsDropDownMenu = {
  //   title: intl.formatMessage({id: 'KIDLING.CHAT_SETTINGS'}),
  //   menuButton: <i className='fa-solid fa-ellipsis-vertical fs-3  '></i>,
  //   items: [
  //     {
  //       title: intl.formatMessage({id: 'KIDLING.CHAT_NEW_CHAT'}),

  //       onClick: () => setShowNewChatDialog(!showNewChatDialog),
  //     },
  //     // {
  //     //   title: intl.formatMessage({id: 'KIDLING.CHAT_NEW_GROUP'}),

  //     //   onClick: () => setShowNewGroupDialog(!showNewGroupDialog),
  //     // },
  //   ],
  // }
  useEffect(() => {
    readAllChats()
  }, [])
  useEffect(() => {
    let temp: any = []

    if (search && search !== '') {
      allChats.forEach((element: any) => {
        if (
          element?.chatGroupName.includes(search) ||
          element.users.find((e: any) => e.usersName.toLowerCase().includes(search.toLowerCase()))
        )
          temp.push(element)
      })
      setCurrentChats(temp)
    } else {
      setCurrentChats(allChats)
    }
  }, [search])

  useEffect(() => {
    setCurrentChats(allChats)
  }, [allChats])

  const readAllChats = async () => {
    const resp = await getAllUserChats()
    setAllChats(resp)
  }

  return (
    <div className='d-flex flex-column flex-lg-row-auto w-100 mw-100 mb-10 mb-lg-0 border border-right-1 h-100'>
      <div className='  d-flex flex-column align-items-between overflow-hidden h-100'>
        <div className=' p-4  mh-100 ' id='kt_chat_contacts_header '>
          <div className='d-flex align-items-center justify-content-between mb-8'>
            <DropDownMenu data={userDropDownMenu} />
            <IconButton onClick={() => setShowNewChatDialog(!showNewChatDialog)}>
              <i className='fa fa-plus-circle fs-1 text-primary' />
            </IconButton>
          </div>
          <form className='w-100 position-relative' autoComplete='off'>
            <SearchBox value={search} onChange={setSearch} />
          </form>
        </div>

        <div className='group__tabs__container  overflow-y-auto'>
          <PrivateChats chats={currentChats} readAllChats={readAllChats} />
          {/* <Tabs
              value={selectedTab}
              onChange={handleChange}
              aria-label='basic tabs example'
              centered
            >
              <Tab
                label={intl.formatMessage({id: 'KIDLING.CHAT.CHATS'})}
                {...a11yProps(0)}
                style={{width: '50%'}}
              />
              <Tab
                label={intl.formatMessage({id: 'KIDLING.CHAT.GROUPS'})}
                {...a11yProps(1)}
                style={{width: '50%'}}
              />
            </Tabs>
            <CustomTabPanel value={selectedTab} index={0}>
              <PrivateChats chats={allChats} />
            </CustomTabPanel>
            <CustomTabPanel value={selectedTab} index={1}>
              <GroupsChats />
            </CustomTabPanel> */}
        </div>
      </div>

      <NewChatDialog setShowModal={setShowNewChatDialog} showModal={showNewChatDialog} />
    </div>
  )
}

export default ChatSidebar
export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}
export function CustomTabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <p>{children}</p>}
    </div>
  )
}
