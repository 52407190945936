import React, {FC, ReactElement, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import Select from 'react-select'
import {toast} from 'react-toastify'
import {updateEmployeeInfo} from '../../../../../actions/employee'
import {getUserInfo, updateUserInfo} from '../../../../../actions/users'
import {useAuth} from '../../../auth'
import {selectStyle, selectStyleDarkMode} from '../../../../utils/select-style'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {UsersListLoading} from '../../../../general-components/loading/UsersListLoading'
import {readFileFrom} from '../../../../../actions/image'
import {getBase64} from '../../../../utils/getBase64'
import ResponsiveDatePicker from '../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const EmployeeInfo: FC = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const {currentUser, setCurrentUser} = useAuth()
  const [preview, setPreview] = useState<any>(null)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [base64Image, setBase64Image] = useState<any>(null)
  const {setCurrentUserImage} = useAppContext()
  const {mode} = useThemeMode()
  const {
    employeeInfo,
    setEmployeeInfo,
    countries,
    cities,
    genders,
    departments,
    nationalities,
    positions,
    states,
    employeeTypes,
    globalLoader,
  } = useAppContext()

  const intl = useIntl()

  const [initialValues, setInitialValues] = useState<any>({...employeeInfo})

  const Schema = Yup.object().shape({
    bankAccountHolderName: Yup.string(),
    bankAccountNumber: Yup.string(),
    bankIBAN: Yup.string(),
    bankName: Yup.string(),
    bankSWIFT: Yup.string(),
    cityId: Yup.string(),
    companyId: Yup.mixed(),
    dateOfBirth: Yup.date(),
    departmentId: Yup.mixed(),
    email: Yup.string()
      .email(intl.formatMessage({id: 'KIDLING.INVALID_EMAIL'}))
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    employeeName: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    employeeTypeId: Yup.mixed(),
    genderId: Yup.mixed(),
    imagePath: Yup.string(),
    mobileNumber: Yup.string(),
    nationalityId: Yup.mixed(),
    phoneNumber: Yup.string(),
    positionId: Yup.mixed(),
    remarks: Yup.string(),
    stateId: Yup.string(),
    addressDetails: Yup.string(),
    addressStreetName: Yup.string(),
    addressHouseNumber: Yup.string(),
    addressPostalCode: Yup.string(),
    countryId: Yup.mixed(),
  })
  const formik: any = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          ...employeeInfo,
          ...values,
        }

        const resp = await updateEmployeeInfo(data)

        if (!!resp.employeeId) {
          setEmployeeInfo(resp)
          // const {data: user} = await getUserInfo(currentUser?.usersId || 0)
          // setCurrentUser(user)
          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
        setSubmitting(false)
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    if (employeeInfo) setInitialValues({...employeeInfo})
  }, [employeeInfo])

  useEffect(() => {
    if (currentUser?.imagePath) {
      getImage()
    }
  }, [])

  useEffect(() => {
    if (!!base64Image) updateImage(base64Image)
  }, [base64Image])

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    getBase64(selectedFile, (result: string) => {
      setBase64Image(result)
    })

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl)
    }
  }, [selectedFile])

  const updateImage = async (image: string) => {
    setIsLoading(true)
    const resp = await updateUserInfo({...currentUser, imagePath: image})
    setIsLoading(false)
    if (resp) {
      setCurrentUser(resp)
      setCurrentUserImage(image ? base64Image : null)
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    }
  }

  const getImage = async () => {
    const resp = await readFileFrom(currentUser?.imagePath ?? '')
    if (resp) setPreview(resp)
  }
  return (
    <div className='pt-8'>
      {!globalLoader && (
        <form
          id='kt_modal_add_user_form'
          className='form container p-2 p-sm-8 d-flex flex-column align-items-center'
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div className='row align-items-center w-100'>
            <div className='col-md-6 col-12 my-3'>
              <label className='required form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.EMPLOYEE_NAME'})}
              </label>
              <input
                {...formik.getFieldProps('employeeName')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.employeeName && formik.errors.employeeName},
                  {
                    'is-valid': formik.touched.employeeName && !formik.errors.employeeName,
                  }
                )}
                name='employeeName'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                rows={1}
              />
              {formik.touched.employeeName && formik.errors.employeeName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.employeeName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 col-12 my-3'>
              <label className='required form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.MOBILE'})}
              </label>
              <input
                {...formik.getFieldProps('mobileNumber')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.mobileNumber && formik.errors.mobileNumber},
                  {
                    'is-valid': formik.touched.mobileNumber && !formik.errors.mobileNumber,
                  }
                )}
                name='mobileNumber'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                rows={1}
              />
              {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.mobileNumber}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 col-12 my-3'>
              <label className='required form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.PHONE'})}
              </label>
              <input
                {...formik.getFieldProps('phoneNumber')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.phoneNumber && formik.errors.phoneNumber},
                  {
                    'is-valid': formik.touched.phoneNumber && !formik.errors.phoneNumber,
                  }
                )}
                name='phoneNumber'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                rows={1}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.phoneNumber}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 col-12 my-3'>
              <label className='required form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.EMAIL'})}
              </label>
              <input
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.email && formik.errors.email},
                  {
                    'is-valid': formik.touched.email && !formik.errors.email,
                  }
                )}
                name='email'
                autoComplete='off'
                disabled
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 col-12 my-3'>
              <label className='required form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.BIRTHDAY'})}
              </label>
              <ResponsiveDatePicker
                onAccept={(val: any) => formik.setFieldValue('dateOfBirth', val)}
                value={formik.values.dateOfBirth}
                disabled
              />

              {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.dateOfBirth}</span>
                  </div>
                </div>
              )}
            </div>
            <div className=' col-md-6 col-12 my-3  '>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.DEPARTMENT'})}
              </label>
              <Select
                name='departmentId'
                options={departments}
                getOptionLabel={(option) => option.departmentName}
                getOptionValue={(option) => option.departmentId}
                className='basic-multi-select'
                classNamePrefix='select'
                isClearable
                isDisabled
                onChange={(val: any) => formik.setFieldValue('departmentId', val.departmentId)}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                value={departments?.find(
                  (item: any) => item.departmentId === formik.values.departmentId
                )}
              />
              {formik.touched.department && formik.errors.department && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.department}</span>
                  </div>
                </div>
              )}
            </div>
            <div className=' col-md-6 col-12 my-3  '>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.POSITION'})}
              </label>
              <Select
                name='position'
                options={positions}
                getOptionLabel={(option) => option.positionName}
                getOptionValue={(option) => option.positionId}
                className='basic-multi-select'
                classNamePrefix='select'
                isClearable
                isDisabled
                onChange={(val: any) => {
                  formik.setFieldValue('positionId', val.positionId)
                }}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                value={positions.find((item: any) => item.positionId === formik.values.positionId)}
              />
              {formik.touched.countryId && formik.errors.countryId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.countryId}</span>
                  </div>
                </div>
              )}
            </div>
            <div className=' col-md-6 col-12 my-3  '>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.NATIONALITY'})}
              </label>
              <Select
                name='nationality'
                options={nationalities}
                getOptionLabel={(option) => option.nationalityName}
                getOptionValue={(option) => option.nationalityId}
                className='basic-multi-select'
                classNamePrefix='select'
                isClearable
                onChange={(val: any) => formik.setFieldValue('nationalityId', val.nationalityId)}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                value={nationalities?.find(
                  (item: any) => item.nationalityId === formik.values.nationalityId
                )}
              />
              {formik.touched.nationalityId && formik.errors.nationalityId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.nationalityId}</span>
                  </div>
                </div>
              )}
            </div>
            <div className=' col-md-6 col-12 my-3  '>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.EMPLOYEE_TYPE'})}
              </label>
              <Select
                name='employeeType'
                options={employeeTypes}
                getOptionLabel={(option) => option.employeeTypeName}
                getOptionValue={(option) => option.employeeTypeId}
                className='basic-multi-select'
                classNamePrefix='select'
                isClearable
                isDisabled
                onChange={(val: any) => formik.setFieldValue('employeeTypeId', val.employeeTypeId)}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                value={employeeTypes?.find(
                  (item: any) => item.employeeTypeId === formik.values.employeeTypeId
                )}
              />
              {formik.touched.employeeTypeId && formik.errors.employeeTypeId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.employeeTypeId}</span>
                  </div>
                </div>
              )}
            </div>
            <div className=' col-md-6 col-12 my-3  '>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'PARENT.GENDER'})}
              </label>
              <Select
                name='genderId'
                options={genders}
                getOptionLabel={(option) => option.genderName}
                getOptionValue={(option) => option.genderId}
                className='basic-multi-select'
                classNamePrefix='select'
                isClearable
                onChange={(val: any) => formik.setFieldValue('genderId', val.genderId)}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                value={genders?.find((item: any) => item.genderId === formik.values.genderId)}
              />
              {formik.touched.genderId && formik.errors.genderId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.genderId}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-12 my-3'>
              <label className='required form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.PASS_CODE'})}
              </label>
              <input
                {...formik.getFieldProps('quickAccessCode')}
                className={clsx('form-control  mb-3 mb-lg-0')}
                name='quickAccessCode'
                autoComplete='off'
                disabled
                rows={1}
              />
              {formik.touched.quickAccessCode && formik.errors.quickAccessCode && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.quickAccessCode}</span>
                  </div>
                </div>
              )}
            </div>
            <div className=' col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'PARENT.Remarks'})}
              </label>
              <textarea
                {...formik.getFieldProps('remarks')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {
                    'is-invalid': formik.touched.remarks && formik.errors.remarks,
                  },
                  {
                    'is-valid': formik.touched.remarks && !formik.errors.remarks,
                  }
                )}
                name='remarks'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                value={formik.values.remarks}
                rows={3}
              />
              {formik.touched.remarks && formik.errors.remarks && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.bankSWIFT}</span>
                  </div>
                </div>
              )}
            </div>
            <hr className='my-16' />
            <div className=' col-md-6 col-12 my-3  '>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.COUNTRY'})}
              </label>
              <Select
                name='countryId'
                options={countries}
                getOptionLabel={(option) => option.countryName}
                getOptionValue={(option) => option.countryId}
                className='basic-multi-select'
                classNamePrefix='select'
                isClearable
                onChange={(val: any) => formik.setFieldValue('countryId', val.countryId)}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme) => ({
                  ...theme,

                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                value={countries?.find((item: any) => item.countryId === formik.values.countryId)}
              />
              {formik.touched.countryId && formik.errors.countryId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.countryId}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-md-6 col-12 '>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'PARENT.State'})}
              </label>
              <Select
                name='stateId'
                options={states}
                getOptionLabel={(option) => option.stateName}
                getOptionValue={(option) => option.stateId}
                className='basic-multi-select'
                classNamePrefix='select'
                isClearable
                onChange={(val: any) => formik.setFieldValue('stateId', val.stateId)}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                value={states?.find((item: any) => item.stateId === formik.values.stateId)}
              />

              {formik.touched.stateId && formik.errors.stateId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.stateId}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.STREET_NAME'})}
              </label>
              <input
                {...formik.getFieldProps('addressStreetName')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.addressStreetName && formik.errors.addressStreetName,
                  },
                  {
                    'is-valid':
                      formik.touched.addressStreetName && !formik.errors.addressStreetName,
                  }
                )}
                name='addressStreetName'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                value={formik.values.addressStreetName}
              />
              {formik.touched.addressStreetName && formik.errors.addressStreetName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.addressStreetName}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-md-6 col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.HOUSE_NUMBER'})}
              </label>
              <input
                {...formik.getFieldProps('addressHouseNumber')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.addressHouseNumber && formik.errors.addressHouseNumber,
                  },
                  {
                    'is-valid':
                      formik.touched.addressHouseNumber && !formik.errors.addressHouseNumber,
                  }
                )}
                name='addressHouseNumber'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                value={formik.values.addressHouseNumber}
              />
              {formik.touched.addressHouseNumber && formik.errors.addressHouseNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.addressHouseNumber}</span>
                  </div>
                </div>
              )}
            </div>
            <div className=' col-md-6 col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.POSTAL_CODE'})}
              </label>
              <input
                {...formik.getFieldProps('addressPostalCode')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.addressPostalCode && formik.errors.addressPostalCode,
                  },
                  {
                    'is-valid':
                      formik.touched.addressPostalCode && !formik.errors.addressPostalCode,
                  }
                )}
                name='addressPostalCode'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                value={formik.values.addressPostalCode}
              />
              {formik.touched.addressPostalCode && formik.errors.addressPostalCode && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.addressPostalCode}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 col-12 '>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.CITY'})}
              </label>
              <Select
                name='cityId'
                options={cities}
                getOptionLabel={(option) => option.cityName}
                getOptionValue={(option) => option.cityId}
                className='basic-multi-select'
                classNamePrefix='select'
                isClearable
                onChange={(val: any) => formik.setFieldValue('cityId', val.cityId)}
                styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: 'var(--bs-dark-light)',
                    primary: 'var(--bs-gray)',
                    neutral0: 'var(--bs-dark-light)',
                  },
                })}
                value={cities?.find((item: any) => item.cityId === formik.values.cityId)}
              />

              {formik.touched.cityId && formik.errors.cityId && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.cityId}</span>
                  </div>
                </div>
              )}
            </div>
            <div className=' col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.HOME.NOTES'})}
              </label>
              <input
                {...formik.getFieldProps('addressDetails')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {'is-invalid': formik.touched.addressDetails && formik.errors.addressDetails},
                  {
                    'is-valid': formik.touched.addressDetails && !formik.errors.addressDetails,
                  }
                )}
                name='addressDetails'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                value={formik.values.addressDetails}
              />
              {formik.touched.addressDetails && formik.errors.addressDetails && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.addressDetails}</span>
                  </div>
                </div>
              )}
            </div>
            <hr className='my-16' />
            <div className='col-md-6 col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.BANK_HOLDER_NAME'})}
              </label>
              <input
                {...formik.getFieldProps('bankAccountHolderName')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.bankAccountHolderName && formik.errors.bankAccountHolderName,
                  },
                  {
                    'is-valid':
                      formik.touched.bankAccountHolderName && !formik.errors.bankAccountHolderName,
                  }
                )}
                name='bankAccountHolderName'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                value={formik.values.bankAccountHolderName}
              />
              {formik.touched.bankAccountHolderName && formik.errors.bankAccountHolderName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.bankAccountHolderName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.BANK_ACCOUNT_NUMBER'})}
              </label>
              <input
                {...formik.getFieldProps('bankAccountNumber')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {
                    'is-invalid':
                      formik.touched.bankAccountNumber && formik.errors.bankAccountNumber,
                  },
                  {
                    'is-valid':
                      formik.touched.bankAccountNumber && !formik.errors.bankAccountNumber,
                  }
                )}
                name='bankAccountNumber'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                value={formik.values.bankAccountNumber}
              />
              {formik.touched.bankAccountNumber && formik.errors.bankAccountNumber && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.bankAccountNumber}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.BANK_IBAN'})}
              </label>
              <input
                {...formik.getFieldProps('bankIBAN')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {
                    'is-invalid': formik.touched.bankIBAN && formik.errors.bankIBAN,
                  },
                  {
                    'is-valid': formik.touched.bankIBAN && !formik.errors.bankIBAN,
                  }
                )}
                name='bankIBAN'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                value={formik.values.bankIBAN}
              />
              {formik.touched.bankIBAN && formik.errors.bankIBAN && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.bankIBAN}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-md-6 col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.BANK_NAME'})}
              </label>
              <input
                {...formik.getFieldProps('bankName')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {
                    'is-invalid': formik.touched.bankName && formik.errors.bankName,
                  },
                  {
                    'is-valid': formik.touched.bankName && !formik.errors.bankName,
                  }
                )}
                name='bankName'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                value={formik.values.bankName}
              />
              {formik.touched.bankName && formik.errors.bankName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.bankName}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='  col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'KIDLING.BANK_SWIFT'})}
              </label>
              <input
                {...formik.getFieldProps('bankSWIFT')}
                className={clsx(
                  'form-control  mb-3 mb-lg-0',
                  {
                    'is-invalid': formik.touched.bankSWIFT && formik.errors.bankSWIFT,
                  },
                  {
                    'is-valid': formik.touched.bankSWIFT && !formik.errors.bankSWIFT,
                  }
                )}
                name='bankSWIFT'
                autoComplete='off'
                disabled={formik.isSubmitting || isLoading}
                value={formik.values.bankSWIFT}
              />
              {formik.touched.bankSWIFT && formik.errors.bankSWIFT && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className='alert-danger'>{formik.errors.bankSWIFT}</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Button
            color='primary'
            type='submit'
            disabled={formik.isSubmitting || isLoading}
            className='ms-auto mt-8'
          >
            {!formik.isSubmitting &&
              intl.formatMessage({
                id: 'AUTH.GENERAL.SUBMIT_BUTTON',
              })}
            {formik.isSubmitting && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </Button>
        </form>
      )}
      {globalLoader && <UsersListLoading />}
    </div>
  )
}

export default EmployeeInfo
