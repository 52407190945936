/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import LanguageSelect from '../../../_metronic/partials/layout/language-select'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import './styles.scss'
import PageLoader from '../../general-components/pageLoader/PageLoader'
export const AuthLayout = () => {
  const intl = useIntl()
  const {companyMetaData, isFinishedLoadingMetaData} = useAppContext()
  const company = window.location.pathname.split('/')[1]
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => {}
  }, [])
  if (!isFinishedLoadingMetaData) return <PageLoader />
  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      // }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='/' className='mb-12'>
          <img
            alt='Logo'
            src={companyMetaData?.imagePathLogoDark ?? '/kidling-Logo.webp'}
            className={`theme-dark-show ${
              company.toLocaleLowerCase() === 'taeks' ? 'h-100px' : ' h-45px'
            }`}
          />
          <img
            alt='Logo'
            src={companyMetaData?.imagePathLogoLight ?? '/kidling-Logo.webp'}
            className={`theme-light-show ${
              company.toLocaleLowerCase() === 'taeks' ? 'h-100px' : ' h-45px'
            }`}
          ></img>
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='w-lg-500px bg-body   shadow-sm p-10 p-lg-15 mx-auto w-100'>
          <Outlet />
        </div>

        <img src='/media/logos/sectigo_trust_seal.png' alt='seal' className='mt-8' />
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-semibold fs-6'>
          <a
            href='http://kidling.de/kontakt/'
            target='_blank'
            className='text-body text-hover-primary px-2'
            rel='noreferrer'
          >
            {intl.formatMessage({
              id: 'KIDLING.HOME.CONTACT',
            })}
          </a>

          <a
            href='http://kidling.de/kontakt/'
            target='_blank'
            className='text-body text-hover-primary px-2'
            rel='noreferrer'
          >
            {'  |  '}{' '}
            {intl.formatMessage({
              id: 'KIDLING.HOME.SUPPORT',
            })}
          </a>

          <a
            href='https://kidling.de/datenschutz-app/'
            target='_blank'
            className='text-body text-hover-primary px-2'
            rel='noreferrer'
          >
            {'  |  '}{' '}
            {intl.formatMessage({
              id: 'KIDLING.HOME.DATA_PRIVACY',
            })}
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}

const AuthPage = () => (
  <div className='d-flex flex-column h-100'>
    <div className='d-flex align-items-stretch flex-shrink-0 w-150px  align-self-end'>
      <LanguageSelect />
    </div>
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />

        <Route path='registration' element={<Registration />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  </div>
)

export {AuthPage}
