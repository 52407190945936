import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {getEmployeePinboard} from '../../actions/pinboard'
import {useAuth} from '../modules/auth'

const usePinboard = () => {
  const [isLoading, setIsLoading] = useState(false)
  const {setEmployeePinboard} = useAppContext()
  const {currentUser} = useAuth()

  useEffect(() => {
    if (currentUser) readPinboards()
  }, [currentUser])
  const readPinboards = async () => {
    setIsLoading(true)
    const resp = await getEmployeePinboard()
    setIsLoading(false)
    setEmployeePinboard(resp)
  }
  return {isLoading, readPinboards}
}
export default usePinboard
