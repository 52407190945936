import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {
  getEmployeeAbsenceStatisticsAdjustment,
  getEmployeeAttendanceByDates,
} from '../../../../../../actions/employee'
import CustomDateRangePicker from '../../../../../general-components/date-range-picker/DateRangePicker'
import moment from 'moment'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import {useIntl} from 'react-intl'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import allLocales from '@fullcalendar/core/locales-all'
import {useLang} from '../../../../../../_metronic/i18n/Metronici18n'
import './styles.scss'
import {getAttendanceTimesheet, submitNewTimesheet} from '../../../../../../actions/attendace'
import {useAuth} from '../../../../auth'
import {toast} from 'react-toastify'
import {statusColors} from '../../../../../utils/constants'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../../../../../general-components/error-text/ErrorText'

function ClockSheet() {
  const {currentUser} = useAuth()
  const locale = useLang()
  const intl = useIntl()
  const [attendance, setAttendance] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState<any>({})
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const {setCurrentTimesheet, currentTimesheet} = useAppContext()

  useEffect(() => {
    if (date) {
      readEmployeeAttendance()
      readTimeSheet()
    }
  }, [date])

  const readEmployeeAttendance = async () => {
    setIsLoading(true)
    const resp = await getEmployeeAttendanceByDates(
      `${moment(date?.startDate).startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
      `${moment(date?.endDate).endOf('day').format('yyyy-MM-DDTHH:mm:ss')}`
    )

    if (resp) {
      setAttendance(resp)
    }
    setIsLoading(false)
  }
  const handleDateChange = (selected: any) => {
    setDate(selected[0])
  }

  const triggerDateChanged = async (dateObj: any) => {
    setDate({startDate: dateObj.start, endDate: dateObj.end})
  }

  const handleSubmitTimeSheet = async () => {
    setIsSubmitting(true)
    const resp = await submitNewTimesheet(
      moment(date?.startDate).format('yyyy-MM-DD'),
      moment(date?.startDate).endOf('month').format('yyyy-MM-DD'),
      currentUser?.employee?.employeeId || 0
    )
    setIsSubmitting(false)
    if (resp) {
      readTimeSheet()
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    }
  }

  const readTimeSheet = async () => {
    const resp = await getAttendanceTimesheet(
      moment(date?.startDate).format('yyyy-MM-DD'),
      moment(date?.endDate).format('yyyy-MM-DD'),
      currentUser?.employee?.employeeId || 0
    )

    setCurrentTimesheet(resp)
  }

  return (
    <div className='card clockin__calendar'>
      <div className='card-header align-items-center justify-content-between  py-5 gap-5'>
        <div className='d-flex align-items-start align-items-sm-center justify-content-between w-100 flex-column flex-sm-row '>
          <Link to={company ? `/${company}/employee/clockin` : '/kidling/employee/clockin'}>
            <i className='fa-solid fa-arrow-left fs-3 me-4'></i>
          </Link>

          <FullCalendar
            plugins={[listPlugin]}
            headerToolbar={{
              left: 'title',
              center: '',
              right: 'prev,next',
            }}
            contentHeight='0'
            initialView='listMonth'
            locale={locale}
            locales={allLocales}
            datesSet={triggerDateChanged}
            noEventsText=''
          />
          <div className='d-flex align-items-center  mt-2 mt-sm-0 w-100 w-sm-auto'>
            {currentTimesheet?.Status !== 2 &&
              currentTimesheet?.Status !== 3 &&
              attendance?.groupedEmployeeAttendances?.length > 0 && (
                <button
                  className='btn btn-primary'
                  type='button'
                  onClick={() => setShowWarning(true)}
                  // disabled={currentTimesheet?.Status !== 0}
                >
                  {isSubmitting ? (
                    <span className='indicator-progress ' style={{display: 'inline-block'}}>
                      {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  ) : (
                    intl.formatMessage({id: 'KIDLING.HR.SUBMIT_TIMESHEET'})
                  )}
                </button>
              )}
            {/* <h5 className='mb-0 ms-auto ms-sm-2'>
              <CustomDateRangePicker handleDateChange={handleDateChange} />
            </h5> */}
            {/* <button className='btn ms-auto'>
              <i className='fa-solid fa-chevron-down '></i>
            </button> */}
          </div>
        </div>
      </div>
      <div className='card-body'>
        <div className='d-flex align-items-center'>
          <span className={`badge badge-${statusColors[currentTimesheet?.Status]?.color}`}>
            {intl.formatMessage({id: statusColors[currentTimesheet?.Status]?.label ?? '--'})}
          </span>
        </div>
        <div className='d-flex flex-column align-items-center'>
          <div className='d-flex align-items-center mt-8 w-md-600px w-100 shadow p-4 rounded'>
            <div className='row w-100 '>
              <div className='col-12 col-sm-4'>
                <div className='d-flex flex-column align-items-center'>
                  <h1 className='  text-body' style={{fontSize: '1.8rem'}}>
                    {/* {employeeAttendaceStatistics?.today >= 0
                      ? `${Math.floor((employeeAttendaceStatistics?.today * 60) / 60)}h 
                ${Math.floor((employeeAttendaceStatistics?.today * 60) % 60)}m`
                      : '--'} */}
                    {currentTimesheet?.data?.WorkedTitle}
                  </h1>
                  <p className='fs-7 text-uppercase text-body'>
                    {intl.formatMessage({id: 'KIDLING.HR.WORKING_HOURS'})}
                  </p>
                </div>
              </div>
              <div className='col-12 col-sm-4'>
                <div className='d-flex flex-column align-items-center '>
                  <h1 className=' text-body' style={{fontSize: '1.8rem'}}>
                    {/* {employeeAttendaceStatistics?.weekBalance >= 0
                      ? `${employeeAttendaceStatistics?.weekBalance.toFixed(2)} h`
                      : '--'} */}
                    {currentTimesheet?.data?.BalanceTitle}
                  </h1>
                  <p className='fs-7 text-uppercase text-body'>
                    {intl.formatMessage({id: 'KIDLING.HR.HOURS_BALANCE'})}
                  </p>
                </div>
              </div>
              <div className='col-12 col-sm-4'>
                <div className='d-flex flex-column align-items-center'>
                  <h1 className='  text-body' style={{fontSize: '1.8rem'}}>
                    {/* {employeeAttendaceStatistics?.today >= 0
                      ? `${Math.floor((employeeAttendaceStatistics?.today * 60) / 60)}h 
                ${Math.floor((employeeAttendaceStatistics?.today * 60) % 60)}m`
                      : '--'} */}
                    {currentTimesheet?.data?.EstimatedTitle}
                  </h1>
                  <p className='fs-7 text-uppercase text-body'>
                    {intl.formatMessage({id: 'KIDLING.HR.ESTIMATED'})}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=' mt-8 w-md-600px w-100 shadow p-4 rounded'>
            <Link
              className='btn d-flex align-items-center w-100 p-0'
              to={{
                pathname: company
                  ? `/${company}/employee/clockin/sheet/day`
                  : `/kidling/employee/clockin/sheet/day`,
                search: `date=${moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
              }}
            >
              <div className='alert alert-danger px-4 border-0 rounded mb-0' role='alert'>
                <i className='fa-regular fa-clock  text-danger'></i>
              </div>

              <div className='ms-4  '>
                <p className='my-0 text-start'> {intl.formatMessage({id: 'COMMON.TODAY'})}</p>
                <h2>
                  {Math.floor((attendance?.currentActualHours * 60) / 60)}h{' '}
                  {Math.floor((attendance?.currentActualHours * 60) % 60)}m
                  <span>
                    <i className='fa-solid fa-circle text-success ms-1'></i>
                  </span>
                </h2>
              </div>
              <Link
                className='btn ms-auto'
                to={{
                  pathname: company
                    ? `/${company}/employee/clockin/sheet/day`
                    : `/kidling/employee/clockin/sheet/day`,
                  search: `date=${moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
                }}
              >
                <i className='fa-solid fa-arrow-right'></i>
              </Link>
            </Link>
          </div>

          <div className='d-flex flex-column align-items-center w-100 mt-8'>
            {isLoading ? (
              <UsersListLoading />
            ) : (
              <div className='list-group w-100'>
                {attendance?.groupedEmployeeAttendances?.map((item: any, index: number) => (
                  <Link
                    to={{
                      pathname: company
                        ? `/${company}/employee/clockin/sheet/day`
                        : `/kidling/employee/clockin/sheet/day`,
                      search: `date=${item.dayOfWeek}`,
                    }}
                    className='list-group-item list-group-item-action bg-body'
                    key={index}
                  >
                    <div className='d-flex align-items-center'>
                      <div
                        className={`alert ${
                          index % 2 === 0 ? 'alert-success ' : 'alert-dark'
                        }  px-4 py-3 border-0 rounded mb-0`}
                        role='alert'
                      >
                        <i
                          className={`fs-4 ${
                            index % 2 === 0 ? 'fa-regular fa-circle' : 'fa-solid fa-check'
                          } ${index % 2 === 0 ? 'alert-success text-success' : 'alert-dark'}`}
                        ></i>
                      </div>

                      <p className='m-0 ms-4'>{moment(item.dayOfWeek).format('ddd DD')} </p>
                      <div className='d-flex align-items-center ms-auto'>
                        <span className='badge badge-primary'>{`${Math.floor(
                          (item?.actualHours * 60) / 60
                        )}h 
                ${Math.floor((item?.actualHours * 60) % 60)}m`}</span>
                        <Link
                          className='btn '
                          to={{
                            pathname: company
                              ? `/${company}/employee/clockin/sheet/day`
                              : `/kidling/employee/clockin/sheet/day`,
                            search: `date=${item.dayOfWeek}`,
                          }}
                        >
                          <i className='fa-solid fa-arrow-right'></i>
                        </Link>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <SweetAlert
        show={showWarning}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowWarning(false)
          handleSubmitTimeSheet()
        }}
        onCancel={() => setShowWarning(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </div>
  )
}

export default ClockSheet
