import {FC, ReactElement, useEffect, useState} from 'react'

type Props = {
  initialSeconds: number
  initialMinute: number
  initialHours: number
  reset: boolean
}
const Timer: FC<Props> = ({initialSeconds, initialMinute, initialHours, reset}): ReactElement => {
  const [minutes, setMinutes] = useState(initialMinute)
  const [seconds, setSeconds] = useState(initialSeconds)
  const [hours, setHours] = useState(initialHours)
  let myInterval: any

  useEffect(() => {
    myInterval = setInterval(() => {
      if (minutes >= 59 && seconds >= 59) {
        setMinutes(0)
        setHours((prev) => prev + 1)
        setSeconds(seconds % 59)
      } else if (seconds >= 59) {
        setSeconds(seconds % 59)
        setMinutes((prev) => prev + 1)
      } else {
        setSeconds((prev) => prev + 1)
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  })

  useEffect(() => {
    if (reset) {
      setHours(0)
      setSeconds(0)
      setMinutes(0)
    }
  }, [reset])

  return (
    <h1 className='my-0'>
      {hours}h {minutes}m {seconds}s
    </h1>
  )
}

export default Timer
