import axios from 'axios'
import * as endpoints from '../config/endpoints'

export const getAllEmployeeShifts = async (
  shiftType?: Number | null,
  From?: string,
  To?: string
) => {
  return axios
    .get(`${endpoints.GET_EMPLOYEE_SHIFT}`, {
      params: {
        shiftType: shiftType ?? null,
        From: From && To ? From : null,
        To: From && To ? To : null,
      },
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getShiftTypes = async () => {
  return axios
    .get(`${endpoints.EMPLOYEE_SHIFT_TYPE}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getAllEmployeeShiftsFiles = async (FromDate?: string, ToDate?: string) => {
  return axios
    .get(`${endpoints.GET_EMPLOYEE_SHIFTS_FILES}`, {
      params: {
        FromDate: FromDate && ToDate ? FromDate : null,
        ToDate: FromDate && ToDate ? ToDate : null,
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
