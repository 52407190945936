/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getEmployeeInfo, getUserInfo, login, loginAdmin} from '../core/_requests'

import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'
import {getTranslationsById} from '../../../../actions/language'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {setLanguage, useLang} from '../../../../_metronic/i18n/Metronici18n'
import {setupAxios} from '../core/AuthHelpers'
import axios from 'axios'
import {groupBy} from '../../../utils/common'
import {useCompanyContext} from '../../../../_metronic/layout/core/CompanyProvider'

export function Login() {
  const company = window.location.pathname.split('/')[1]
  const [loading, setLoading] = useState(false)
  const {setTranslations, languages, setMobileConfiguration, companyMetaData}: any = useAppContext()
  const {currentCompany, setCurrentCompany} = useCompanyContext()
  const navigate = useNavigate()
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()
  const locale = useLang()
  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .min(3, intl.formatMessage({id: 'KIDLING.MIN_3_SYMBOLES'}))
      .max(50, intl.formatMessage({id: 'KIDLING.MAX_50_SYMBOLES'}))
      .required(intl.formatMessage({id: 'KIDLING.USERNAME_REQUIRED'})),
    password: Yup.string()
      .min(3, intl.formatMessage({id: 'KIDLING.MIN_3_SYMBOLES'}))
      .max(50, intl.formatMessage({id: 'KIDLING.MAX_50_SYMBOLES'}))
      .required(intl.formatMessage({id: 'KIDLING.PASSWORD_REQUIRED'})),
  })

  const initialValues = {
    username: '',
    password: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const resp = await loginAdmin(values.username, values.password)
        //localStorage.setItem('MasterToken', resp[0].token)
        const url = resp[0].apiUrl
        setupAxios(axios, url)
        localStorage.setItem('apiUrl', url)

        const auth = await login(values.username, values.password, url)
        saveAuth({...auth})
        // const resp1 = groupBy(auth.configurtations, 'mobileConfigurationName')
        // if (resp1) setMobileConfiguration(resp)
        const {data: user} = await getUserInfo(auth.userId, auth.token)
        const employee = await getEmployeeInfo(auth.userId, auth.token)

        setCurrentUser({...user, employee})
        const userLangId = languages?.findIndex(
          (lang: any, index: number) => index === user.languageId - 1
        )

        if (languages?.length > 0 && locale !== languages[userLangId].value)
          setLanguage(languages[userLangId].value)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage({id: 'KIDLING.LOGIN_DETAIL_INCORRECT'}))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const readTranslations = async (id: number) => {
    const trans = await getTranslationsById(id)
    const temp: any = {}
    trans.map((item: any) => (temp[item.labelKey] = item.translationName))

    setTranslations(temp)
  }
  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='   mb-3'>
          {companyMetaData?.educatorAppTitle
            ? `${intl.formatMessage({
                id: 'KIDLING.SIGNIN',
              })} ${intl.formatMessage({id: 'KIDLING.MESSAGE.TO'})} ${
                companyMetaData?.educatorAppTitle
              }`
            : intl.formatMessage({id: 'KIDLING.SIGNIN_TO_KIDLING'})}
        </h1>

        {/* <div className='text-gray-400 fw-semibold fs-4'>
          {intl.formatMessage({id: 'KIDLING.NEWHERE'})} ?{' '}
          <Link to='/auth/registration' className='link-primary fw-bold'>
            {intl.formatMessage({id: 'KIDLING.CREATE_ACCOUNT'})}
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      {/* : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        {/* <label className='form-label fs-6 fw-bold text-dark'>
          {intl.formatMessage({id: 'KIDLING.USERNAME'})}
        </label> */}
        <input
          placeholder={intl.formatMessage({id: 'KIDLING.USERNAME'})}
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control  ',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='text'
          name='username'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span className='alert-danger'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <div className='d-flex justify-content-between mt-n5'></div>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'KIDLING.PASSWORD'})}
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control  ',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='alert-danger'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-stack mb-8'>
        {/* begin::Label */}
        {/* <label className='form-label fw-bold text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'KIDLING.PASSWORD'})}
            </label> */}
        {/* end::Label */}
        {/* begin::Link */}
        <Link
          to={company ? `/${company}/auth/forgot-password` : '/kidling/auth/forgot-password'}
          className='  fs-6 fw-bold'
          style={{marginLeft: '5px'}}
        >
          {intl.formatMessage({id: 'KIDLING.FORGOT_PASSWORD'})}?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'> {intl.formatMessage({id: 'login'})}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        {/* <div className='text-center text-muted text-uppercase fw-bold mb-5'>or</div> */}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google *
        </a>/}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
