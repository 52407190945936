import {FC, useState, useEffect} from 'react'
import {useDropzone} from 'react-dropzone'
import {useIntl} from 'react-intl'
import SweetAlert from 'react-bootstrap-sweetalert'

export const thumbsContainer: any = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

export const thumb: any = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

export const thumbInner: any = {
  width: 100,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 0,
  overflow: 'hidden',
}

type Props = {
  setSelectedFiles: any
  setShowAttachModal: any
}

const MessageAttachModalForm: FC<Props> = ({setSelectedFiles, setShowAttachModal}) => {
  const [files, setFiles] = useState<any>([])
  const [showAlert, setShowAlert] = useState<any>(false)
  const intl = useIntl()

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      '*/*': [],
    },
    onDrop: (acceptedFiles: any) => {
      setFiles([
        ...files,
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ])
    },
  })

  const removeFile = (file: any) => {
    const temp = files.filter((item: any) => item !== file)
    setFiles(temp)
  }
  const thumbs = files.map((file: any) => (
    <div style={thumb} key={file.name} className='position-relative image__preview'>
      <div style={thumbInner}>
        <img
          src={
            file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
              ? file.preview
              : file.type === 'application/pdf'
              ? '/media/svg/files/pdf.svg'
              : file.type ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              ? '/media/svg/files/doc.svg'
              : '/media/svg/files/upload.svg'
          }
          className='image-input image-input-outline image-input-wrapper w-100 h-100 contain'
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
          alt={file.name}
        />
      </div>

      <span
        className='btn btn-icon position-absolute p-0 end-0 btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
        data-kt-image-input-action='cancel'
        data-bs-toggle='tooltip'
        title='Remove'
        onClick={() => removeFile(file)}
      >
        <i className='fa-solid fa-xmark'>
          <span className='path1'></span>
          <span className='path2'></span>
        </i>
      </span>
    </div>
  ))

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview))
  }, [])

  const [userForEdit] = useState({
    notes: '',
    date: new Date(),
    startTime: new Date(),
    endTime: new Date(),
  })

  const cancel = (withRefresh?: boolean) => {
    setShowAttachModal(false)
  }

  return (
    <>
      <form id='kt_modal_add_user_form' className='form ' noValidate>
        <section className='container mt-7'>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <p>
              {intl.formatMessage({
                id: 'KIDLING.HOME.DRAG_AND_DROP_SOME_FILES_HERE_OR_CLICK_TO_SELECT_FILES',
              })}
            </p>
          </div>
          <aside style={thumbsContainer}>{thumbs}</aside>
        </section>
      </form>

      {/* begin::Actions */}
      <div className='text-center pt-8'>
        <button
          type='reset'
          onClick={() => cancel()}
          className='btn btn-light me-3'
          data-kt-users-modal-action='cancel'
        >
          {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
        </button>

        <button
          type='submit'
          className='btn btn-primary'
          data-kt-users-modal-action='submit'
          onClick={() => {
            setSelectedFiles(files)
            cancel()
          }}
        >
          <span className='indicator-label'>
            {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
          </span>
        </button>
      </div>
      {/* end::Actions */}

      <SweetAlert
        show={showAlert}
        success
        title='Success!'
        onConfirm={(e) => setShowAlert(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </>
  )
}

export {MessageAttachModalForm}
