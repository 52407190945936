import React from 'react'
import {Navigate, Outlet, Route, Routes} from 'react-router-dom'
import MainPage from './components/main-page'
import EmployeeClockin from './components/employee-clockin'
import ClockSheet from './components/employee-clockin/ClockSheet'
import SheetDay from './components/employee-clockin/SheetDay'
import Shifts from './components/shifts/Shifts'
import Vacations from './components/vacations'
import Tasks from './components/tasks/Tasks'
import CalenderPage from '../calender'
import './styles.scss'

function Employee() {
  const EmployeeLayout = () => {
    return (
      <div id='kt_app_content_container' className=' h-100 position-relative'>
        <div className='d-flex flex-column flex-lg-row h-100'>
          {/* <div className='  d-flex flex-column flex-lg-row-auto w-100 w-lg-275px'>
            <div className='card card-flush mb-0'>
              <EmployeeSidebar />
            </div>
          </div> */}
          <div className='flex-lg-row-fluid h-100 z-index-2'>
            <div className='h-100'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      {/* <div className='container-xxl mb-7'>
        <UserTopProfile />
      </div> */}
      <Routes>
        <Route element={<EmployeeLayout />}>
          <Route path='main' element={<MainPage />} />
          <Route path='clockin' element={<EmployeeClockin />} />
          <Route path='clockin/sheet' element={<ClockSheet />} />
          <Route path='clockin/sheet/day' element={<SheetDay />} />
          <Route path='shifts' element={<Shifts />} />
          <Route path='vacations' element={<Vacations />} />

          <Route path='tasks' element={<Tasks />} />
          <Route path='meetings' element={<CalenderPage />} />
          <Route index element={<Navigate to='main' />} />
        </Route>
      </Routes>
    </div>
  )
}

export default Employee
