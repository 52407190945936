import React, {useEffect} from 'react'
import ChatSidebar from './components/ChatSidebar'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import './styles.scss'
import {useMediaQuery} from 'react-responsive'
import PrivateDetailsMessages from './components/PrivateDetailsMessages'
import useIsChatDetailsPath from '../../../hooks/useCurrentPath'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'

function Chat() {
  const isDetailsPage = useIsChatDetailsPath()
  const isMobile = useMediaQuery({
    query: '(max-width: 1000px)',
  })
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    // if (mobileConfiguration[6]?.length > 0 && !mobileConfiguration[6][0]?.showInHrApp) {
    navigate('/')
    // }
  }, [mobileConfiguration])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: '5rem',
        left: 0,
        right: 0,
        bottom: '2rem',

        overflow: 'hidden',
      }}
    >
      <div
        id='kt_app_content'
        className='app-content flex-column-fluid'
        style={{
          display: 'flex',
          justifyContent: 'center',

          height: '86%',
          width: '80%',
          overflow: 'hidden',
          borderBottom: '1px solid #ccc',
        }}
      >
        <div
          id='kt_app_content_container'
          className='h-100 app-container card card-flush container-xxl p-0'
        >
          <div className='h-100 d-flex flex-column flex-lg-row gap-4 '>
            {(!(isMobile && isDetailsPage) || !isMobile) && (
              <div className='h-100 d-flex flex-column  w-100 mw-100 w-100 w-lg-350px  '>
                <div className='h-100  mb-0'>
                  <ChatSidebar />
                </div>
              </div>
            )}

            <div className='flex-lg-row-fluid p-0 m-0 h-100 chat__background'>
              <div className='  h-100 p-0 '>
                {/* <GroupDetailsMessages /> */}
                <Routes>
                  <Route element={<Outlet />}>
                    {/* <Route path='group/:id' element={<GroupDetailsMessages />} /> */}
                    <Route path='private/:id' element={<PrivateDetailsMessages />} />
                    {!isMobile && (
                      <Route
                        path='*'
                        element={
                          <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
                            <i
                              className='fa-sharp fa-solid fa-comments  '
                              style={{fontSize: '6rem'}}
                            ></i>
                          </div>
                        }
                      />
                    )}
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chat
