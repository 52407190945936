/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import clsx from 'clsx'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useAppContext} from '../../core/AppContextProvider'
import {useAuth} from '../../../../app/modules/auth'
import AvatarWithLetters from '../../../../app/general-components/avatar-with-letters'
import {useThemeMode} from '../../../partials/layout/theme-mode/ThemeModeProvider'
import {useChatContext} from '../../../../app/context/ChatProvider'
import {useMediaQuery} from 'react-responsive'
import {Link} from 'react-router-dom'
import Menu from '@mui/material/Menu'
import {IconButton} from '@mui/material'
import './styles.scss'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p'

const Topbar: FC = () => {
  const {currentUserImage, tabMessagesCounts, mobileConfiguration, companyMetaData} =
    useAppContext()
  const company = window.location.pathname.split('/')[1]
  const {currentUser} = useAuth()
  const {mode} = useThemeMode()
  const {allChats} = useChatContext()
  const [countOfNewChats, setCountOfNewChats] = useState(0)
  const isMobile = useMediaQuery({
    query: '(max-width:762px)',
  })
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    let count = 0
    allChats &&
      allChats?.map((item: any) => {
        count = count + Number(item?.countOfNewMessages)
      })
    setCountOfNewChats(count)
  }, [allChats])

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-center flex-shrink-0'>
        {/* Search */}
        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
          <Search />
        </div> */}
        <button
          className='btn btn-icon btn-active-light-primary btn-custom position-relative p-0 '
          onClick={() => window.location.reload()}
        >
          <i
            className='fa-solid fa-arrows-rotate'
            style={{
              color: mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : '',
            }}
          ></i>
        </button>
        {mobileConfiguration &&
          mobileConfiguration[5]?.length > 0 &&
          mobileConfiguration[5][0]?.showInHrApp &&
          !isMobile && (
            <Link
              to={company ? `/${company}/messages` : '/kidling/messages'}
              className='btn btn-icon btn-active-light-primary btn-custom position-relative p-0'
            >
              <i
                className='fas fa-envelope fa-2x'
                style={{
                  fontSize: '1.3rem',
                  color:
                    mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : '',
                }}
              ></i>

              <span
                className='position-absolute   translate-middle badge rounded-pill bg-danger'
                style={{top: '0.8rem', right: '-1rem'}}
              >
                {tabMessagesCounts?.unread > 0 && tabMessagesCounts?.unread}
              </span>
            </Link>
          )}
        {/* {mobileConfiguration &&
          mobileConfiguration[6]?.length > 0 &&
          mobileConfiguration[6][0]?.showInHrApp &&
          !isMobile && (
            <Link
              to={company ? `/${company}/chat` : '/kidling/chat'}
              className='btn btn-icon btn-active-light-primary btn-custom position-relative p-0 mx-sm-1'
            >
              <i
                className='fa-sharp fa-solid fa-comments fs-3 '
                style={{
                  color:
                    mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : '',
                }}
              ></i>

              <span
                className='position-absolute   translate-middle badge rounded-pill bg-danger'
                style={{top: '0.8rem', right: '-1rem'}}
              >
                {countOfNewChats > 0 && countOfNewChats}
              </span>
            </Link>
          )} */}
        {/* Activities */}
        {/* <div className={clsx('d-flex align-items-center ', toolbarButtonMarginClass)}>
          
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            id='kt_activities_toggle'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen032.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
         
        </div> */}

        {/* NOTIFICATIONS */}
        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
         
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen022.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <HeaderNotificationsMenu />
          
        </div> */}

        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
         
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary btn-custom',
              toolbarButtonHeightClass
            )}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen025.svg'
              className={toolbarButtonIconSizeClass}
            />
          </div>
          <QuickLinks />
         
        </div> */}

        {/* begin::Theme mode */}
        <div className={clsx('d-flex align-items-center me-2', toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>

        {/* <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative ms-2 ',
            toolbarButtonHeightClass
          )}
        >
          <Link
            to={company ? `/${company}/companyInformation` : '/kidling/companyInformation'}
            className='  position-relative p-0'
          >
            <i
              className='fa-solid fa-school fa-2x'
              style={{
                fontSize: '1.3rem',
                color:
                  mode !== 'dark' && companyMetaData.iconColor ? companyMetaData.iconColor : '',
              }}
            ></i>
          </Link>
        </div> */}

        {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <Link to='/questions'>
            <i
              className='fa-solid fa-circle-question  text-white me-4 mt-1'
              style={{fontSize: '1.3rem'}}
            ></i>
          </Link>
        </div> */}
        {/* end::Theme mode */}

        {/* begin::User */}
        <div className={clsx('d-flex align-items-center  ')} id='kt_header_user_menu_toggle'>
          {/* begin::Toggle */}

          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ml: 2}}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
            className='p-4 m-0'
          >
            <AvatarWithLetters
              image={currentUserImage}
              alt={currentUser?.usersName?.charAt(0).toUpperCase() ?? ''}
              extrasmall
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id='account-menu'
            open={open}
            onClose={handleClose}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            className='py-0'
          >
            <HeaderUserMenu />
          </Menu>
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export {Topbar}
