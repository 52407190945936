import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {KTCard} from '../../../../_metronic/helpers'
import Calender from './components/calender'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useEffect} from 'react'

const CalenderPage = () => {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (mobileConfiguration[7]?.length > 0 && !mobileConfiguration[7][0]?.showInHrApp) {
      navigate('/')
    }
  }, [mobileConfiguration])
  return (
    <KTCard>
      <Calender />
    </KTCard>
  )
}

export default CalenderPage
