import React, {FC, ReactElement, useEffect, useMemo, useState} from 'react'
import {Button} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {toast} from 'react-toastify'
import {selectStyle, selectStyleDarkMode} from '../../../../utils/select-style'
import {updateEmployeeInfo} from '../../../../../actions/employee'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const UserAddress: FC = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const lang = useLang()
  const {employeeInfo, setEmployeeInfo, countries, cities} = useAppContext()
  const [countryOption, setCountryOption] = useState([])
  const [citiesOptions, setCitiesOptions] = useState([])
  const intl = useIntl()
  const {mode} = useThemeMode()
  const [initialValues, setInitialValues] = useState<any>({
    addressStreetName: employeeInfo?.addressStreetName || null,
    addressHouseNumber: employeeInfo?.addressHouseNumber || null,
    addressPostalCode: employeeInfo?.addressPostalCode || null,
    stateId: employeeInfo?.stateId || null,
    countryId: employeeInfo?.countryId || null,
  })

  useEffect(() => {
    let temp: any = []
    countries?.map((item: any) =>
      temp.push({
        label: item.countryName,
        value: item.countryId,
      })
    )
    setCountryOption(temp)
  }, [countries])

  useEffect(() => {
    let temp: any = []
    cities?.map((item: any) =>
      temp.push({
        label: item.cityName,
        value: item.stateId,
      })
    )
    setCitiesOptions(temp)
  }, [cities])

  const Schema = Yup.object().shape({
    addressStreetName: Yup.string(),
    addressHouseNumber: Yup.string(),
    addressPostalCode: Yup.string(),
    stateId: Yup.string(),
    countryId: Yup.string(),
  })
  const formik: any = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)

      try {
        const data = {
          ...employeeInfo,
          ...values,
        }

        const resp = await updateEmployeeInfo(data)

        if (!!resp.employeeId) {
          setEmployeeInfo(resp)

          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
        setSubmitting(false)
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <form
      id='kt_modal_add_user_form'
      className='form container p-8 d-flex flex-column align-items-end'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <div className='row align-items-center'>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-body'>
            {intl.formatMessage({id: 'KIDLING.STREET_NAME'})}
          </label>
          <input
            {...formik.getFieldProps('addressStreetName')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.addressStreetName && formik.errors.addressStreetName},
              {
                'is-valid': formik.touched.addressStreetName && !formik.errors.addressStreetName,
              }
            )}
            name='addressStreetName'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            value={formik.values.addressStreetName}
          />
          {formik.touched.addressStreetName && formik.errors.addressStreetName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.addressStreetName}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-body'>
            {intl.formatMessage({id: 'KIDLING.HOUSE_NUMBER'})}
          </label>
          <input
            {...formik.getFieldProps('addressHouseNumber')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.addressHouseNumber && formik.errors.addressHouseNumber},
              {
                'is-valid': formik.touched.addressHouseNumber && !formik.errors.addressHouseNumber,
              }
            )}
            name='addressHouseNumber'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            value={formik.values.addressHouseNumber}
          />
          {formik.touched.addressHouseNumber && formik.errors.addressHouseNumber && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.addressHouseNumber}</span>
              </div>
            </div>
          )}
        </div>

        <div className=' col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-body'>
            {intl.formatMessage({id: 'KIDLING.POSTAL_CODE'})}
          </label>
          <input
            {...formik.getFieldProps('addressPostalCode')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.addressPostalCode && formik.errors.addressPostalCode},
              {
                'is-valid': formik.touched.addressPostalCode && !formik.errors.addressPostalCode,
              }
            )}
            name='addressPostalCode'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            value={formik.values.addressPostalCode}
          />
          {formik.touched.addressPostalCode && formik.errors.addressPostalCode && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.addressPostalCode}</span>
              </div>
            </div>
          )}
        </div>
        <div className=' col-md-6 col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-body'>
            {intl.formatMessage({id: 'KIDLING.CITY'})}
          </label>
          <Select
            name='stateId'
            options={citiesOptions}
            className='basic-multi-select'
            classNamePrefix='select'
            isClearable
            onChange={(val: any) => formik.setFieldValue('stateId', val.value)}
            styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'var(--bs-dark-light)',
                primary: 'var(--bs-gray)',
                neutral0: 'var(--bs-dark-light)',
              },
            })}
            value={citiesOptions.find((item: any) => item.value === formik.values.stateId)}
          />

          {formik.touched.stateId && formik.errors.stateId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.stateId}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-12 my-3'>
          <label className=' form-label fs-6 fw-semibold text-body'>
            {intl.formatMessage({id: 'KIDLING.COUNTRY'})}
          </label>
          <Select
            name='countryId'
            options={countryOption}
            className='basic-multi-select'
            classNamePrefix='select'
            isClearable
            onChange={(val: any) => formik.setFieldValue('countryId', val.value)}
            styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
            theme={(theme) => ({
              ...theme,

              colors: {
                ...theme.colors,
                primary25: 'var(--bs-dark-light)',
                primary: 'var(--bs-gray)',
                neutral0: 'var(--bs-dark-light)',
              },
            })}
            value={countryOption.find((item: any) => item.value === formik.values.countryId)}
          />
          {formik.touched.countryId && formik.errors.countryId && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.countryId}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <Button
        color='primary'
        type='submit'
        disabled={formik.isSubmitting || isLoading}
        className='ms-auto'
      >
        {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
      </Button>
    </form>
  )
}

export default UserAddress
