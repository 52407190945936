import axios from 'axios'
import * as endpoints from '../config/endpoints'
import moment from 'moment'

export const getAbsenceReason = async (filter: string) => {
  return axios
    .get(`${endpoints.EMPLOYEE_ABSENCE_REASON}/listwhere/${filter}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
