import {useEffect, useState} from 'react'
import {useCompanyContext} from '../../_metronic/layout/core/CompanyProvider'
import {readDaysOff, readHolidays} from '../../actions/events'
import {getCompanyInfo} from '../../actions/company'

const useCompanyInfo = () => {
  const {currentCompany} = useCompanyContext()
  const [holidays, setHolidays] = useState([])
  const [daysOff, setDaysOff] = useState([])
  const [companyInfo, setcompanyInfo] = useState([])

  useEffect(() => {
    if (currentCompany) readHolidayesAndDaysOff()
  }, [currentCompany])

  const readHolidayesAndDaysOff = async () => {
    Promise.all([
      readHolidays(),
      readDaysOff(),
      getCompanyInfo(`companyId=${currentCompany.value}`),
    ])
      .then(function ([resp1, resp2, resp3]) {
        setHolidays(resp1)
        setDaysOff(resp2)
        setcompanyInfo(resp3)
      })
      .catch(console.log)
  }
  return {holidays, daysOff, companyInfo}
}

export default useCompanyInfo
