import React, {FC, ReactElement} from 'react'
import './styles.scss'
type Props = {
  name: string
  onChange: (v: boolean) => void
  yesText: string
  noText: string
}
const ToggleSwitch: FC<Props> = ({name, onChange, yesText, noText}): ReactElement => {
  return (
    <div className='toggle-switch'>
      <input
        type='checkbox'
        className='toggle-switch-checkbox'
        name={name}
        id={name}
        onChange={(prev) => onChange(true)}
      />
      <label className='toggle-switch-label' htmlFor={name}>
        <span className='toggle-switch-inner' data-yes={yesText} data-no={noText} />
        <span className='toggle-switch-switch' />
      </label>
    </div>
  )
}

export default ToggleSwitch
