import {FC, ReactElement, useEffect, useState} from 'react'
import {Modal, ModalBody} from 'reactstrap'
import './modal.scss'
import moment from 'moment'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import SweetAlert from 'react-bootstrap-sweetalert'
import {useAppContext} from '../../../../../../../_metronic/layout/core/AppContextProvider'
import {Avatar} from '@mui/material'
import CustomTabs from '../../../../../../general-components/custom-tabs'
import TimeEditableCell from '../../../../../../general-components/time-edittable/TimeEditableCell'
import ErrorText from '../../../../../../general-components/error-text/ErrorText'
import {
  DeleteEmployeeAttendance,
  updateEmployeeAttendance,
  addEmployeeAttendance,
} from '../../../../../../../actions/employee'
import {useAuth} from '../../../../../auth'
import {momentDateFormat} from '../../../../../../../config/constants'
import {useSearchParams} from 'react-router-dom'

type dialogProps = {
  show: boolean
  data: any
}
type Props = {
  show: dialogProps
  setShow: (v: dialogProps) => void
  readEmployeeAttendance?: (startDate: string, endDate: string) => void
  readOnly?: boolean
}
const AttendanceDetailsModal: FC<Props> = ({
  show,
  setShow,
  readEmployeeAttendance,
  readOnly,
}): ReactElement => {
  const {currentUserImage, attendanceTypes, mobileConfiguration} = useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [currentData, setCurrentData] = useState(show?.data)
  const [remarks, setRemarks] = useState(show?.data?.remarks)
  const [showDelete, setShowDelete] = useState(false)
  const [searchParams] = useSearchParams()
  const intl = useIntl()
  const {currentUser} = useAuth()

  useEffect(() => {
    setRemarks(show?.data?.remarks)
    setCurrentData(show?.data)
  }, [show?.data])

  const handleClose = () => {
    setRemarks('')
    setCurrentData(null)
    setShow({show: false, data: null})
  }

  const saveChanges = async () => {
    setIsLoading(true)

    let resp = null
    if (!show.data?.isNew) {
      const data = {
        ...currentData,
        remarks: remarks,
      }
      resp = await updateEmployeeAttendance(data)
    } else {
      const data = {
        date: moment().format(momentDateFormat),
        in: moment().format(momentDateFormat),
        out: moment().format(momentDateFormat),
        employeeId: currentUser?.employee.employeeId,
        ...currentData,
        numberOfMinutes: 0,
        remarks: remarks,
      }
      data.numberOfMinutes = moment
        .duration(
          moment(
            moment(
              moment(data.date)
                .set('hours', moment(data.out).hours())
                .set('minutes', moment(data.out).minutes())
            )
          ).diff(
            moment(
              moment(data.date)
                .set('hours', moment(data.in).hours())
                .set('minutes', moment(data.in).minutes())
            )
          )
        )
        .asMinutes()

      resp = await addEmployeeAttendance(data)
    }

    setIsLoading(false)
    if (!!resp) {
      if (readEmployeeAttendance)
        readEmployeeAttendance(
          `${moment(searchParams.get('date')).startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
          `${moment(searchParams.get('date')).endOf('day').format('yyyy-MM-DDTHH:mm:ss')}`
        )
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))

      handleClose()
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  const handleDelete = async () => {
    if (show?.data?.employeeAttendanceId) {
      setIsDeleting(true)
      const resp = await DeleteEmployeeAttendance(Number(show?.data?.employeeAttendanceId))

      if (resp?.status === 200) {
        if (readEmployeeAttendance)
          readEmployeeAttendance(
            `${moment(searchParams.get('date')).startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
            `${moment(searchParams.get('date')).endOf('day').format('yyyy-MM-DDTHH:mm:ss')}`
          )
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        setIsDeleting(false)
        handleClose()
      } else {
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      }
    }
  }

  return (
    <Modal
      isOpen={show.show}
      toggle={handleClose}
      fullscreen={true}
      zIndex={1000}
      onClosed={handleClose}
      fade
      modalClassName='shiftModal'
    >
      {/* <ModalHeader toggle={handleClose}>Details</ModalHeader> */}
      <ModalBody>
        <div className='d-flex w-100 justify-content-center '>
          <div className='d-flex  flex-column align-items-start  mw-400px w-100'>
            <div className='d-flex align-items-center mb-1 w-100'>
              <Avatar
                src={currentUserImage ?? '/media/avatars/blank.png'}
                alt={currentUser?.employee?.employeeName.charAt(0).toUpperCase()}
              />
              <div className='ms-4'>
                {/* <p className='my-0 text-body'>Projektleiter</p> */}
                <h5 className='mb-0 ms-1'>{currentUser?.employee?.employeeName}</h5>
              </div>
              <div className='ms-auto d-flex align-items-center'>
                <div
                  className={`d-flex align-items-center alert alert-${
                    show?.data?.attendanceTypeId === 2 ? 'danger' : 'success'
                  } px-4 border-0 rounded mb-0 mx-1 p-3`}
                  role='alert'
                >
                  {show?.data?.out
                    ? `${Math.floor(show?.data?.numberOfMinutes / 60)}h 
                ${Math.floor(show?.data?.numberOfMinutes % 60)}m`
                    : `${Math.floor(
                        Math.ceil(
                          moment
                            .duration(
                              moment().diff(
                                moment(
                                  moment(show?.data?.date)
                                    .set('hours', moment(show?.data?.in).hours())
                                    .set('minutes', moment(show?.data?.in).minutes())
                                )
                              )
                            )
                            .asMinutes()
                        ) / 60
                      )}h 
                  ${Math.floor(
                    Math.ceil(
                      moment
                        .duration(
                          moment().diff(
                            moment(
                              moment(show?.data?.date)
                                .set('hours', moment(show?.data?.in).hours())
                                .set('minutes', moment(show?.data?.in).minutes())
                            )
                          )
                        )
                        .asMinutes()
                    ) % 60
                  )}m`}
                </div>
                <div
                  className={`d-flex align-items-center alert alert-${
                    show?.data?.attendanceTypeId === 2 ? 'danger' : 'success'
                  } px-4 border-0 rounded mb-0`}
                  role='alert'
                >
                  <i
                    className={` ${
                      show?.data?.attendanceTypeId === 2
                        ? 'fa-solid fa-mug-hot text-danger'
                        : 'fa-regular fa-clock text-success'
                    }  `}
                  ></i>
                </div>
              </div>
            </div>
            <div className='my-4 w-100'>
              <CustomTabs
                value={show?.data?.attendanceTypeId - 1}
                onChange={(val: any) => {
                  setCurrentData((prev: Date) => ({
                    ...prev,
                    attendanceTypeId: val + 1,
                  }))
                }}
                options={attendanceTypes?.map((item: any) => item.attendanceTypeName)}
                disabled={
                  (!(
                    mobileConfiguration[4]?.length > 0 && mobileConfiguration[4][0]?.showInHrApp
                  ) ||
                    readOnly) &&
                  !show?.data?.isNew
                }
              />
            </div>
            {/* <p className='m-0 p-1 alert alert-warning align-self-start text-gray-800 mw-fit-content'>
              Überstundenschicht
            </p> */}
            <div className='row mt-4 w-100'>
              <div className='col-4'>
                <p className='mb-0 text-body'>{intl.formatMessage({id: 'KIDLING.HOME.DATE'})}</p>
                <p>{moment(show?.data?.date).format('DD.MM.yyyy')}</p>
              </div>
              <div className='col-8'>
                <p className='mb-0 text-body'>{intl.formatMessage({id: 'KIDLING.TIME'})}</p>
                <div className='d-flex align-items-center'>
                  <div className='d-flex bg-light rounded p-1 text-gray-600 me-2'>
                    <TimeEditableCell
                      value={moment(currentData?.date)
                        .set('hours', moment(currentData?.in).hours())
                        .set('minutes', moment(currentData?.in).minutes())}
                      onChange={(val: any) => {
                        setCurrentData((prev: any) => ({
                          ...prev,
                          in: moment(val).format('yyyy-MM-DDTHH:mm:ss'),
                          out: moment(val).format('yyyy-MM-DDTHH:mm:ss'),
                        }))
                      }}
                      disabled={
                        (!(
                          mobileConfiguration[4]?.length > 0 &&
                          mobileConfiguration[4][0]?.showInHrApp
                        ) ||
                          readOnly) &&
                        !show?.data?.isNew
                      }
                    />
                  </div>
                  {' - '}
                  <div className='d-flex bg-light rounded p-1 text-gray-600 ms-2'>
                    {' '}
                    {(show?.data?.out || show?.data?.isNew) && (
                      <TimeEditableCell
                        value={
                          currentData?.out
                            ? moment(currentData?.date)
                                .set('hours', moment(currentData?.out).hours())
                                .set('minutes', moment(currentData?.out).minutes())
                            : moment()
                        }
                        onChange={(val: any) => {
                          setCurrentData((prev: any) => ({
                            ...prev,
                            out: moment(val).format('yyyy-MM-DDTHH:mm:ss'),
                          }))
                        }}
                        disabled={
                          (!(
                            mobileConfiguration[4]?.length > 0 &&
                            mobileConfiguration[4][0]?.showInHrApp
                          ) ||
                            readOnly) &&
                          !show?.data?.isNew
                        }
                        minDate={currentData?.in ?? new Date()}
                      />
                    )}{' '}
                    {!show?.data?.out && !show?.data?.isNew && (
                      <span
                        className='text-bold d-flex align-items-center'
                        // onClick={() => setEditNow(true)}
                      >
                        <i
                          className={`fa-solid fa-circle mx-1 ${
                            show?.data?.attendanceTypeId === 2 ? 'text-danger' : 'text-success'
                          }`}
                        ></i>{' '}
                        {intl.formatMessage({id: 'KIDLING.NOW'})}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className=' col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'PARENT.Remarks'})}
              </label>
              <textarea
                className={'form-control  mb-3 mb-lg-0'}
                name='remarks'
                autoComplete='off'
                value={remarks}
                rows={3}
                onChange={(e: any) => setRemarks(e.target.value)}
                disabled={
                  (!(
                    mobileConfiguration[4]?.length > 0 && mobileConfiguration[4][0]?.showInHrApp
                  ) ||
                    readOnly) &&
                  !show?.data?.isNew
                }
              />
            </div>
            {((mobileConfiguration[4]?.length > 0 &&
              mobileConfiguration[4][0]?.showInHrApp &&
              !readOnly) ||
              show?.data?.isNew) && (
              <div className='d-flex align-items-center w-100'>
                {!show?.data?.isNew && (
                  <button
                    type='button'
                    className='btn btn-danger my-4 w-100 me-2'
                    onClick={() => setShowDelete(true)}
                  >
                    {!isDeleting ? (
                      <i className='fa-solid fa-trash fs-3'></i>
                    ) : (
                      <span className='indicator-progress ' style={{display: 'inline-block'}}>
                        {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                )}
                <button
                  type='button'
                  className='btn btn-success my-4 w-100'
                  onClick={() => saveChanges()}
                >
                  {!isLoading ? (
                    <i className='fa-solid fa-check fs-3  '></i>
                  ) : (
                    <span className='indicator-progress ' style={{display: 'inline-block'}}>
                      {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          handleDelete()
        }}
        onCancel={() => setShowDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </Modal>
  )
}

export default AttendanceDetailsModal
