import moment from 'moment'
import React, {FC} from 'react'
const ShiftCell: FC = (eventInfo: any) => {
  const data = eventInfo.event._def.extendedProps

  return (
    <div className='border-start border-4 border-warning  ps-1 py-2 w-100 h-100 bg-light'>
      <p className='m-0 p-0 text-warning'>
        {data?.employeeShiftTypeName ?? eventInfo.event._def.title}
      </p>
      <div className='d-flex flex-wrap align-items-center  '>
        <div className='   bg-light   text-gray-600'>{moment(data.fromTime).format('HH:mm')}</div>
        <div className=' p-0 mx-1 text-gray-600'>{'-'}</div>
        <div className=' p-0 bg-light   text-gray-600'>{moment(data.toTime).format('HH:mm')}</div>
      </div>
    </div>
  )
}

export default ShiftCell
