import React, {FC, ReactElement, useEffect, useState} from 'react'
import Select, {StylesConfig} from 'react-select'
import {setLanguage, useLang} from '../../../i18n/Metronici18n'
import {useAppContext} from '../../../layout/core/AppContextProvider'
import {selectStyle, selectStyleDarkMode} from '../../../../app/utils/select-style'
import {useThemeMode} from '../theme-mode/ThemeModeProvider'

const formatOptionLabel = ({value, label, flag}: any) => (
  <div className='d-flex align-items-center'>
    <img src={flag} alt='flag' className='w-15px h-15px rounded-1 ms-2 me-2' />
    <div>{label}</div>
  </div>
)

interface Props {
  setCurrentLang?: any
  value?: any
}
const LanguageSelect: FC<Props> = ({setCurrentLang, value}): ReactElement => {
  const lang = useLang()
  const {languages}: any = useAppContext()
  const [currentLanguage, setCurrentLanguage] = useState(null)
  const {mode} = useThemeMode()
  useEffect(() => {
    if (languages.length > 0) {
      const temp = languages?.find((x: any) => x.value === lang)
      setCurrentLanguage(temp)
    }
  }, [languages])

  const changeUserLang = (val: string) => {
    const langId = languages?.findIndex((x: any) => x.value === val)

    setCurrentLang(langId + 1)
  }

  const style = {
    control: (base: any) => ({
      ...base,
      width: '100%',
      minWidth: '150px',

      borderRaduis: '0.5rem',
      padding: '2px',
      boxShadow: 'none',
      backgroundColor: 'var(--bg-color)',
      '&:hover': {
        borderColor: '#eee',
      },
    }),
    menu: (provided: any) => ({...provided, zIndex: 9999}),
  }
  return (
    <div className='d-flex justify-content-end w-100 m-2'>
      <div className='  w-100'>
        <Select
          value={setCurrentLang ? value : currentLanguage}
          name='Language'
          options={languages}
          className='basic-multi-select'
          classNamePrefix='select'
          formatOptionLabel={formatOptionLabel}
          onChange={(val: any) =>
            setCurrentLang ? changeUserLang(val.value) : setLanguage(val.value)
          }
          isLoading={languages.length === 0}
          theme={(theme) => ({
            ...theme,

            colors: {
              ...theme.colors,
              primary25: 'var(--bs-dark-light)',
              primary: 'var(--bs-gray)',
              neutral0: 'var(--bs-dark-light)',
            },
          })}
          styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
        />
      </div>
    </div>
  )
}

export default LanguageSelect
