import moment from 'moment'
import {getAllEmployeeShifts, getAllEmployeeShiftsFiles} from '../../actions/shifts'
import {useEffect, useState} from 'react'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'

const useShifts = () => {
  const {setGlobalLoader} = useAppContext()
  const [dateFilter, setDateFilter] = useState<any>({})
  const [employeeShifts, setEmployeeShifts] = useState([])
  const [employeeShiftsFiles, setEmployeeShiftsFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedShiftType, setSelectedShiftType] = useState<any>(null)
  const [formattedShifts, setFormattedShifts] = useState([])

  useEffect(() => {
    let tempObj: any = []
    employeeShifts?.map((item: any) => {
      tempObj.push({
        title: item?.employeeShiftTypeName ?? '',
        start: moment(item.date)
          .set('hours', moment(item.fromTime).toDate().getHours())
          .set('minutes', moment(item.fromTime).toDate().getMinutes())
          .format('yyyy-MM-DDTHH:mm:ss'),
        end: moment(item.date)
          .set('hours', moment(item.toTime).toDate().getHours())
          .set('minutes', moment(item.toTime).toDate().getMinutes())
          .format('yyyy-MM-DDTHH:mm:ss'),
        duration: {
          hours: moment
            .duration(
              moment(
                moment(item.date)
                  .set('hours', moment(item.fromTime).toDate().getHours())
                  .set('minutes', moment(item.fromTime).toDate().getMinutes())
                  .format('yyyy-MM-DDTHH:mm:ss')
              ).diff(
                moment(item.date)
                  .set('hours', moment(item.toTime).toDate().getHours())
                  .set('minutes', moment(item.toTime).toDate().getMinutes())
                  .format('yyyy-MM-DDTHH:mm:ss')
              )
            )
            .asHours(),
        },
        extendedProps: {...item},
        displayEventEnd: true,
      })
    })
    setFormattedShifts(tempObj)
  }, [employeeShifts])

  const readAllShifts = async () => {
    setIsLoading(true)
    const resp = await getAllEmployeeShifts(
      selectedShiftType?.employeeShiftTypeId ?? null,
      moment(dateFilter?.startStr).format('yyyy-MM-DDTHH:mm:ss'),
      moment(dateFilter?.endStr).format('yyyy-MM-DDTHH:mm:ss')
    )
    setEmployeeShifts(resp)
    setIsLoading(false)
  }
  const readAllShiftsFiles = async () => {
    setGlobalLoader(true)
    const resp = await getAllEmployeeShiftsFiles(
      moment(dateFilter?.startStr).format('yyyy-MM-DDTHH:mm:ss'),
      moment(dateFilter?.endStr).format('yyyy-MM-DDTHH:mm:ss')
    )
    setEmployeeShiftsFiles(resp)
    setGlobalLoader(false)
  }
  const triggerDateChanged = async (dateObj: any) => {
    setDateFilter(dateObj)
  }
  return {
    formattedShifts,
    employeeShiftsFiles,
    dateFilter,
    selectedShiftType,
    readAllShifts,
    readAllShiftsFiles,
    triggerDateChanged,
    setSelectedShiftType,
    isLoading,
  }
}

export default useShifts
