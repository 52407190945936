import {Fragment, useEffect} from 'react'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import PinboardCard from '../../../general-components/PinboardCard/PinboardCard'
import {Link, useNavigate} from 'react-router-dom'
import {NoRecords} from '../../../general-components/NoRecords'
import usePinboard from '../../../hooks/usePinboard'

function EmployeePinboard() {
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]
  const {employeePinboard, mobileConfiguration} = useAppContext()
  const {isLoading} = usePinboard()

  useEffect(() => {
    if (mobileConfiguration[10]?.length > 0 && !mobileConfiguration[10][0]?.showInHrApp) {
      navigate('/')
    }
  }, [mobileConfiguration])
  return (
    <div className=''>
      <Link to={company ? `/${company}/main` : '/kidling/main'}>
        <i className='fa-solid fa-arrow-left fs-3 me-4'></i>
      </Link>
      {employeePinboard?.map((item: any, index: number) => (
        <Fragment key={index}>
          <PinboardCard data={item} />
        </Fragment>
      ))}
      {employeePinboard?.length === 0 && !isLoading && <NoRecords />}
    </div>
  )
}

export default EmployeePinboard
