import React, {useState, createContext, useContext, FC} from 'react'
const ChatContext: any = createContext<any>(null)

export const useChatContext = (): any => {
  return useContext(ChatContext)
}

export default function ChatContextProvider({children}: any) {
  const [connection, setConnection] = useState(null)
  const [onlineUsers, setOnlineUsers] = useState<any>([])
  const [allChats, setAllChats] = useState([])
  const [chatDetails, setChatDetails] = useState<any>({})
  const [refreshConnection, setRefreshConnection] = useState(false)
  return (
    <ChatContext.Provider
      value={{
        connection,
        onlineUsers,
        allChats,
        chatDetails,
        refreshConnection,
        setConnection,
        setOnlineUsers,
        setAllChats,
        setChatDetails,
        setRefreshConnection,
      }}
    >
      {children}
    </ChatContext.Provider>
  )
}
