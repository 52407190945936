import axios from 'axios'
import * as endpoints from '../config/endpoints'
import moment from 'moment'
import {momentFullDateFormat} from '../app/utils/constants'

export const getAllEmployees = async () => {
  return axios
    .get(`${endpoints.EMPLOYEE}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeInfoByUserId = async (id: number) => {
  return axios
    .get(`${endpoints.EMPLOYEE}/listwhere/usersId=${id}?IsMobile=1`)
    .then((response) => response.data[0])
    .catch((error) => {
      console.log(error)
    })
}

export const updateEmployeeInfo = async (data: any) => {
  return axios
    .put(endpoints.EMPLOYEE, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeAttendance = async (filter: string) => {
  return axios
    .get(`${endpoints.EMPLOYEE_ATTENDANCE}/listwhere/${filter}`)
    .then((response) => {
      const data: any = response.data.sort((a: any, b: any) =>
        moment(b?.date)
          .set('hours', moment(b.in).hours())
          .set('minutes', moment(b.in).minutes())
          .diff(
            moment(a?.date)
              .set('hours', moment(a.in).hours())
              .set('minutes', moment(a.in).minutes())
          )
      )

      return data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeAttendanceById = async (id: number) => {
  return axios
    .get(`${endpoints.EMPLOYEE_ATTENDANCE}/${id}`)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      console.log(error)
    })
}
export const addEmployeeAttendance = async (data: any) => {
  return axios
    .post(`${endpoints.EMPLOYEE_ATTENDANCE}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const updateEmployeeAttendance = async (data: any) => {
  return axios
    .put(`${endpoints.EMPLOYEE_ATTENDANCE_UPDATE}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
export const DeleteEmployeeAttendance = async (id: number) => {
  return axios
    .delete(`${endpoints.EMPLOYEE_ATTENDANCE}/${id} `)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeShift = async (filter: string) => {
  return axios
    .get(`${endpoints.EMPLOYEE_SHIFT}/listwhere/${filter}`)
    .then((response) => {
      const data: any = response.data.sort((a: any, b: any) => moment(b?.date).diff(a?.date))
      return data
    })
    .catch((error) => {
      console.log(error)
    })
}

export const getAllEmployeesByKita = async (filter: string) => {
  return axios
    .get(`${endpoints.EMPLOYEE}/listwhere/${filter}?IsMobile=1`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const addEmployeeCheckin = async (attendanceTypeId: number) => {
  return axios
    .post(`${endpoints.EMPLOYEE_CHECKIN}?attendanceTypeId=${attendanceTypeId}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const addEmployeeCheckout = async () => {
  return axios
    .put(`${endpoints.EMPLOYEE_CHECKOUT}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeAttendanceByDates = async (From: string, To: string) => {
  return axios
    .get(`${endpoints.GE_EMPLOYEE_ATTENDANCE}`, {
      params: {From: From && To ? From : null, To: From && To ? To : null},
    })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      console.log(error)
    })
}
export const addEmployeeAbsence = async (data: number) => {
  return axios
    .post(`${endpoints.ADD_EMPLOYEE_ABSENCE}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
export const updateAbsencesOfEmployee = async (data: any) => {
  return axios
    .post(`${endpoints.UPDATE_EMPLOYEE_ABSENCE}`, data)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const deleteAbsenceOfEmployee = async (id: number) => {
  return axios
    .delete(`${endpoints.EMPLOYEE_ABSENCE}/${id}`)
    .then((response) => response)
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeAbsenceByDates = async (
  absenceType: number | null,
  From: string,
  To: string
) => {
  return axios
    .get(`${endpoints.EMPLOYEE_ABSENCE_BY_DATE}`, {
      params: {
        absenceType: absenceType && absenceType,
        From: From && To ? From : null,
        To: From && To ? To : null,
      },
    })
    .then((response) => {
      const data: any = response.data.data?.sort((a: any, b: any) =>
        moment(b?.employeeAbsenceFromDate).diff(a?.employeeAbsenceFromDate)
      )
      return data
    })

    .catch((error) => {
      console.log(error)
    })
}
export const getEmployeeAttendanceType = async () => {
  return axios
    .get(`${endpoints.EMPLOYEE_ATTENDANCE_TYPE}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}
export const getEmployeeAbsenceStatistics = async (EmployeeId: number) => {
  return axios
    .get(`${endpoints.EMPLOYEE_ABSENCE_STATISTICS}`, {
      params: {
        From: moment().startOf('year').format(momentFullDateFormat),
        To: moment().endOf('year').format(momentFullDateFormat),
        EmployeeId,
      },
    })
    .then((response) => response.data.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeAbsencePolicy = async (expression: string) => {
  return axios
    .get(`${endpoints.EMPLOYEE_ABSENCE_POLICY}/listwhere/${expression}`)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeAbsenceStatisticsAdjustment = async (
  From: string,
  To: string,
  // EmployeeAbsenceReasonId: number,
  EmployeeId: number
) => {
  const params = {From, To, EmployeeId}
  return axios
    .get(`${endpoints.EMPLOYEE_ABSENCE_STATISTICS_ADJUSTMENT}`, {params})
    .then((response) => response.data?.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getEmployeeVacationPolicies = async (
  From: string,
  To: string,
  CompanyId: number,
  EmployeeId: number
) => {
  const params = {From, To, EmployeeId, CompanyId}
  return axios
    .get(`${endpoints.GET_EMPLOYEE_VACATION_POLICY}`, {params})
    .then((response) => response?.data)
    .catch((error) => {
      console.log(error)
    })
}
