export const MESSAGE_LENGTH = 135
export const MOBILE_MESSAGE_LENGTH = 40
export const MESSAGE_FOLDER_ID = {
  Sent: 0,
  Draft: 1,
  Deleted: 2,
  Received: 3,
  Pinned: 4,
}

export const MESSAGE_TYPE = {
  Message: 1,
  Reminder: 2,
  Announcement: 3,
}

export const ATTENDACE_TYPE_ID = {
  checkin: 1,
  break: 2,
}

export const momentDateFormat = 'yyyy-MM-DDTHH:mm:ss'
