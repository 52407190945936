import React, {FC, ReactElement, useEffect, useState} from 'react'

type Props = {
  options: string[]
  onChange: (n: number) => void
  value?: number
  disabled?: boolean
  fullWidth?: boolean
}
const CustomTabs: FC<Props> = ({options, onChange, value, disabled, fullWidth}): ReactElement => {
  console.log('value', value)

  const [val, setVal] = useState(value || 0)

  useEffect(() => {
    if (!disabled) onChange(val)
  }, [val])

  useEffect(() => {
    if (value) setVal(value)
  }, [value])

  return (
    <div className={`d-flex align-items-center  ${!fullWidth && 'flex-wrap'}  w-100`}>
      {options.map((item: any, index: number) => (
        <button
          type='button'
          className={`btn rounded-0 my-1  ${fullWidth && 'w-100'} ${
            index == 0 ? 'rounded-start' : index === options.length - 1 && 'rounded-end'
          } ${index === val ? 'btn-primary' : 'btn-secondary  '}   `}
          key={index}
          onClick={() => !disabled && setVal(index)}
        >
          {item}
        </button>
      ))}
    </div>
  )
}

export default CustomTabs
