import React, {useEffect} from 'react'
import {ListViewProvider} from './core/ListViewProvider'
import Messages from './Messages'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useNavigate} from 'react-router-dom'

function MessagesWrapper() {
  const {mobileConfiguration} = useAppContext()
  const navigate = useNavigate()
  useEffect(() => {
    if (mobileConfiguration[5]?.length > 0 && !mobileConfiguration[5][0]?.showInHrApp) {
      navigate('/')
    }
  }, [mobileConfiguration])

  return (
    <ListViewProvider>
      <Messages />
    </ListViewProvider>
  )
}

export default MessagesWrapper
