import clsx from 'clsx'
import {useAppContext} from '../../core/AppContextProvider'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  const {mobileConfiguration} = useAppContext()
  const company = window.location.pathname.split('/')[1]

  return (
    <>
      <MenuItem
        title={intl.formatMessage({id: 'KIDLING.DASHBOARD'})}
        to={company ? `/${company}/employee/main` : '/kidling/employee/main'}
        hasBullet={false}
        fontIcon='fa-solid fa-home'
      ></MenuItem>
      {mobileConfiguration &&
        mobileConfiguration[7]?.length > 0 &&
        mobileConfiguration[7][0]?.showInHrApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.MENU.CALENDER'})}
            to={company ? `/${company}/calendar` : '/kidling/calendar'}
            hasBullet={false}
            fontIcon='fa-solid fa-calendar'
          ></MenuItem>
        )}
      {((mobileConfiguration &&
        mobileConfiguration[2]?.length > 0 &&
        mobileConfiguration[2][0]?.showInHrApp) ||
        (mobileConfiguration &&
          mobileConfiguration[3]?.length > 0 &&
          mobileConfiguration[3][0]?.showInHrApp) ||
        (mobileConfiguration &&
          mobileConfiguration[4]?.length > 0 &&
          mobileConfiguration[4][0]?.showInHrApp)) && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.HR.ATTENDANCE'})}
          to={company ? `/${company}/employee/clockin` : '/kidling/employee/clockin'}
          hasBullet={false}
          fontIcon='fa-solid fa-person-circle-plus'
        ></MenuItem>
      )}
      {mobileConfiguration &&
        mobileConfiguration[1]?.length > 0 &&
        mobileConfiguration[1][0]?.showInHrApp && (
          <MenuItem
            title={intl.formatMessage({id: 'KIDLING.HR.VACATIONS'})}
            to={company ? `/${company}/employee/vacations` : '/kidling/employee/vacations'}
            hasBullet={false}
            fontIcon='fa-solid fa-umbrella-beach'
          ></MenuItem>
        )}
      {((mobileConfiguration &&
        mobileConfiguration[8]?.length > 0 &&
        mobileConfiguration[8][0]?.showInHrApp) ||
        (mobileConfiguration &&
          mobileConfiguration[9]?.length > 0 &&
          mobileConfiguration[9][0]?.showInHrApp)) && (
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.HR.SHIFTS'})}
          to={company ? `/${company}/employee/shifts` : '/kidling/employee/shifts'}
          hasBullet={false}
          fontIcon='fa-solid fa-calendar-check'
        ></MenuItem>
      )}
      <MenuItem
        title={intl.formatMessage({id: 'KIDLING.COMPANY_INFO'})}
        to={company ? `/${company}/companyInformation` : '/kidling/companyInformation'}
        hasBullet={false}
        fontIcon='fa-solid fa-school'
      ></MenuItem>

      <div className={clsx(' mt-auto border-top')}>
        <MenuItem
          title={intl.formatMessage({id: 'KIDLING.NEED_HELP'})}
          to={'https://quintic.atlassian.net/servicedesk/customer/portal/5'}
          hasBullet={false}
          fontIcon='fa-solid fa-circle-question fa-2x'
          external
        ></MenuItem>
      </div>
    </>
  )
}
