import React, {FC, ReactElement, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import moment from 'moment-timezone'
import LanguageSelect from '../../../../../_metronic/partials/layout/language-select'
import {useLang} from '../../../../../_metronic/i18n/Metronici18n'
import {useAppContext} from '../../../../../_metronic/layout/core/AppContextProvider'
import {useAuth} from '../../../auth'
import {updateUserInfo} from '../../../../../actions/users'
import {toast} from 'react-toastify'

const UserInfo: FC = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const lang = useLang()
  const {languages, currentUserImage}: any = useAppContext()

  const {currentUser, setCurrentUser} = useAuth()
  const intl = useIntl()
  const [currentLang, setCurrentLang] = useState(currentUser?.languageId)

  const [initialValues, setInitialValues] = useState<any>({
    fullname: currentUser?.usersName || null,
    email: currentUser?.email || null,
    username: currentUser?.username || null,
    password: currentUser?.password || null,
  })

  // useEffect(() => {
  //   const temp = languages.find((x: any) => x.value === lang)
  //   setCurrentLanguage(temp)
  // }, [languages])

  const Schema = Yup.object().shape({
    fullname: Yup.string().required(intl.formatMessage({id: 'KIDLING.REQUIRED'})),
    email: Yup.string().email(intl.formatMessage({id: 'KIDLING.INVALID_EMAIL'})),
    username: Yup.string(),
  })
  const formik: any = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          ...currentUser,
          username: values.username,
          // email: values.email,
          languageId: currentLang,
          usersName: values.fullname,
          imagePath: currentUserImage,
        }

        const resp = await updateUserInfo(data)
        if (resp) {
          setCurrentUser(resp)

          toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <form
      id='kt_modal_add_user_form'
      className='form container p-8 d-flex flex-column align-items-end'
      onSubmit={formik.handleSubmit}
      noValidate
    >
      <div className='row align-items-center'>
        <div className='col-md-6 col-12 my-3'>
          <label className='required form-label fs-6 fw-semibold text-body'>
            {intl.formatMessage({id: 'KIDLING.FULLNAME'})}
          </label>
          <input
            {...formik.getFieldProps('fullname')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.fullname && formik.errors.fullname},
              {
                'is-valid': formik.touched.fullname && !formik.errors.fullname,
              }
            )}
            name='fullname'
            autoComplete='off'
            disabled={formik.isSubmitting || isLoading}
            rows={1}
          />
          {formik.touched.fullname && formik.errors.fullname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.fullname}</span>
              </div>
            </div>
          )}
        </div>
        <div className='col-md-6 col-12 my-3'>
          <label className='required form-label fs-6 fw-semibold text-body'>
            {intl.formatMessage({id: 'KIDLING.USERNAME'})}
          </label>
          <input
            {...formik.getFieldProps('username')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.username && formik.errors.username},
              {
                'is-valid': formik.touched.username && !formik.errors.username,
              }
            )}
            name='username'
            autoComplete='off'
            disabled
            rows={1}
          />
          {formik.touched.username && formik.errors.username && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.username}</span>
              </div>
            </div>
          )}
        </div>

        <div className='col-md-6 col-12 my-3'>
          <label className='required form-label fs-6 fw-semibold text-body'>
            {intl.formatMessage({id: 'KIDLING.EMAIL'})}
          </label>
          <input
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control  mb-3 mb-lg-0',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            name='email'
            autoComplete='off'
            disabled
            rows={1}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        <div className='col-md-6 col-12 my-3'>
          <label className='required form-label fs-6 fw-semibold text-body'>
            {intl.formatMessage({id: 'KIDLING.LANGUAGE'})}
          </label>
          <LanguageSelect
            setCurrentLang={setCurrentLang}
            value={languages?.find(
              (x: any, index: number) => index + 1 === (currentLang ?? currentUser?.languageId)
            )}
          />
          {formik.touched.language && formik.errors.language && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className='alert-danger'>{formik.errors.language}</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <Button
        color='primary'
        type='submit'
        disabled={formik.isSubmitting || isLoading}
        className='ms-auto'
      >
        {!formik.isSubmitting &&
          intl.formatMessage({
            id: 'AUTH.GENERAL.SUBMIT_BUTTON',
          })}
        {formik.isSubmitting && (
          <span className='indicator-progress' style={{display: 'block'}}>
            {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </Button>
    </form>
  )
}

export default UserInfo
