import moment from 'moment'
import {FC, ReactElement, useState} from 'react'
import {useIntl} from 'react-intl'
import './styles.scss'
import {AddToCalendarButton} from 'add-to-calendar-button-react'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../error-text/ErrorText'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import EventDialog from '../EventDialog'
import {UsersListLoading} from '../loading/UsersListLoading'
import useCalendar from '../../hooks/useCalendar'

interface Props {
  data: any
}
const EventDetailsView: FC<Props> = ({data}): ReactElement => {
  const intl = useIntl()
  const {DoDelete} = useCalendar()
  const {globalLoader} = useAppContext()
  const [showDelete, setShowDelete] = useState(false)
  const [modal, setModal] = useState<any>({showModal: false, data: null})

  return (
    <div className=' w-100 h-100'>
      {!data?.holidayName && !data?.dayOffName && (
        <div className='d-flex align-items-center justify-content-end w-100  border-bottom'>
          <AddToCalendarButton
            name={data.eventsName}
            options={['Apple', 'Google', 'Outlook.com']}
            location={data.locationName}
            startDate={moment(data.startsOn).format('yyyy-MM-DD')}
            endDate={moment(data.endsOn).format('yyyy-MM-DD')}
            startTime={moment(data.startsOn).format('HH:mm')}
            endTime={moment(data.endsOn).format('HH:mm')}
            description={data.description}
            timeZone='Europe/Berlin'
            hideTextLabelButton
            buttonsList
            trigger='click'
            styleLight='border-0 outline-0'
            lightMode='system'
          ></AddToCalendarButton>
          <a
            href='#'
            className='btn btn-sm btn-icon  me-2'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title={intl.formatMessage({id: 'COMMON.EDIT'})}
            onClick={() =>
              setModal({
                showModal: true,
                data: {...data, extendedProps: data},
                enableEdit: true,
              })
            }
          >
            <i className='fa-solid fa-pencil fs-7 m-0'></i>
          </a>
          <a
            href='#'
            className='btn btn-sm btn-icon  me-2'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title={intl.formatMessage({id: 'COMMON.DELETE'})}
            onClick={() => setShowDelete(true)}
          >
            <i className='fa-solid fa-trash fs-7 m-0'></i>
          </a>
        </div>
      )}
      <div className='d-flex flex-column mt-4 w-100 p-4 pb-0'>
        <div className='row w-100'>
          <i className='fa-solid fa-circle fs-3   mt-1 col-1' style={{color: data.colorCode}}></i>

          <div className='col-10 ms-4 ms-4'>
            <h4 className='my-0'>{data?.dayOffName ?? data?.holidayName ?? data.eventsName}</h4>
            <div className='my-0 text-muted d-flex align-items-center text-nowrap'>
              {moment(data?.dayOffDate ?? data?.holidayDate ?? data.startsOn).format(
                'dddd, MMMM DD '
              )}{' '}
              {data.startsOn && (
                <>
                  <span className='w-6px h-6px rounded-circle mx-2  bg-gray-500 d-inline-block'></span>
                  {moment(data.startsOn).format('HH:mm')}
                  {' - '}
                  {moment(data.endsOn).format('HH:mm')}
                </>
              )}
            </div>
          </div>
        </div>
        {data?.holidayName && (
          <div className='row w-100 mt-4'>
            <i className='fa-solid fa-hashtag fs-3 col-1'></i>
            <div className='col-10 ms-4'>
              <p className='my-0'>{data.numberOfDays}</p>
            </div>
          </div>
        )}
        {!data?.holidayName && !data?.dayOffName && (
          <>
            {data.locationName && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-location-dot fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data.locationName}</p>
                </div>
              </div>
            )}

            {data.roomName && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-building fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data.roomName}</p>
                </div>
              </div>
            )}

            {data.description && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-paragraph fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data.description}</p>
                </div>
              </div>
            )}

            {data.employeeName && (
              <div className='row w-100 mt-4'>
                <i className='fa-solid fa-user fs-3 col-1'></i>
                <div className='col-10 ms-4'>
                  <p className='my-0'>{data.employeeName}</p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          DoDelete(data.eventsId)
        }}
        onCancel={() => setShowDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
      {modal.showModal && <EventDialog modal={modal} setModal={setModal} />}
      {globalLoader && <UsersListLoading />}
    </div>
  )
}

export default EventDetailsView
