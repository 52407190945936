import axios, {AxiosRequestConfig} from 'axios'
import {EDUCATOR_LOGIN, LOGIN, LOGIN_ADMIN, USERS} from '../../../../config/endpoints'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
// export const LOGIN_URL = `${localStorage.getItem('apiUrl')}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export const login = async (username: string, password: string, backend: string) => {
  const config: AxiosRequestConfig = {
    method: 'post',
    url: `${backend}authentication/educator/login`,
    headers: {
      username,
      password,
    },
  }

  return axios
    .request(config)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export const loginAdmin = async (username: string, password: string) => {
  const config: AxiosRequestConfig = {
    method: 'post',
    url: LOGIN_ADMIN,
    headers: {
      username,
      password,
    },
  }

  return axios
    .request(config)
    .then((response) => response.data)
    .catch((error) => {
      console.log(error)
    })
}

export const getUserInfo = async (id: string, token: any) => {
  return axios.get(`${localStorage.getItem('apiUrl')}users/${id}`, {headers: {token}})
}

export const getEmployeeInfo = async (id: string, token: any) => {
  return axios
    .get(`${localStorage.getItem('apiUrl')}employee/listwhere/usersId=${id}`, {headers: {token}})
    .then((response) => response.data[0])
    .catch((error) => {
      console.log(error)
    })
}
