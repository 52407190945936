import {FC, useEffect} from 'react'
import {Link, Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider, useLayout} from './core'
import {useLocation} from 'react-router-dom'
import {DrawerMessenger, RightToolbar, ActivityDrawer, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import {WithChildren} from '../helpers'
import {themeModeSwitchHelper, useThemeMode} from '../partials/layout/theme-mode/ThemeModeProvider'
import {Sidebar} from './components/Sidebar'
import {useAppContext} from './core/AppContextProvider'
import PageLoader from '../../app/general-components/pageLoader/PageLoader'
import {useMediaQuery} from 'react-responsive'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import {Paper} from '@mui/material'
import {useChatContext} from '../../app/context/ChatProvider'

const MasterLayout: FC<WithChildren> = ({children}) => {
  const company = window.location.pathname.split('/')[1]
  const {classes} = useLayout()
  const {mode} = useThemeMode()
  const location = useLocation()
  const {companyMetaData, isFinishedLoadingMetaData, mobileConfiguration, tabMessagesCounts} =
    useAppContext()
  const {chatCount} = useChatContext()
  const isMobile = useMediaQuery({
    query: '(max-width:762px)',
  })
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

  if (!isFinishedLoadingMetaData) return <PageLoader />

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          {/* <div
            className='position-absolute bg-primary h-350px w-100 start-0'
            style={{top: '5rem', zIndex: -1}}
          ></div> */}

          <HeaderWrapper />
          {isMobile && (
            <Paper
              sx={{position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000}}
              elevation={3}
            >
              <BottomNavigation style={{backgroundColor: mode !== 'dark' ? '#fff' : '#000'}}>
                {mobileConfiguration[5]?.length > 0 && mobileConfiguration[5][0]?.showInHrApp && (
                  <BottomNavigationAction
                    icon={
                      <Link
                        to={company ? `/${company}/messages` : '/kidling/messages'}
                        className='btn btn-icon btn-active-light-primary btn-custom position-relative '
                      >
                        <i
                          className='fas fa-envelope  '
                          style={{
                            color:
                              mode !== 'dark' && companyMetaData.iconColor
                                ? companyMetaData.iconColor
                                : '',
                            fontSize: '2rem',
                          }}
                        ></i>

                        <span
                          className='position-absolute   translate-middle badge rounded-pill bg-danger'
                          style={{top: '0.8rem', right: '-1rem'}}
                        >
                          {tabMessagesCounts?.unread > 0 && tabMessagesCounts?.unread}
                        </span>
                      </Link>
                    }
                    value='messages'
                  />
                )}
                {/* {mobileConfiguration&&mobileConfiguration[6]?.length > 0 && mobileConfiguration[6][0]?.showInHrApp && (
                  <BottomNavigationAction
                    icon={
                      <Link
                        to={company ? `/${company}/chat` : '/kidling/chat'}
                        className='btn btn-icon btn-active-light-primary btn-custom position-relative  '
                      >
                        <i
                          className='fa-sharp fa-solid fa-comments '
                          style={{
                            color:
                              mode !== 'dark' && companyMetaData.iconColor
                                ? companyMetaData.iconColor
                                : '',
                            fontSize: '2rem',
                          }}
                        ></i>

                        <span
                          className='position-absolute   translate-middle badge rounded-pill bg-danger'
                          style={{top: '0.8rem', right: '-1rem'}}
                        >
                          {chatCount > 0 && chatCount}
                        </span>
                      </Link>
                    }
                  />
                )} */}
                <BottomNavigationAction
                  icon={
                    <Link
                      to={
                        company ? `/${company}/companyInformation` : '/kidling/companyInformation'
                      }
                      className='btn btn-icon  position-relative   '
                    >
                      <i
                        className='fa-solid fa-school '
                        style={{
                          color:
                            mode !== 'dark' && companyMetaData.iconColor
                              ? companyMetaData.iconColor
                              : '',
                          fontSize: '2rem',
                        }}
                      ></i>
                    </Link>
                  }
                />
              </BottomNavigation>
            </Paper>
          )}
          <div
            id='kt_content_container'
            className={clsx(
              'd-flex flex-column-fluid align-items-stretch px-0 px-4 mt-4',
              classes.contentContainer.join(' ')
            )}
          >
            <AsideDefault />

            <div className='wrapper d-flex flex-column flex-row-fluid  ' id='kt_wrapper'>
              <div className='flex-column-fluid' id='kt_content'>
                <div className='post' id='kt_post'>
                  <Content>
                    <Outlet />
                  </Content>
                </div>
              </div>
              {/* <Footer /> */}
            </div>
          </div>
          <Footer />
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      {/* <RightToolbar /> */}
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
