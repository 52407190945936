import React from 'react'

const TextEditableCell = ({value, onChange, rows, disabled}: any) => {
  const [mode, setMode] = React.useState('read')
  const [text, setText] = React.useState(value ?? undefined)

  React.useEffect(() => {
    setText(value)
  }, [value])

  if (mode === 'edit') {
    const handleInputChange: any = (val: any) => {
      setText(val)
    }
    const handleSaveClick = () => {
      setMode('read')
      if (onChange) {
        onChange(text)
      }
    }
    return (
      <div className='p-2px d-flex flex-column align-items-end gap-2 w-100'>
        <textarea
          rows={rows}
          value={text}
          onChange={(e: any) => handleInputChange(e.target.value)}
          className='w-100'
          disabled={disabled}
        />
        <div className='d-flex align-items-center'>
          <button
            className='btn btn-sm btn-danger mx-1 btn-shadow rounded py-1 px-1 d-flex justify-content-center align-items-center'
            onClick={() => setMode('read')}
          >
            <i className='fa-solid fa-xmark text-white ms-1'></i>
          </button>
          <button
            className='btn btn-sm btn-success btn-shadow rounded py-1 px-0 d-flex justify-content-center align-items-center'
            onClick={handleSaveClick}
          >
            <i className='fa-solid fa-check text-white ms-1'></i>
          </button>
        </div>
      </div>
    )
  }
  if (mode === 'read') {
    const handleEditClick = () => {
      if (!disabled) setMode('edit')
    }
    return (
      <div className='p-5px d-flex align-items-center w-100' onClick={handleEditClick}>
        {text ? text : '--'}
      </div>
    )
  }
  return null
}

export default TextEditableCell
