import {useIntl} from 'react-intl'
import {useAuth} from '../modules/auth'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {getEmployeeAbsenceStatistics} from '../../actions/employee'
import moment from 'moment'
import {getAttendanceTimesheet} from '../../actions/attendace'
import {getAllEventsWithRecurrents} from '../../actions/events'
import {getAllEmployeeShifts} from '../../actions/shifts'
import {Avatar} from '@mui/material'

const useDashboard = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {
    tabMessagesCounts,
    translations,
    employeeAbsenceStatistics,
    setEmployeeAbsenceStatistics,
    employeeAttendaceStatistics,
    setEmployeeAttendaceStatistics,
    absenceAdjustments,
    inboxData,
    employeePinboard,
    mobileConfiguration,
  } = useAppContext()
  const company = window.location.pathname.split('/')[1]
  const [menuData, setMenuData] = useState<any>([])
  const [weekEvents, setWeekEvents] = useState([])
  const [todayEmployeeShifts, setTodayEmployeeShifts] = useState([])

  useEffect(() => {
    if (
      (mobileConfiguration[2]?.length > 0 && mobileConfiguration[2][0]?.showInHrApp) ||
      (mobileConfiguration[3]?.length > 0 && mobileConfiguration[3][0]?.showInHrApp) ||
      (mobileConfiguration[4]?.length > 0 && mobileConfiguration[4][0]?.showInHrApp)
    )
      readEmployeeStatistics()
    if (mobileConfiguration[1]?.length > 0 && mobileConfiguration[1][0]?.showInHrApp)
      readAbsenceStatistics()
    if (mobileConfiguration[7]?.length > 0 && mobileConfiguration[7][0]?.showInHrApp) readEvents()
    if (
      (mobileConfiguration[8]?.length > 0 && mobileConfiguration[8][0]?.showInHrApp) ||
      (mobileConfiguration[9]?.length > 0 && mobileConfiguration[9][0]?.showInHrApp)
    )
      readAllShifts()
  }, [mobileConfiguration])

  useEffect(() => {
    let eventsStatArray: any = [
      {
        title: intl.formatMessage({id: 'KIDLING.HR.WEEK_EVENTS'}),
        text: <span className='badge badge-danger text-white'>{weekEvents?.length}</span>,
        icon: <i className='fa-regular fa-calendar'></i>,
        color: 'text-primary',
      },
    ]

    weekEvents?.map((i: any) =>
      eventsStatArray.push({
        title: (
          <div className='my-0 text-body d-flex align-items-center justify-content-between text-nowrap w-100'>
            <p className='m-0 p-0'>{i.eventsName} </p>
            {/* <span className='w-6px h-6px rounded-circle mx-2  bg-gray-500 d-inline-block'></span> */}
            <div className='d-flex align-items-center'>
              {moment(i.startsOn).format('MMMM DD ')}
              {i.startsOn && (
                <>
                  {'-'}
                  {moment(i.startsOn).format('HH:mm')}
                  {' - '}
                  {moment(i.endsOn).format('HH:mm')}
                </>
              )}
            </div>
          </div>
        ),
        // subtitle: moment(i.startsOn).format('DD.MM.yyyy HH:mm'),
        icon: <i className='fa-solid fa-circle fs-7' style={{color: i?.colorCode}}></i>,
        color: 'text-primary',
      })
    )
    let employeeShifts: any = [
      {
        title: intl.formatMessage({id: 'KIDLING.HR.TODAY_SHIFTS'}),
        text: <span className='badge badge-danger text-white'>{todayEmployeeShifts?.length}</span>,
        icon: <i className='fa-solid fa-calendar-check'></i>,
        color: 'text-primary',
      },
    ]
    let absenceStat: any = [
      {
        title: intl.formatMessage({id: 'KIDLING.HR.TAKEN_VACATION_DAYS'}),
        text: (
          <span className='badge badge-danger text-white'>
            {employeeAbsenceStatistics?.taken >= 0
              ? `${employeeAbsenceStatistics?.taken.toFixed(2)} d`
              : '--'}
          </span>
        ),
        icon: <i className='fa-solid fa-umbrella-beach '></i>,
        color: 'text-danger',
      },
      {
        title: intl.formatMessage({id: 'KIDLING.HR.AVAILABE_VACATION_DAYS'}),
        text: (
          <span className='badge badge-success text-white'>
            {employeeAbsenceStatistics?.available >= 0
              ? `${employeeAbsenceStatistics?.available.toFixed(2)} d`
              : '--'}
          </span>
        ),
        icon: <i className='fa-solid fa-umbrella-beach '></i>,
        color: 'text-success',
      },
    ]
    absenceAdjustments?.map((i: any) => {
      absenceStat.push({
        title: i?.employeeAbsenceEntitlementName,
        text: '',
        icon: <i className='fa-solid fa-plus '></i>,
        color: 'text-green',
      })
    })
    todayEmployeeShifts?.map((i: any) =>
      employeeShifts.push({
        title: i.employeeShiftTypeName,
        text: <span className='badge badge-success text-white'>{i.employeeShiftTypeName}</span>,
        subtitle: `${moment(i?.fromTime).format('HH:mm')} - ${moment(i?.toTime).format('HH:mm')}`,
        icon: <i className='fa-solid fa-calendar-check'></i>,
        color: 'text-primary',
      })
    )

    let messagesStat: any = [
      {
        title: 'Neu',
        text: <span className='badge badge-danger text-white'>{tabMessagesCounts?.unread}</span>,
        icon: <i className='fa-solid fa-inbox '></i>,
        color: 'text-primary',
      },
    ]

    inboxData?.items?.map((i: any, index: number) => {
      const nameArray = i.usersName
        .trim()
        .replace(/\s{2,}/g, ' ')
        .split(' ')
      if (i?.readDateTime === null)
        messagesStat.push({
          title: (
            <div className='my-0 text-body d-flex align-items-center justify-content-between text-nowrap w-100'>
              <div className='d-flex align-items-center'>
                <Avatar
                  sx={{width: 20, height: 20}}
                  src={i.imagePath}
                  alt={`${nameArray[0].charAt(0).toUpperCase()} ${
                    nameArray.length > 1 ? nameArray[1].charAt(0).toUpperCase() : ''
                  } `}
                />
                <p className='m-0 p-0 ms-2'>
                  {`${i.notificationTitle.substring(0, 25)} ${
                    i.notificationTitle?.length > 25 ? ' ...' : ''
                  }`}
                </p>
              </div>
              <div className='d-flex align-items-center'>
                {/* <span className='w-6px h-6px rounded-circle mx-2  bg-gray-500 d-inline-block'></span> */}
                {moment(i?.dateTime).format('MMMM DD - HH:mm')}
              </div>
            </div>
          ),
        })
    })

    let pinboardStat: any = [
      {
        title: 'Neu',
        text: <span className='badge badge-danger text-white'>{employeePinboard?.length}</span>,

        icon: <i className='fa-solid fa-bullhorn '></i>,
        color: 'text-primary',
      },
    ]
    employeePinboard?.map((i: any) => {
      pinboardStat.push({
        title: (
          <div className='my-0 text-body d-flex align-items-center justify-content-between text-nowrap w-100'>
            <div className='d-flex align-items-center'>
              <Avatar sx={{width: 20, height: 20}} src={i?.imagePath} alt={`SJ`} />
              <p className='m-0 p-0 ms-2'>
                {`${i.employeePinboardTitle.substring(0, 25)} ${
                  i.employeePinboardTitle?.length > 25 ? ' ...' : ''
                }`}
              </p>
            </div>

            <div className='d-flex align-items-center'>
              {/* <span className='w-6px h-6px rounded-circle mx-2  bg-gray-500 d-inline-block'></span> */}
              {moment(i?.showStartDate).format('MMMM DD')}
            </div>
          </div>
        ),
      })
    })

    setMenuData([
      ((mobileConfiguration[2]?.length > 0 && mobileConfiguration[2][0]?.showInHrApp) ||
        (mobileConfiguration[3]?.length > 0 && mobileConfiguration[3][0]?.showInHrApp) ||
        (mobileConfiguration[4]?.length > 0 && mobileConfiguration[4][0]?.showInHrApp)) && {
        url: 'clockin',
        title: intl.formatMessage({id: 'KIDLING.HR.ATTENDANCE'}),
        icon: 'fa-solid fa-person-circle-plus',
        count: '',
        show: true,
        stat: [
          {
            title: intl.formatMessage({id: 'COMMON.WEEK_WORKING_HOURS'}),
            text: (
              <span className='badge badge-success text-white'>
                {employeeAttendaceStatistics?.WorkedTitle ?? '--'}
              </span>
            ),
            icon: <i className='fa-solid fa-clock '></i>,
            color: 'text-danger',
          },
          {
            title: intl.formatMessage({id: 'KIDLING.HR.HOURS_BALANCE'}),
            text: (
              <span className='badge badge-success text-white'>
                {employeeAttendaceStatistics?.BalanceTitle ?? '--'}
              </span>
            ),
            icon: <i className='fa-solid fa-user-clock '></i>,
            color: 'text-success',
          },
          {
            title: intl.formatMessage({id: 'KIDLING.HR.ESTIMATED'}),
            text: (
              <span className='badge badge-success text-white'>
                {employeeAttendaceStatistics?.EstimatedTitle ?? '--'}
              </span>
            ),
            icon: <i className='fa-solid fa-clock '></i>,
            color: 'text-danger',
          },
        ],
        colNum:
          mobileConfiguration[1]?.length > 0 &&
          mobileConfiguration[1][0]?.showInHrApp &&
          ((mobileConfiguration[8]?.length > 0 && mobileConfiguration[8][0]?.showInHrApp) ||
            (mobileConfiguration[9]?.length > 0 && mobileConfiguration[9][0]?.showInHrApp))
            ? 'col-12 col-md-6 col-lg-4'
            : !(mobileConfiguration[1]?.length > 0 && mobileConfiguration[1][0]?.showInHrApp) &&
              !(
                (mobileConfiguration[8]?.length > 0 && mobileConfiguration[8][0]?.showInHrApp) ||
                (mobileConfiguration[9]?.length > 0 && mobileConfiguration[9][0]?.showInHrApp)
              )
            ? 'col-12'
            : 'col-12 col-md-6',
      },

      mobileConfiguration[1]?.length > 0 &&
        mobileConfiguration[1][0]?.showInHrApp && {
          url: 'vacations',
          title: intl.formatMessage({id: 'KIDLING.HR.VACATIONS'}),
          icon: 'fa-solid fa-umbrella-beach',
          count: '',
          show: true,
          stat: absenceStat,
          colNum:
            ((mobileConfiguration[2]?.length > 0 && mobileConfiguration[2][0]?.showInHrApp) ||
              (mobileConfiguration[3]?.length > 0 && mobileConfiguration[3][0]?.showInHrApp) ||
              (mobileConfiguration[4]?.length > 0 && mobileConfiguration[4][0]?.showInHrApp)) &&
            ((mobileConfiguration[8]?.length > 0 && mobileConfiguration[8][0]?.showInHrApp) ||
              (mobileConfiguration[9]?.length > 0 && mobileConfiguration[9][0]?.showInHrApp))
              ? 'col-12 col-md-6 col-lg-4'
              : !(
                  (mobileConfiguration[2]?.length > 0 && mobileConfiguration[2][0]?.showInHrApp) ||
                  (mobileConfiguration[3]?.length > 0 && mobileConfiguration[3][0]?.showInHrApp) ||
                  (mobileConfiguration[4]?.length > 0 && mobileConfiguration[4][0]?.showInHrApp)
                ) &&
                !(
                  (mobileConfiguration[8]?.length > 0 && mobileConfiguration[8][0]?.showInHrApp) ||
                  (mobileConfiguration[9]?.length > 0 && mobileConfiguration[9][0]?.showInHrApp)
                )
              ? 'col-12'
              : 'col-12 col-md-6',
        },
      ((mobileConfiguration[8]?.length > 0 && mobileConfiguration[8][0]?.showInHrApp) ||
        (mobileConfiguration[9]?.length > 0 && mobileConfiguration[9][0]?.showInHrApp)) && {
        url: 'shifts',
        title: intl.formatMessage({id: 'KIDLING.HR.SHIFTS'}),
        icon: 'fa-solid fa-calendar-check',
        count: '',
        show: true,
        stat: employeeShifts,
        colNum:
          ((mobileConfiguration[2]?.length > 0 && mobileConfiguration[2][0]?.showInHrApp) ||
            (mobileConfiguration[3]?.length > 0 && mobileConfiguration[3][0]?.showInHrApp) ||
            (mobileConfiguration[4]?.length > 0 && mobileConfiguration[4][0]?.showInHrApp)) &&
          mobileConfiguration[1]?.length > 0 &&
          mobileConfiguration[1][0]?.showInHrApp
            ? 'col-12 col-md-6 col-lg-4'
            : !(
                (mobileConfiguration[2]?.length > 0 && mobileConfiguration[2][0]?.showInHrApp) ||
                (mobileConfiguration[3]?.length > 0 && mobileConfiguration[3][0]?.showInHrApp) ||
                (mobileConfiguration[4]?.length > 0 && mobileConfiguration[4][0]?.showInHrApp)
              ) && !(mobileConfiguration[1]?.length > 0 && mobileConfiguration[1][0]?.showInHrApp)
            ? 'col-12'
            : 'col-12 col-md-6',
      },
      // {
      //   url: 'tasks',
      //   title: intl.formatMessage({id: 'KIDLING.HR.TASKS'}),
      //   icon: 'fa-solid fa-list-ul',
      //   count: '',
      //   show: true,
      //   stat: [
      //     {
      //       title: 'Aktuell',
      //       text: 'Unterrichten Sie',
      //       icon: <i className='fa-solid fa-person-chalkboard  '></i>,
      //       color: 'text-info',
      //     },
      //   ],
      // },
      // {
      //   url: 'meetings',
      //   title: intl.formatMessage({id: 'KIDLING.HR.MEETINGS'}),
      //   icon: 'fa-solid fa-handshake',
      //   count: '',
      //   show: true,
      //   stat: [
      //     {
      //       title: 'Weiter',
      //       text: '20.09.2022 13:00',
      //       icon: <i className='fa-solid fa-handshake  '></i>,
      //       color: 'text-primary',
      //     },
      //   ],
      // },
      mobileConfiguration[5]?.length > 0 &&
        mobileConfiguration[5][0]?.showInHrApp && {
          url: company ? `/${company}/messages` : '/kidling/messages',
          extrnalLink: true,
          title: intl.formatMessage({id: 'KIDLING.HR.MESSAGES'}),
          icon: 'fa-regular fa-envelope',
          count: '',
          show: true,
          colNum:
            mobileConfiguration[10]?.length > 0 && mobileConfiguration[10][0]?.showInHrApp
              ? 'col-12 col-md-6'
              : 'col-12',
          stat: messagesStat,
        },
      mobileConfiguration[10]?.length > 0 &&
        mobileConfiguration[10][0]?.showInHrApp && {
          url: company ? `/${company}/pinboard` : '/kidling/pinboard',
          extrnalLink: true,
          title: intl.formatMessage({id: 'KIDLING.HR.PINBOARD'}),
          icon: 'fa-solid fa-bullhorn',
          count: '',
          show: true,
          colNum:
            mobileConfiguration[5]?.length > 0 && mobileConfiguration[5][0]?.showInHrApp
              ? 'col-12 col-md-6'
              : 'col-12',
          stat: pinboardStat,
        },
      // {
      //   url: 'documents',
      //   title: intl.formatMessage({id: 'KIDLING.MENU.DOCUMENTS'}),
      //   icon: 'fa-solid fa-paperclip',
      //   count: '',
      //   show: true,
      //   stat: [
      //     {
      //       title: 'Neu',

      //       text: <span className='badge badge-danger text-white'>{tabMessagesCounts?.unread}</span>,

      //       icon: <i className='fa-solid fa-inbox mb-2'></i>,
      //       color: 'text-primary',
      //     },
      //   ],
      // },
      mobileConfiguration[7]?.length > 0 &&
        mobileConfiguration[7][0]?.showInHrApp && {
          url: company ? `/${company}/calendar` : '/kidling/calendar',
          extrnalLink: true,
          title: intl.formatMessage({id: 'KIDLING.MENU.CALENDER'}),
          icon: 'fa-regular fa-calendar',
          count: '',
          show: true,
          stat: eventsStatArray,
          colNum: 'col-12 col-md-6 col-lg-12',
        },
      // {
      //   url: 'team',
      //   title: intl.formatMessage({id: 'KIDLING.KIDLING.TEAM'}),
      //   icon: 'fa-solid fa-user-group',
      //   count: '',
      //   show: true,
      //   stat: [
      //     {
      //       title: 'Neu',

      //       text: (
      //         <span className='badge badge-danger text-white'>{tabMessagesCounts?.unread}</span>
      //       ),

      //       icon: <i className='fa-solid fa-inbox mb-2'></i>,
      //       color: 'text-primary',
      //     },
      //   ],
      // },
    ])
  }, [
    translations,
    employeeAttendaceStatistics,
    employeeAbsenceStatistics,
    weekEvents,
    todayEmployeeShifts,
    employeePinboard,
    mobileConfiguration,
  ])

  const readAllShifts = async () => {
    const resp = await getAllEmployeeShifts(
      null,
      moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss'),
      moment().endOf('day').format('yyyy-MM-DDTHH:mm:ss')
    )
    setTodayEmployeeShifts(resp)
  }

  const readEvents = async (dateFilter?: any) => {
    try {
      const resp = await getAllEventsWithRecurrents(
        '',
        moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss'),
        moment().endOf('week').format('yyyy-MM-DDTHH:mm:ss')
      )

      setWeekEvents(resp)
    } catch (e) {
      console.log('error', e)
    }
  }

  const readEmployeeStatistics = async () => {
    const resp = await getAttendanceTimesheet(
      moment().startOf('week').format('yyyy-MM-DD'),
      moment().endOf('week').format('yyyy-MM-DD'),
      currentUser?.employee?.employeeId || 0
    )

    setEmployeeAttendaceStatistics(resp?.data)
  }

  const readAbsenceStatistics = async () => {
    try {
      const resp = await getEmployeeAbsenceStatistics(currentUser?.employee?.employeeId)
      if (resp) setEmployeeAbsenceStatistics(resp)
      else toast.error(intl.formatMessage({id: 'KIDLING.HR.ContractDoesNotExist'}))
    } catch (e) {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    }
  }

  return {menuData}
}
export default useDashboard
