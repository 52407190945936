import react, {FC, useEffect, useMemo, useState} from 'react'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import TableContainer from '../../../../../general-components/table-container/TableContainer'
import _columns, {_mobileColumns} from './columns/_columns'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import {
  getAllMessages,
  getAllMessagesCounts,
  getAllMessagesV2,
} from '../../../../../../actions/messages'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../auth'
import InboxTableHeader from './columns/DeleteTableHeader'
import {useNavigate} from 'react-router-dom'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import ReactPlaceholder from 'react-placeholder/lib'
import {TableLoader} from '../../../../../general-components/table-loader/TableLoader'
import {useMediaQuery} from 'react-responsive'
import {MESSAGE_FOLDER_ID, MESSAGE_TYPE} from '../../../../../../config/constants'
import PaginationComponent from '../../../../../general-components/pagination/Pagination'
import DeleteTableHeader from './columns/DeleteTableHeader'

const TrashTable: FC = () => {
  const [pageSize, setPageSize] = useState(15)
  const intl = useIntl()
  const [currentPage, setCurrentPage] = useState(0)
  const [deleteData, setDeleteData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const {setTabMessagesCounts} = useAppContext()
  const {currentUser, auth} = useAuth()
  const navigate = useNavigate()
  const isMobile = useMediaQuery({
    query: '(max-width:700px)',
  })
  useEffect(() => {
    if (currentUser) readMessages()
  }, [currentPage, pageSize, currentUser])

  const handleMarkPinned = async (id: string) => {
    const data = {ids: [id], pinned: true}
    // const resp = await updateMessages(data)

    // if (resp) {
    //   readMessages()
    //   toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
    // } else {
    //   toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
    // }
  }

  const readMessages = async (filter?: string) => {
    setIsLoading(true)
    const resp = await getAllMessagesV2(
      currentUser?.usersId ?? 0,
      filter ?? '1=1',
      currentPage + 1,
      pageSize,
      MESSAGE_FOLDER_ID.Deleted,
      MESSAGE_TYPE.Message
    )

    setDeleteData(resp)
    const resp1 = await getAllMessagesCounts(currentUser?.usersId ?? 0)
    setTabMessagesCounts(resp1)
    setIsLoading(false)
  }

  const columns: any = useMemo(
    () =>
      isMobile
        ? _mobileColumns({handleMarkPinned, navigate})
        : _columns({handleMarkPinned, navigate}),
    [isMobile]
  )
  return (
    <KTCardBody className='py-1  px-2'>
      <div className='  py-1  px-0'>
        <DeleteTableHeader getAllMessages={readMessages} data={deleteData?.items} />

        <div className='  py-1 px-4'>
          {isLoading ? (
            <TableLoader colNum={4} rows={5} />
          ) : deleteData?.items?.length > 0 ? (
            <TableContainer columns={columns} data={deleteData?.items} hideCards hidePagination />
          ) : (
            <div className=' mt-8 w-100 d-flex flex-column align-items-center justify-content-center'>
              <i className='fa-sharp fa-solid fa-comments  ' style={{fontSize: '5rem'}}></i>
              <p className='mt-4 text-body fs-3'>
                {intl.formatMessage({id: 'KIDLING.MESSAGING.NO_MESSAGES_YET'})}
              </p>
            </div>
          )}

          {deleteData?.totalPages > 1 && !isLoading && (
            <PaginationComponent
              pageCount={deleteData?.totalPages}
              page={currentPage}
              setPage={setCurrentPage}
              setPageSize={setPageSize}
              pageSize={pageSize}
              total={deleteData?.totalCount}
            />
          )}
        </div>
      </div>
    </KTCardBody>
  )
}

export {TrashTable}
