/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, ReactElement, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import {useIntl} from 'react-intl'
import Select from 'react-select'
import {useAppContext} from '../../../_metronic/layout/core/AppContextProvider'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import {readFileFrom} from '../../../actions/image'
import {
  addNewEvent,
  addNewEventSchedule,
  deleteContactEvent,
  deleteKidEvent,
  getAllEventsWithRecurrents,
  getEventSchedule,
  updateEvent,
} from '../../../actions/events'
import './eventDialog.scss'
import {deleteEvent} from '../../../actions/events'
import {toast} from 'react-toastify'
import {useAuth} from '../../modules/auth'
import {selectStyle, selectStyleDarkMode} from '../../utils/select-style'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../error-text/ErrorText'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {off} from 'process'
import {UsersListLoading} from '../loading/UsersListLoading'
import {useCompanyContext} from '../../../_metronic/layout/core/CompanyProvider'
import ResponsiveDatePicker from '../responsive-material-datepicker/ResponsiveDatePicker'
import {useThemeMode} from '../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

interface Props {
  modal: any
  setModal: any
}
const EventDialog: FC<Props> = ({modal, setModal}): ReactElement => {
  const intl = useIntl()
  const {auth, currentUser} = useAuth()
  const [currentTab, setCurrentTab] = useState(0)
  const [showDelete, setShowDelete] = useState(false)
  const {mode} = useThemeMode()
  const {
    attendanceLocations,
    allRooms,
    setAllEvents,
    eventsCategories,
    calendarFilter,
    calenderDateFilter,
  } = useAppContext()
  const {currentCompany, setCurrentCompany} = useCompanyContext()
  const [globalEnable, setGlobalEnable] = useState<boolean>(false)
  const [listOfDaysValues, setListOfDaysValues] = useState<any>([])
  const [preview, setPreview] = useState<any>(null)
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [base64Image, setBase64Image] = useState<any>(null)
  const [initialCategory, setInitialCategory] = useState<any>(null)
  const [kidPart, setKidPart] = useState([])
  const [groupPart, setGroupPart] = useState([])
  const [showDelete1, setShowDelete1] = useState<any>({show: false, data: null})
  const data = modal?.data?.extendedProps ? modal?.data?.extendedProps : modal?.data
  let calendarApi = data?.view?.calendar

  const [eventInitialValues, setEventInitialValues] = useState<any>({})

  const weekDays = [
    {id: 1, name: intl.formatMessage({id: 'KIDLING.HOME.MONDAY'})},
    {id: 2, name: intl.formatMessage({id: 'KIDLING.HOME.TUESDAY'})},
    {id: 3, name: intl.formatMessage({id: 'KIDLING.HOME.WEDNESDAY'})},
    {id: 4, name: intl.formatMessage({id: 'KIDLING.HOME.THURSDAY'})},
    {id: 5, name: intl.formatMessage({id: 'KIDLING.HOME.FRIDAY'})},
    {id: 6, name: intl.formatMessage({id: 'KIDLING.HOME.SATURDAY'})},
    {id: 0, name: intl.formatMessage({id: 'KIDLING.HOME.SUNDAY'})},
  ]

  const kidSchema = Yup.object().shape({
    eventsName: Yup.string()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .nullable(),
    category: Yup.mixed()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .nullable(),
    description: Yup.string(),
    fee: Yup.number().typeError(intl.formatMessage({id: 'KIDLING.INCORRECT_NUMBER'})),
    nurseryClosed: Yup.boolean(),
    indoor: Yup.boolean(),
    date: Yup.date()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    toDate: Yup.date()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'}))
      .test(
        'isValidDate',
        intl.formatMessage({id: 'KIDLING.END_TIME_MUST_BE_GREATER-THEN_START_TIME'}),
        (value: any, context: any) => {
          const totaldays = moment
            .duration(moment(value).diff(moment(context.parent.date)))
            .asDays()

          if (Math.trunc(totaldays) >= 0) {
            return true
          }
          return false
        }
      ),
    startsOn: Yup.date()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
    locationName: Yup.mixed(),
    room: Yup.mixed(),
    registerBefore: Yup.date(),

    endsOn: Yup.date()
      .required(intl.formatMessage({id: 'KIDLING.REQUIRED'}))
      .typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'}))
      .test(
        'isValidDate',
        intl.formatMessage({id: 'KIDLING.END_TIME_MUST_BE_GREATER-THEN_START_TIME'}),
        (value: any, context: any) => {
          const totalminutes = moment
            .duration(moment(value).diff(moment(context.parent.startsOn)))
            .asMinutes()

          if (totalminutes >= 0) {
            return true
          }
          return false
        }
      ),
  })

  const formik: any = useFormik({
    initialValues: eventInitialValues,
    enableReinitialize: true,
    validationSchema: kidSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {
          eventsName: values.eventsName,
          description: values.description,
          imagePath: base64Image ?? preview,
          startsOn: moment(values.date)
            .set('hours', moment(values.startsOn).hours())
            .set('minutes', moment(values.startsOn).minutes())
            .format('yyyy-MM-DDTHH:mm:ss'),
          endsOn: moment(values.toDate)
            .set('hours', moment(values.endsOn).hours())
            .set('minutes', moment(values.endsOn).minutes())
            .format('yyyy-MM-DDTHH:mm:ss'),
          registerBefore: moment(values.registerBefore).format('yyyy-MM-DDTHH:mm:ss'),
          fee: values.fee || 0,
          indoor: values.indoor,
          roomId: values?.room?.roomId || 0,
          locationName: values?.locationName?.attendanceLocationName || '',
          nurseryClosed: values.nurseryClosed,
          eventsCategoryId: values.category.value,
          employeeId: currentUser?.employee?.employeeId,
          companyId: currentCompany?.value,
        }

        const resp = modal?.data?.extendedProps
          ? await updateEvent({...data, eventsId: modal?.data?.extendedProps.eventsId})
          : await addNewEvent(data)
        if (!!resp) {
          if (listOfDaysValues.length > 0) {
            const schedule = listOfDaysValues.map((item: any) => ({
              weekdayId: item,
              eventsId: modal?.data?.extendedProps
                ? modal?.data?.extendedProps.eventsId
                : resp.eventsId,
              fromTime: moment(values.date).format('yyyy-MM-DDTHH:mm:ss'),
              toTime: moment(values.toDate).format('yyyy-MM-DDTHH:mm:ss'),
            }))
            await addNewEventSchedule(schedule)
          }

          const resp2 = await getAllEventsWithRecurrents(
            calendarFilter.filter,
            calenderDateFilter?.start
              ? moment(calenderDateFilter?.startStr).format('yyyy-MM-DDTHH:mm:ss')
              : moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss'),
            calenderDateFilter?.end
              ? moment(calenderDateFilter?.endStr).format('yyyy-MM-DDTHH:mm:ss')
              : moment().endOf('week').format('yyyy-MM-DDTHH:mm:ss')
          )

          if (!!resp2) {
            setAllEvents(resp2)
            toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
            handleClose()
          }
        } else {
          toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        }

        calendarApi?.render()

        // calendarApi?.addEvent({
        //   id: 1,
        //   title: values.eventName,
        //   start: values.startsOn,
        //   end: values.endsOn,
        //   extendedProps: {...values},
        //   allDay: false,
        // })
      } catch (ex) {
        console.log(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  useEffect(() => {
    const cat = eventsCategories?.find(
      (item: any) => item.eventsCategoryId === data?.eventsCategoryId
    )
    if (cat)
      setInitialCategory({
        value: cat?.eventsCategoryId,
        label: cat?.eventsCategoryName,
        colorCode: cat?.colorCode,
      })
  }, [eventsCategories, data])

  useEffect(() => {
    setListOfDaysValues([])
    setGlobalEnable(false)
  }, [])

  useEffect(() => {
    readEventSchedule()
  }, [data])

  useEffect(() => {
    if (modal?.data?.extendedProps?.eventsKid) {
      const groups = modal?.data?.extendedProps?.eventsKid.filter(
        (item: any) => item.classesName !== null
      )
      setGroupPart(groups)
      const kids = modal?.data?.extendedProps?.eventsKid.filter(
        (item: any) => item.kidName !== null
      )
      setKidPart(kids)
    }
  }, [data])

  useEffect(() => {
    if (modal?.data?.extendedProps) {
      setEventInitialValues({
        ...data,
        registerBefore: moment(
          data?.registerBefore ? data?.registerBefore : data?.startsOn
        ).toDate(),
        endsOn: moment(data?.endsOn).toDate(),
        startsOn: moment(data?.startsOn).toDate(),
        date: moment(data?.startsOn).toDate(),
        toDate: moment(data?.endsOn).toDate(),
        room: allRooms.find((room: any) => room.roomId === data?.roomId),
        locationName: attendanceLocations.find(
          (location: any) => location.attendanceLocationName === data?.locationName
        ),
        category: initialCategory,
      })
    } else
      setEventInitialValues((prev: any) => ({
        ...prev,
        fee: 0,
        eventsName: '',
        startsOn: data?.start || new Date(),
        date: data?.start || new Date(),
        toDate: moment(data?.start).toDate(),
        endsOn: data?.end || new Date(),
        registerBefore: data?.start || new Date(),
      }))
    setPreview(data?.imagePath)
  }, [attendanceLocations, allRooms, data, initialCategory])

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    getBase64(selectedFile)

    // free memory when ever this component is unmounted
    return () => {
      URL.revokeObjectURL(objectUrl)
    }
  }, [selectedFile])

  const handleClose = () => {
    setModal({data: null, showModal: false})
    setPreview(null)
    setBase64Image(null)
    setEventInitialValues({
      eventsName: null,
      locationName: null,
      room: '',
      description: '',
      fee: '',
      startsOn: null,
      endsOn: null,
      registerBefore: null,
      nurseryClosed: false,
      indoor: false,
    })
  }

  const getBase64 = async (file: any) => {
    const reader = new FileReader()

    reader.addEventListener('load', () => {
      return setBase64Image(reader.result)
    })

    reader.readAsDataURL(file)
  }

  const DoDelete = async () => {
    formik.setSubmitting(true)
    const resp: any = await deleteEvent(data.eventsId)
    if (resp.status === 200) {
      const resp = await getAllEventsWithRecurrents(
        calendarFilter.filter,
        calenderDateFilter?.start
          ? moment(calenderDateFilter?.startStr).format('yyyy-MM-DDTHH:mm:ss')
          : moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss'),
        calenderDateFilter?.end
          ? moment(calenderDateFilter?.endStr).format('yyyy-MM-DDTHH:mm:ss')
          : moment().endOf('week').format('yyyy-MM-DDTHH:mm:ss')
      )
      if (resp) {
        setAllEvents(resp)
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        handleClose()
      }
    }
    formik.setSubmitting(false)
  }

  const readEventSchedule = async () => {
    if (data?.eventsId) {
      setIsLoading(true)
      const resp = await getEventSchedule(data.eventsId)

      if (resp) {
        if (resp.length > 0) {
          setGlobalEnable(true)
          const days: any = []
          resp.map((item: any) => days.push(item.weekdayId))
          setListOfDaysValues(days)

          formik.setFieldValue('startdate', moment(resp[0]?.fromTime).toDate())
          formik.setFieldValue('enddate', moment(resp[0]?.toTime).toDate())
        } else {
          setGlobalEnable(false)
        }
      }
      setIsLoading(false)
    }
  }

  const DeleteParticipient = async (value: any) => {
    setIsLoading(true)
    if (value?.kid) {
      const resp = await deleteKidEvent(value?.kid)
      if (!!resp) {
        readEvents()
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      }
    } else if (value.contact) {
      const resp = await deleteContactEvent(value?.contact)
      if (!!resp) {
        readEvents()
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      }
    }
    setIsLoading(false)
    handleClose()
  }

  const readEvents = async (dateFilter?: any) => {
    try {
      const resp = await getAllEventsWithRecurrents(
        calendarFilter.filter,
        dateFilter?.start
          ? moment(dateFilter?.startStr).format('yyyy-MM-DDTHH:mm:ss')
          : moment().startOf('week').format('yyyy-MM-DDTHH:mm:ss'),
        dateFilter?.end
          ? moment(dateFilter?.endStr).format('yyyy-MM-DDTHH:mm:ss')
          : moment().endOf('week').format('yyyy-MM-DDTHH:mm:ss')
      )

      setAllEvents(resp)
    } catch (e) {
      console.log('error', e)
    }
  }

  return (
    <Modal
      isOpen={modal.showModal}
      toggle={handleClose}
      fullscreen={true}
      zIndex={1100}
      onClosed={handleClose}
      modalClassName='EventModal'
    >
      <ModalHeader toggle={handleClose}>
        {modal?.data?.extendedProps
          ? intl.formatMessage({id: 'KIDLING.HOME.EDIT_EVENT'})
          : intl.formatMessage({id: 'KIDLING.HOME.ADD_EVENT'})}
      </ModalHeader>
      <Tabs defaultActiveKey={0} id='uncontrolled-tab-example' className='my-3'>
        <Tab
          eventKey={0}
          title={
            <h5>
              {modal?.data?.extendedProps
                ? intl.formatMessage({id: 'KIDLING.HOME.EDIT_EVENT'})
                : intl.formatMessage({id: 'KIDLING.HOME.ADD_EVENT'})}
            </h5>
          }
        >
          <form
            id='kt_modal_add_user_form'
            className='form container'
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <ModalBody>
              <div className='row align-items-center'>
                <div className=' col-12'>
                  <div
                    className='image-input image-input-outline'
                    data-kt-image-input='true'
                    style={{
                      backgroundImage: preview
                        ? `url(${preview})`
                        : 'url("/media/svg/avatars/blank.svg")',
                    }}
                  >
                    <div
                      className='image-input-wrapper w-125px h-125px'
                      style={{
                        backgroundImage: preview
                          ? `url(${preview})`
                          : 'url("/media/svg/avatars/blank.svg")',
                      }}
                    ></div>

                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='change'
                      data-bs-toggle='tooltip'
                      title='Change avatar'
                    >
                      <i className='fa-solid fa-pencil'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>

                      <input
                        type='file'
                        name='avatar'
                        accept='.png, .jpg, .jpeg'
                        onChange={(e: any) =>
                          setSelectedFile(e.target?.files.length > 0 ? e.target?.files[0] : null)
                        }
                      />
                      <input type='hidden' name='avatar_remove' />
                    </label>

                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='cancel'
                      data-bs-toggle='tooltip'
                      title='Cancel avatar'
                    >
                      <i className='fa-solid fa-xmark'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>

                    <span
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='remove'
                      data-bs-toggle='tooltip'
                      title='Remove avatar'
                      onClick={() => setPreview(null)}
                    >
                      <i className='fa-solid fa-xmark'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                  </div>

                  <div className='form-text'>
                    {intl.formatMessage({id: 'KIDLING.HOME.ALLOWED_FILE_TYPES'})}: png, jpg, jpeg.
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <div className='fv-row my-7'>
                    <label className='label required form-label fs-6 fw-semibold text-body'>
                      {intl.formatMessage({id: 'KIDLING.HOME.FROMDATE'})}
                    </label>
                    <div
                      className={clsx(
                        {'is-invalid': formik.touched.date && formik.errors.date},
                        {
                          'is-valid': formik.touched.date && !formik.errors.date,
                        }
                      )}
                    >
                      <ResponsiveDatePicker
                        dateTime
                        onAccept={(val: any) => {
                          formik.setFieldValue('date', val)
                        }}
                        value={formik.values.date}
                        disabled={formik.isSubmitting || isLoading}
                      />
                    </div>
                    {formik.errors.date && (
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.date}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <div className='fv-row my-7'>
                    <label className='label required form-label fs-6 fw-semibold text-body'>
                      {intl.formatMessage({id: 'KIDLING.HOME.TODATE'})}
                    </label>
                    <div
                      className={clsx(
                        {'is-invalid': formik.touched.toDate && formik.errors.toDate},
                        {
                          'is-valid': formik.touched.toDate && !formik.errors.toDate,
                        }
                      )}
                    >
                      <ResponsiveDatePicker
                        dateTime
                        onAccept={(val: any) => {
                          formik.setFieldValue('toDate', val)
                        }}
                        value={formik.values.toDate}
                        disabled={formik.isSubmitting || isLoading}
                      />
                    </div>
                    {formik.errors.toDate && (
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.toDate}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <div className='fv-row my-7'>
                    <label className='label required form-label fs-6 fw-semibold text-body'>
                      {intl.formatMessage({id: 'KIDLING.HOME.START_TIME'})}
                    </label>
                    <div
                      className={clsx(
                        'd-flex align-items-center    mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.startsOn && formik.errors.startsOn},
                        {
                          'is-valid': formik.touched.startsOn && !formik.errors.startsOn,
                        }
                      )}
                    >
                      <ResponsiveDatePicker
                        timeOnly
                        onAccept={(val: any) => {
                          formik.setFieldValue('startsOn', val)
                        }}
                        value={formik.values.startsOn}
                        disabled={formik.isSubmitting || isLoading}
                      />
                    </div>
                    {formik.errors.endsOn && (
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.startsOn}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <div className='fv-row my-7'>
                    <label className='label required form-label fs-6 fw-semibold text-body'>
                      {intl.formatMessage({id: 'KIDLING.HOME.END_TIME'})}
                    </label>
                    <div
                      className={clsx(
                        'd-flex align-items-center     mb-3 mb-lg-0',
                        {'is-invalid': formik.touched.endsOn && formik.errors.endsOn},
                        {
                          'is-valid': formik.touched.endsOn && !formik.errors.endsOn,
                        }
                      )}
                    >
                      <ResponsiveDatePicker
                        timeOnly
                        onAccept={(val: any) => {
                          formik.setFieldValue('endsOn', val)
                        }}
                        value={formik.values.endsOn}
                        disabled={formik.isSubmitting || isLoading}
                      />
                    </div>
                    {formik.errors.endsOn && (
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.endsOn}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <div className='fv-row my-7'>
                    <label className='label   form-label fs-6 fw-semibold text-body'>
                      {intl.formatMessage({id: 'KIDLING.HOME.REGISTER_BEFORE'})}
                    </label>
                    <div
                      className={clsx(
                        'd-flex align-items-center   mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            formik.touched.registerBefore && formik.errors.registerBefore,
                        },
                        {
                          'is-valid':
                            formik.touched.registerBefore && !formik.errors.registerBefore,
                        }
                      )}
                    >
                      <ResponsiveDatePicker
                        dateTime
                        onAccept={(val: any) => {
                          formik.setFieldValue('registerBefore', val)
                        }}
                        value={formik.values.registerBefore}
                        disabled={formik.isSubmitting || isLoading}
                      />
                    </div>
                    {formik.errors.registerBefore && (
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.registerBefore}</span>
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <label className='required form-label fs-6 fw-semibold text-body'>
                    {intl.formatMessage({id: 'KIDLING.HOME.EVENT_NAME'})}
                  </label>
                  <input
                    {...formik.getFieldProps('eventsName')}
                    className={clsx(
                      'form-control  mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.eventsName && formik.errors.eventsName},
                      {
                        'is-valid': formik.touched.eventsName && !formik.errors.eventsName,
                      }
                    )}
                    name='eventsName'
                    autoComplete='off'
                    disabled={formik.isSubmitting || isLoading}
                    rows={1}
                  />
                  {formik.touched.eventsName && formik.errors.eventsName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.eventsName}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <label className=' required label  form-label fs-6 fw-semibold text-body'>
                    {intl.formatMessage({id: 'KIDLING.EVENT_CATEGORY'})}
                  </label>

                  <Select
                    openMenuOnFocus={true}
                    value={formik.values.category}
                    onChange={(val: any) => {
                      formik.setFieldValue('category', val)
                    }}
                    options={eventsCategories?.map((item: any) => ({
                      value: item.eventsCategoryId,
                      label: item.eventsCategoryName,
                      colorCode: item.colorCode,
                    }))}
                    isClearable={true}
                    formatOptionLabel={(item: any) => (
                      <div className='d-flex align-items-center'>
                        <div
                          className='  rounded-circle me-2'
                          style={{
                            width: '10px',
                            height: '10px',
                            backgroundColor: `${item.colorCode}`,
                          }}
                        ></div>
                        <p className='p-0 m-0'>{item.label}</p>
                      </div>
                    )}
                    placeholder={intl.formatMessage({id: 'KIDLING.EVENT_CATEGORY'})}
                    isDisabled={formik.isSubmitting || isLoading}
                    theme={(theme) => ({
                      ...theme,

                      colors: {
                        ...theme.colors,
                        primary25: 'var(--bs-dark-light)',
                        primary: 'var(--bs-gray)',
                        neutral0: 'var(--bs-dark-light)',
                      },
                    })}
                    styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                  />
                  {formik.errors.category && (
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.category}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <label className='  label  form-label fs-6 fw-semibold text-body'>
                    {intl.formatMessage({id: 'KIDLING.HOME.LOCATION'})}
                  </label>

                  <Select
                    openMenuOnFocus={true}
                    value={formik.values.locationName}
                    onChange={(val: any) => formik.setFieldValue('locationName', val)}
                    options={attendanceLocations}
                    isClearable={true}
                    formatOptionLabel={(item: any) => (
                      <p className='p-0 m-0'>{item.attendanceLocationName}</p>
                    )}
                    placeholder={intl.formatMessage({id: 'KIDLING.HOME.LOCATION'})}
                    isDisabled={formik.isSubmitting || isLoading}
                    theme={(theme) => ({
                      ...theme,

                      colors: {
                        ...theme.colors,
                        primary25: 'var(--bs-dark-light)',
                        primary: 'var(--bs-gray)',
                        neutral0: 'var(--bs-dark-light)',
                      },
                    })}
                    styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                  />
                  {formik.errors.locationName && (
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.locationName}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-6 col-lg-3 col-12 my-lg-0 my-7'>
                  <label className='  label  form-label fs-6 fw-semibold text-body'>
                    {intl.formatMessage({id: 'KIDLING.HOME.ROOM'})}
                  </label>
                  <Select
                    openMenuOnFocus={true}
                    value={formik.values.room}
                    onChange={(val: any) => formik.setFieldValue('room', val)}
                    options={allRooms}
                    isClearable={true}
                    formatOptionLabel={(item: any) => <p className='p-0 m-0'>{item.roomName}</p>}
                    className='w-100  '
                    placeholder={intl.formatMessage({id: 'KIDLING.HOME.ROOM'})}
                    isDisabled={formik.isSubmitting || isLoading}
                    theme={(theme) => ({
                      ...theme,

                      colors: {
                        ...theme.colors,
                        primary25: 'var(--bs-dark-light)',
                        primary: 'var(--bs-gray)',
                        neutral0: 'var(--bs-dark-light)',
                      },
                    })}
                    styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                  />
                  {formik.errors.room && (
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.room}</span>
                    </div>
                  )}
                </div>
                <div className='col-md-6 col-lg-3 col-12'>
                  <label className='   form-label fs-6 fw-semibold text-body'>
                    {intl.formatMessage({id: 'KIDLING.HOME.FEE'})}
                  </label>
                  <input
                    type='text'
                    {...formik.getFieldProps('fee')}
                    className={clsx(
                      'form-control  mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.fee && formik.errors.fee},
                      {
                        'is-valid': formik.touched.fee && !formik.errors.fee,
                      }
                    )}
                    name='fee'
                    autoComplete='off'
                    disabled={formik.isSubmitting || isLoading}
                  />
                  {formik.touched.fee && formik.errors.fee && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.fee}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid col-md-6 col-lg-3 col-12 my-lg-0 my-7'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={formik.values.indoor}
                    name='notifications'
                    onChange={(e: any) => {
                      formik.setFieldValue('indoor', e.target.checked)
                    }}
                    disabled={formik.isSubmitting || isLoading}
                  />
                  <label className='  form-check-label'>
                    {intl.formatMessage({id: 'KIDLING.HOME.INDOOR'})}
                  </label>
                </div>
                <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid col-md-6 col-lg-3 col-12 my-lg-0 my-7'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={formik.values.nurseryClosed}
                    name='notifications'
                    onChange={(e) => {
                      formik.setFieldValue('nurseryClosed', e.target.checked)
                    }}
                    disabled={formik.isSubmitting || isLoading}
                  />
                  <label className='form-check-label'>
                    {intl.formatMessage({id: 'KIDLING.HOME.NURSERY_CLOSED'})}
                  </label>
                </div>
                <div className=' col-lg-6 col-12 mt-4'>
                  <label className='  form-label fs-6 fw-semibold text-body'>
                    {intl.formatMessage({id: 'KIDLING.HOME.DESCRIPTION'})}
                  </label>
                  <textarea
                    {...formik.getFieldProps('description')}
                    className={clsx(
                      'form-control  mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.description && formik.errors.description},
                      {
                        'is-valid': formik.touched.description && !formik.errors.description,
                      }
                    )}
                    name='description'
                    autoComplete='off'
                    disabled={formik.isSubmitting || isLoading}
                    rows={1}
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.description}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className=' col-lg-6 col-12'>
                  <label className='   form-label fs-6 fw-semibold text-body'>
                    {intl.formatMessage({id: 'KIDLING.HOME.NOTES'})}
                  </label>
                  <textarea
                    {...formik.getFieldProps('notes')}
                    className={clsx(
                      'form-control  mb-3 mb-lg-0',
                      {'is-invalid': formik.touched.notes && formik.errors.notes},
                      {
                        'is-valid': formik.touched.notes && !formik.errors.notes,
                      }
                    )}
                    name='notes'
                    autoComplete='off'
                    disabled={formik.isSubmitting || isLoading}
                    rows={1}
                  />
                  {formik.touched.notes && formik.errors.notes && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span className='alert-danger'>{formik.errors.notes}</span>
                      </div>
                    </div>
                  )}
                </div>
                <hr className='hr' />
                <div className='form-check d-flex align-items-center justify-content-between'>
                  <div className='d-flex align-items-center '>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={globalEnable}
                      onChange={(e) => setGlobalEnable((prev) => !prev)}
                    />
                    <label
                      className='form-check-label text-body ms-2'
                      onClick={() => setGlobalEnable((prev) => !prev)}
                    >
                      {intl.formatMessage({id: 'KIDLING.HOME.RECURRING'})}?
                    </label>
                  </div>
                </div>
                <div className='d-flex align-items-center gap-4 my-4 flex-wrap'>
                  {weekDays.map((item: any, index: number) => (
                    <div className='form-check' key={index}>
                      <input
                        className='form-check-input '
                        type='checkbox'
                        checked={
                          listOfDaysValues.find((checkItem: number) => checkItem === item.id) >= 0
                        }
                        id={item.id}
                        disabled={!globalEnable}
                        onChange={(e) => {
                          let newList = [...listOfDaysValues]
                          const isExists = newList.find(
                            (checkItem: number) => checkItem === item.id
                          )

                          if (isExists >= 0) {
                            newList = newList.filter((checkItem: number) => checkItem !== item.id)
                          } else {
                            newList.push(item.id)
                          }

                          setListOfDaysValues(newList)
                        }}
                      />
                      <label className='form-check-label text-body'>{item.name}</label>
                    </div>
                  ))}
                </div>

                {/* end::Scroll */}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color='secondary' onClick={handleClose}>
                {intl.formatMessage({id: 'KIDLING.HOME.DISCARD'})}{' '}
              </Button>
              {modal?.data?.extendedProps && data.employeeId === currentUser?.employee?.employeeId && (
                <Button
                  color='danger'
                  type='button'
                  disabled={formik.isSubmitting || isLoading}
                  onClick={() => setShowDelete(true)}
                >
                  {intl.formatMessage({id: 'AUTH.GENERAL.DELETE'})}
                </Button>
              )}
              {modal.enableEdit && (
                <Button color='primary' type='submit' disabled={formik.isSubmitting || isLoading}>
                  {intl.formatMessage({id: 'AUTH.GENERAL.SUBMIT_BUTTON'})}
                </Button>
              )}
            </ModalFooter>
          </form>
        </Tab>
        {modal.data?.extendedProps && (
          <Tab eventKey={1} title={<h5>{intl.formatMessage({id: 'COMMON.RECIPIENTS'})}</h5>}>
            <div className='d-flex   form container py-8 gap-4 flex-wrap'>
              {/* {modal.data?.extendedProps?.employeeEvent.length > 0 && (
                <div className='d-flex flex-column border p-4'>
                  <h4 className='border-bottom-2  '>
                    {intl.formatMessage({id: 'KIDLING.EMPLOYEE'})}
                  </h4>
                  <hr />
                  {modal.data?.extendedProps.employeeEvent?.map((emp: any, index: number) => (
                    <h5 className='badge badge-info fw-semibold me-1' key={index}>
                      {emp.employeeName}
                    </h5>
                  ))}
                </div>
              )} */}
              {modal.data.extendedProps.contactEvent.length > 0 && (
                <div className='d-flex flex-column border p-4 w-md-200px'>
                  <h4 className='border-bottom-2  '>
                    {intl.formatMessage({id: 'COMMON.CONTACTS'})}
                  </h4>
                  <hr />
                  {modal.data.extendedProps.contactEvent?.map((cont: any, index: number) => (
                    <div className='d-flex align-items-center justify-content-between my-1'>
                      <h5 className='badge badge-info fw-semibold me-1 mb-0' key={index}>
                        {cont.contactName}
                      </h5>
                      <button
                        className='btn bt-sm p-0'
                        type='button'
                        onClick={() => {
                          setShowDelete1({show: true, data: {contact: cont.eventsContactId}})
                        }}
                      >
                        <i className='mx-2 fa-solid fa-trash text-danger delete__icon'></i>
                      </button>
                    </div>
                  ))}
                </div>
              )}

              {kidPart?.length > 0 && (
                <div className='d-flex flex-column border p-4   w-md-200px'>
                  <h4 className='border-bottom-2  '>
                    {intl.formatMessage({id: 'KIDLING.MESSAGE.KIDS'})}
                  </h4>
                  <hr />
                  {kidPart?.map((cont: any, index: number) => (
                    <div className='d-flex align-items-center justify-content-between my-1'>
                      <h5 className='badge badge-info fw-semibold me-1  mb-0' key={index}>
                        {cont.kidName}
                      </h5>
                      <button
                        className='btn bt-sm p-0'
                        type='button'
                        onClick={() => {
                          setShowDelete1({show: true, data: {kid: cont.eventsKidId}})
                        }}
                      >
                        <i className='mx-2 fa-solid fa-trash text-danger delete__icon'></i>
                      </button>
                    </div>
                  ))}
                </div>
              )}

              {groupPart?.length > 0 && (
                <div className='d-flex flex-column border p-4 w-md-200px'>
                  <h4 className='border-bottom-2  '>
                    {intl.formatMessage({id: 'KIDLING.HOME.GROUP_NAME'})}
                  </h4>
                  <hr />
                  {groupPart?.map((cont: any, index: number) => (
                    <div className='d-flex align-items-center justify-content-between my-1'>
                      <h5 className='badge badge-info fw-semibold me-1 mb-0' key={index}>
                        {cont.classesName}
                      </h5>
                      <button
                        className='btn bt-sm p-0'
                        type='button'
                        onClick={() => {
                          setShowDelete1({show: true, data: {kid: cont.eventsKidId}})
                        }}
                      >
                        <i className='mx-2 fa-solid fa-trash text-danger delete__icon'></i>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Tab>
        )}
      </Tabs>

      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          DoDelete()
        }}
        onCancel={() => setShowDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />

      <SweetAlert
        show={showDelete1.show}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          DeleteParticipient(showDelete1.data)
          setShowDelete1({show: false, data: null})
        }}
        onCancel={() => setShowDelete1({show: false, data: null})}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />

      {isLoading && <UsersListLoading />}
    </Modal>
  )
}

export default EventDialog
