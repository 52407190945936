import HrCard from '../../../../../general-components/hr-card'
import useDashboard from '../../../../../hooks/useDashboard'
function MainPage() {
  const {menuData} = useDashboard()

  return (
    <div className='row p-4 p-sm-8  g-6 h-100'>
      {menuData?.map(
        (item: any, index: number) =>
          item?.title && (
            <div
              className={`${item?.colNum ? `${item?.colNum}` : 'col-12 col-sm-6 col-lg-4  '}`}
              key={index}
            >
              <HrCard {...item} />
            </div>
          )
      )}
    </div>
  )
}

export default MainPage
