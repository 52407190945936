import React, {FC, ReactElement, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import Select from 'react-select'
import {useFormik} from 'formik'
import {selectStyle, selectStyleDarkMode, selectStyleNoBorder} from '../../../../../utils/select-style'
import CustomTabs from '../../../../../general-components/custom-tabs'
import DatePicker from 'react-datepicker'
import clsx from 'clsx'
import * as Yup from 'yup'
import {useIntl} from 'react-intl'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import ResponsiveDatePicker from '../../../../../general-components/responsive-material-datepicker/ResponsiveDatePicker'
import { useThemeMode } from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
const users = [
  {label: 'Muhannad hammada', value: 0},
  {label: 'Adam e', value: 1},
]

type Props = {
  show: boolean
  setShow: (v: boolean) => void
}
const AddTaskModal: FC<Props> = ({show, setShow}): ReactElement => {
  const navigate = useNavigate()
    const {mode} = useThemeMode()
  const intl = useIntl()
  const [selectedTab, setSelectedTab] = useState(0)
  const handleClose = () => {
    setShow(false)
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    description: Yup.string(),
    date: Yup.date().typeError(intl.formatMessage({id: 'KIDLING.INVALID_DATETIME'})),
  })
  const [initialValues] = useState({
    name: '',
    description: '',
    date: new Date(),
  })
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <Modal
      isOpen={show}
      toggle={handleClose}
      fullscreen={true}
      zIndex={1000}
      onClosed={handleClose}
      fade
      modalClassName='vacaionModal'
    >
      {' '}
      <form id='kt_modal_add_user_form' className='form ' onSubmit={formik.handleSubmit} noValidate>
        <ModalHeader>
          <div className='d-flex align-items-center justify-content-between w-100'>
            <a
              onClick={handleClose}
              className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              title='Back'
            >
              <i className='fa-solid fa-close fs-5 m-0'></i>
            </a>

            <button
              className='btn btn-sm btn-icon btn-custom btn-active-light-primary me-3 ms-auto d-flex align-items-center'
              type='button'
              onClick={() => setShow(true)}
            >
              <i className='fa-solid fa-check   fs-3  '></i>
            </button>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className='w-100 d-flex justify-content-center'>
            <div className='d-flex flex-column w-100 mw-500px'>
              <div className='fv-row mb-7'>
                <label className='  form-label fs-6 fw-semibold text-muted'>Aufgabe Name</label>
                <input
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  name='name'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-7'>
                <label className='  form-label fs-6 fw-semibold text-muted'>
                  Aufgabe Beschreibung
                </label>
                <textarea
                  {...formik.getFieldProps('name')}
                  className={clsx(
                    'form-control   mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  name='name'
                  autoComplete='off'
                  disabled={formik.isSubmitting}
                  rows={3}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>

              <div className='fv-row mb-7'>
                <label className='  form-label fs-6 fw-semibold text-muted'>Zugewiesen an</label>
                <Select
                  name='vacation'
                  options={users}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  isClearable
                  placeholder='Leave Type'
                  onChange={(val: any) => console.log(val)}
                  isMulti
                  styles={mode === 'dark' ? selectStyleDarkMode : selectStyle}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: 'var(--bs-dark-light)',
                      primary: 'var(--bs-gray)',
                      neutral0: 'var(--bs-dark-light)',
                    },
                  })}
                  formatOptionLabel={(item: any) => (
                    <div className='d-flex align-items-center '>
                      <AvatarWithLetters
                        image={item.image}
                        alt={`${item.label[0].charAt(0).toUpperCase()}  `}
                        rounded
                        extrasmall
                      />

                      <div className='d-flex flex-column ms-2  '>
                        <p className='p-0 m-0'>{item.label}</p>
                      </div>
                    </div>
                  )}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span className='alert-danger'>{formik.errors.name}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row my-7'>
                <label className='label  form-label fs-6 fw-semibold text-muted'>
                  {intl.formatMessage({id: 'KIDLING.HOME.DUEDATE'})}
                </label>
                <div
                  className={clsx(
                    {'is-invalid': formik.touched.date && formik.errors.date},
                    {
                      'is-valid': formik.touched.date && !formik.errors.date,
                    }
                  )}
                >
                  {' '}
                  <ResponsiveDatePicker
                    dateTime
                    onAccept={(val: any) => {
                      formik.setFieldValue('date', val)
                    }}
                    value={formik.values.date}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </form>
    </Modal>
  )
}

export default AddTaskModal
