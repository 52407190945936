import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useSearchParams} from 'react-router-dom'
import moment from 'moment'
import {useIntl} from 'react-intl'
import {UsersListLoading} from '../../../../../general-components/loading/UsersListLoading'
import AttendanceDetailsModal from './details-modal/AttendanceDetailsModal'
import useEmployeeAttendance from '../../../../../hooks/useEmployeeAttendance'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'

function SheetDay() {
  const intl = useIntl()
  const [show, setShow] = useState({show: false, data: null})
  const [searchParams] = useSearchParams()
  const {readTimeSheet, readEmployeeAttendance, isLoading} = useEmployeeAttendance()
  const {currentTimesheet, todayEmployeeAttendance} = useAppContext()
  const company = window.location.pathname.split('/')[1]

  useEffect(() => {
    readTimeSheet(
      moment(searchParams.get('date')).startOf('month').format('yyyy-MM-DD'),
      moment(searchParams.get('date')).endOf('month').format('yyyy-MM-DD')
    )
    readEmployeeAttendance(
      `${moment(searchParams.get('date')).startOf('day').format('yyyy-MM-DDTHH:mm:ss')}`,
      `${moment(searchParams.get('date')).endOf('day').format('yyyy-MM-DDTHH:mm:ss')}`
    )
  }, [searchParams])

  if (isLoading) return <UsersListLoading />
  return (
    <div className='card'>
      <div className='card-header align-items-center justify-content-between  py-5 gap-5'>
        <div className='d-flex  align-items-center justify-content-between w-100'>
          <Link
            to={company ? `/${company}/employee/clockin/sheet` : '/kidling/employee/clockin/sheet'}
          >
            <i className='fa-solid fa-arrow-left fs-3 me-4'></i>
          </Link>

          <div className='d-flex align-items-center'>
            <h5 className='mb-0'>{moment(searchParams.get('date')).format('dddd-DD MMM')}</h5>
          </div>
        </div>
      </div>
      <div className='card-body'>
        <div className='d-flex flex-column align-items-center'>
          <div className='d-flex align-items-center mt-8 w-md-400px w-100 shadow p-4 rounded'>
            <div className='row w-100 '>
              <div className='col-12'>
                <div className='d-flex flex-column align-items-center'>
                  <h1 className='  text-body' style={{fontSize: '1.8rem'}}>
                    {Math.floor((todayEmployeeAttendance?.actualHours * 60) / 60)}h{' '}
                    {Math.floor((todayEmployeeAttendance?.actualHours * 60) % 60)}m
                  </h1>
                  <p className='fs-7 text-uppercase text-body'>
                    {intl.formatMessage({id: 'KIDLING.HR.WORKING_HOURS'})}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column align-items-center w-100'>
            <div className='list-group w-100'>
              {todayEmployeeAttendance?.groupedEmployeeAttendances?.length > 0 &&
                todayEmployeeAttendance?.groupedEmployeeAttendances[0]?.attendances?.map(
                  (item: any, index: number) => (
                    <button
                      type='button'
                      onClick={() => setShow({show: true, data: item})}
                      className='list-group-item list-group-item-action'
                      key={index}
                    >
                      <div className='d-flex align-items-center'>
                        <i
                          className={`${
                            item.attendanceTypeId === 1
                              ? 'fa-regular fa-clock text-success'
                              : 'fa-solid fa-mug-hot text-danger'
                          }`}
                        ></i>
                        <p className='m-0 ms-4'>
                          <div className='d-flex flex-column'>
                            <h6 className='mb-0 '>{moment(item.date).format('DD.MM.yyyy')}</h6>
                            <p className='my-0 text-body'>
                              {moment(item.in).format('HH:mm')} -{' '}
                              {item.out && moment(item.out).format('HH:mm')}{' '}
                              {!item.out && (
                                <span className='text-bold'>
                                  <i
                                    className={`fa-solid fa-circle ${
                                      item.attendanceTypeId === 2 ? 'text-danger' : 'text-success'
                                    }`}
                                  ></i>{' '}
                                  {intl.formatMessage({id: 'KIDLING.NOW'})}
                                </span>
                              )}
                            </p>
                          </div>
                        </p>
                        <div className='d-flex align-items-center ms-auto'>
                          <span className='badge badge-primary'>{`${Math.floor(
                            item?.numberOfMinutes / 60
                          )}h 
                ${Math.floor(item?.numberOfMinutes % 60)}m`}</span>
                          <button className='btn '>
                            <i className='fa-solid fa-arrow-right'></i>
                          </button>
                        </div>
                      </div>
                    </button>
                  )
                )}
            </div>
          </div>
        </div>
      </div>
      <AttendanceDetailsModal
        show={show}
        setShow={setShow}
        readEmployeeAttendance={readEmployeeAttendance}
        readOnly={currentTimesheet?.Status === 3 || currentTimesheet?.Status === 2}
      />
    </div>
  )
}

export default SheetDay
