import React from 'react'
import moment from 'moment-timezone'
import ResponsiveDatePicker from '../responsive-material-datepicker/ResponsiveDatePicker'

const TimeEditableCell = ({value, onChange, maxDate, minDate, disabled}: any) => {
  const [mode, setMode] = React.useState('read')
  const [text, setText] = React.useState(value ?? '')

  React.useEffect(() => {
    setText(value || '')
  }, [value]) // <--- when value is changed text state is changed too
  if (mode === 'edit') {
    const handleInputChange = (val: any) => {
      setText(val)
      setMode('read')
      if (onChange) {
        onChange(val)
      }
    }
    // const handleSaveClick = () => {
    //   setMode('read')
    //   if (onChange) {
    //     onChange(text)
    //   }
    // }
    return (
      <div className='p-2px d-flex align-items-center gap-2 w-100'>
        <ResponsiveDatePicker
          timeOnly
          onAccept={handleInputChange}
          value={text}
          maxDate={maxDate}
          minDate={minDate}
        />

        {/* <button
          className='btn btn-sm btn-success btn-shadow rounded py-1 px-0 d-flex justify-content-center align-items-center'
          onClick={handleSaveClick}
        >
          <i className='fa-solid fa-check text-white ms-1'></i>
        </button> */}
      </div>
    )
  }
  if (mode === 'read') {
    const handleEditClick = () => {
      setMode('edit')
    }
    return (
      <div className='p-5px text-body' onClick={!disabled ? handleEditClick : () => {}}>
        {text ? moment(text).format('HH:mm') : '-- : --'}
      </div>
    )
  }
  return null
}

export default TimeEditableCell
