import React, {useEffect, useState} from 'react'
import MessagesSidebar from './message/MessagesSidebar'

import {InboxTable} from './components/inbox-table/InboxTable'
import MessageDetails from './message/MessageDetails'
import ComposeMessage from './message/ComposeMessage'
import {useAppContext} from '../../../../_metronic/layout/core/AppContextProvider'
import {useIntl} from 'react-intl'
import {Outlet, Route, Routes, useNavigate, useParams} from 'react-router-dom'
import TabsContent from './message/TabsContent'

import {getAllMessagesCounts} from '../../../../actions/messages'
import {useAuth} from '../../auth'

function Messages() {
  const {currentUser, auth} = useAuth()
  const {setGlobalLoader, setTabMessagesCounts, tabMessagesCounts} = useAppContext()
  const params = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    readMessages()
  }, [currentUser, auth])

  useEffect(() => {
    if (!params['*']) navigate(`inbox`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  const readMessages = async () => {
    setGlobalLoader(true)
    let resp = await getAllMessagesCounts(currentUser?.usersId ?? 0)

    setTabMessagesCounts(resp)
    setGlobalLoader(false)
  }

  return (
    <div id='kt_app_content' className='app-content flex-column-fluid'>
      <div id='kt_app_content_container' className='app-container container-xxl p-0'>
        <div className='d-flex flex-column flex-lg-row'>
          <div
            className='d-none d-lg-flex flex-column flex-lg-row-auto w-100 w-lg-350px  '
            data-kt-drawer='true'
            data-kt-drawer-name='inbox-aside'
            data-kt-drawer-activate='{default: true, lg: false}'
            data-kt-drawer-overlay='true'
            data-kt-drawer-width='300px'
            data-kt-drawer-direction='start'
            data-kt-drawer-toggle='#kt_inbox_aside_toggle'
          >
            <div
              className='card card-flush mb-0'
              data-kt-sticky='true'
              data-kt-sticky-name='inbox-aside-sticky'
              data-kt-sticky-offset="{default: false, xl: '100px'}"
              data-kt-sticky-width="{lg: '350px'}"
              data-kt-sticky-left='auto'
              data-kt-sticky-top='100px'
              data-kt-sticky-animation='false'
            >
              <MessagesSidebar />
            </div>
          </div>
          <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
            <div className='card'>
              <Routes>
                <Route element={<Outlet />}>
                  <Route path='compose' element={<ComposeMessage />} />
                  {/* <Route path='new-group' element={<NewGroup />} /> */}
                  <Route path='inbox/details/:id' element={<MessageDetails />} />
                  <Route path='sent/details/:id' element={<MessageDetails />} />
                  <Route path='marked/details/:id' element={<MessageDetails />} />
                  <Route path='trash/details/:id' element={<MessageDetails />} />
                  {/* <Route path='group-message/:id' element={<GroupChatDetails />} /> */}
                  <Route path=':tab' element={<TabsContent />} />
                </Route>
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Messages
