import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import FullCalendar from '@fullcalendar/react'
import listPlugin from '@fullcalendar/list'
import allLocales from '@fullcalendar/core/locales-all'
import {useLang} from '../../../../../../_metronic/i18n/Metronici18n'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
import ShiftDetailsModal from './TaskDetailsModal'
import TaskDetailsModal from './TaskDetailsModal'
import AddTaskModal from './AddTaskModal'

const data = [
  {
    TaskName: 'Test Task',
    assignedTo: {
      name: 'Muhannad Hammada',
      image: '',
    },
    createdBy: {
      name: 'Adam s',
      image: '',
    },
    dueDate: '30.07.2023 14:00',
  },
  {
    TaskName: 'Test Task1',
    assignedTo: {
      name: 'Muhannad Hammada',
      image: '',
    },
    createdBy: {name: 'Adam s', image: ''},
    dueDate: '28.07.2023 13:00',
  },
]
function Tasks() {
  const navigate = useNavigate()
  const company = window.location.pathname.split('/')[1]
  const [show, setShow] = useState(false)
  const [addNewModal, setAddNewModal] = useState(false)
  const intl = useIntl()
  const locale = useLang()
  return (
    <div className='card'>
      <div className='card-header align-items-center justify-content-between  py-5 gap-5 w-100'>
        <div className='d-flex align-items-center justify-content-between w-100'>
          <a
            onClick={() => navigate(-1)}
            className='btn btn-sm btn-icon btn-clear btn-active-light-primary me-3'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            title='Back'
          >
            <i className='fa-solid fa-arrow-left fs-5 m-0'></i>
          </a>

          <button
            className='btn btn-icon ms-auto d-flex align-items-center'
            onClick={() => setAddNewModal(true)}
          >
            <i className='fa-solid fa-plus ms-2 fs-3'></i>
          </button>
        </div>
      </div>
      <div className='card-body'>
        <div className='d-flex flex-column align-items-center w-100'>
          <div className='card-title my-2 w-100   mt-4  '>
            {/* begin::Search */}
            <div className='d-flex flex-column w-100  '>
              <div className='d-flex align-items-center position-relative w-100  '>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-1 position-absolute ms-6'
                />
                <input
                  type='text'
                  data-kt-user-table-filter='Suche'
                  className='form-control form-control-solid   w-100  '
                  style={{padding: '.6rem 0 .6rem 4rem'}}
                  placeholder={intl.formatMessage({id: 'COMMON.SEARCH'})}
                  //   value={searchTerm}
                  //   onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className='w-100 '>
            <FullCalendar
              plugins={[listPlugin]}
              headerToolbar={{
                left: 'title',
                center: '',
                right: 'prev,next',
              }}
              contentHeight='0'
              initialView='listDay'
              locale={locale}
              locales={allLocales}
              datesSet={() => console.log('date changed')}
              noEventsText=''
            />
          </div>
          <div className=' d-flex flex-column w-100 justify-content-center'>
            {data.map((item: any, index: number) => (
              <div
                key={index}
                className='d-flex align-items-center my-2 border-bottom py-2 justify-content-center w-100 cursor-pointer '
                onClick={() => setShow(true)}
              >
                <AvatarWithLetters
                  verysmall
                  image={item.assignedTo.image}
                  alt={`${item?.assignedTo?.name.charAt(0).toUpperCase()}`}
                />
                <div className='ms-4 d-flex justify-content-between align-items-center w-100'>
                  <h5 className='mb-0'>{item.TaskName}</h5>
                  <p className='mb-0'>{item.dueDate}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <TaskDetailsModal show={show} setShow={setShow} />
      <AddTaskModal show={addNewModal} setShow={setAddNewModal} />
    </div>
  )
}

export default Tasks
