import React, {FC, ReactElement} from 'react'
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import AvatarWithLetters from '../../../../../general-components/avatar-with-letters'
type Props = {
  show: boolean
  setShow: (v: boolean) => void
}
const TaskDetailsModal: FC<Props> = ({show, setShow}): ReactElement => {
  const handleClose = () => {
    setShow(false)
  }
  return (
    <Modal
      isOpen={show}
      toggle={handleClose}
      fullscreen={true}
      zIndex={1000}
      onClosed={handleClose}
      fade
      modalClassName='shiftModal'
    >
      {/* <ModalHeader toggle={handleClose}>Details</ModalHeader> */}
      <ModalBody>
        <div className='d-flex flex-column pb-8'>
          <div className='d-flex align-items-center mb-8'>
            <AvatarWithLetters image='/media/avatars/blank.png' alt='T' rounded verysmall />
            <div className='ms-4'>
              <h5 className='mb-0'>Lara W</h5>
              <p className='my-0 text-muted'>Projektleiter</p>
            </div>
          </div>
          <div className='row mt-8'>
            <div className='col-12 d-flex align-items-center'>
              <p className='mb-0 text-muted me-4'>erstellt von:</p>

              <div className='d-flex   p-1 text-gray-600 d-flex align-items-center'>
                <AvatarWithLetters image='/media/avatars/blank.png' alt='T' rounded verysmall />
                <div className='ms-4 d-flex   flex-column   w-100'>
                  <h5 className='mb-0'>Adam E</h5>
                  <p className='mb-0'>Projektleiter</p>
                </div>
              </div>
            </div>
          </div>

          <p className='m-0 p-1 alert alert-warning align-self-start text-gray-800 border-bottom-0 rounded-bottom-0'>
            Test Task
          </p>
          <p className='m-0 p-1 alert alert-warning align-self-start text-gray-500 bg-light rounded-top-0'>
            {' '}
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
            has been the industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type specimen book. It has
            survived not only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem Ipsum.
          </p>

          <div className='col-12 w-100  d-flex align-items-center justify-content-end  '>
            <p className='mb-0 text-muted'>Fälligkeitsdatum: </p>
            <p className='mb-0 text-muted'>17.07.2023</p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default TaskDetailsModal
