import moment from 'moment'
import {useAppContext} from '../../_metronic/layout/core/AppContextProvider'
import {
  getEmployeeAbsenceByDates,
  getEmployeeAbsenceStatistics,
  getEmployeeAbsenceStatisticsAdjustment,
  getEmployeeVacationPolicies,
} from '../../actions/employee'
import {momentDateFormat} from '../../config/constants'
import {useAuth} from '../modules/auth'
import {momentFullDateFormat} from '../utils/constants'
import {useCompanyContext} from '../../_metronic/layout/core/CompanyProvider'

const useAbsence = () => {
  const {currentUser} = useAuth()
  const {currentCompany} = useCompanyContext()
  const {
    setEmployeeAbsenceStatistics,
    setAbsenceAdjustments,
    setAllVacations,
    currentVacationsYear,
    setGlobalLoader,
    currentAbsenceType,
    setAbsencePolicies,
  } = useAppContext()

  const readAbsenceAdjustments = async () => {
    const resp = await getEmployeeAbsenceStatisticsAdjustment(
      moment().startOf('year').format(momentDateFormat),
      moment().endOf('year').format(momentDateFormat),
      currentUser?.employee?.employeeId
    )
    setAbsenceAdjustments(resp)
  }

  const readStatistics = async () => {
    const resp = await getEmployeeAbsenceStatistics(currentUser?.employee?.employeeId)
    setEmployeeAbsenceStatistics(resp)
  }
  const getVacations = async () => {
    setGlobalLoader(true)
    const resp = await getEmployeeAbsenceByDates(
      currentAbsenceType,
      moment(currentVacationsYear).format(momentFullDateFormat),
      moment(currentVacationsYear).endOf('year').format(momentFullDateFormat)
    )

    setAllVacations(resp)
    setGlobalLoader(false)
  }

  const getPolicies = async () => {
    const resp = await getEmployeeVacationPolicies(
      moment(currentVacationsYear).format(momentFullDateFormat),
      moment(currentVacationsYear).endOf('year').format(momentFullDateFormat),
      currentCompany?.value,
      currentUser?.employee?.employeeId
    )
    setAbsencePolicies(resp)
  }
  return {readStatistics, readAbsenceAdjustments, getVacations, getPolicies}
}
export default useAbsence
