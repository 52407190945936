import React, {useState, createContext, useContext, FC} from 'react'
const CompanyContext: any = createContext<any>(null)

export const useCompanyContext = (): any => {
  return useContext(CompanyContext)
}

export default function CompanyContextProvider({children}: any) {
  const [currentCompany, setCurrentCompany] = useState(null)

  return (
    <CompanyContext.Provider
      value={{
        currentCompany,
        setCurrentCompany,
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}
