import React from 'react'

function Linkify({children}: any) {
  const isUrl = (word: any) => {
    const urlPattern = /[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm
    return word.match(urlPattern)
  }

  const addMarkup = (word: any) => {
    return isUrl(word) ? `<a target="_blank" href="//${word}">${word}</a>` : word
  }

  const words = children?.split(' ')
  const formatedWords = words.map((w: any, i: any) => addMarkup(w))
  const html = formatedWords.join(' ')
  return <span dangerouslySetInnerHTML={{__html: html}} />
}

export default Linkify
