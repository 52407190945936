import {FC, ReactElement, useCallback, useEffect, useState} from 'react'
import {Modal, ModalBody} from 'reactstrap'
import '../shifts/modal.scss'
import {useAuth} from '../../../../auth'
import {useAppContext} from '../../../../../../_metronic/layout/core/AppContextProvider'
import moment from 'moment'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import SweetAlert from 'react-bootstrap-sweetalert'
import ErrorText from '../../../../../general-components/error-text/ErrorText'
import DateEditableCell from '../../../../../general-components/date-editable/DateEditableCell'
import TextEditableCell from '../../../../../general-components/text-editable/TextEditableCell'

import CustomTabs from '../../../../../general-components/custom-tabs'
import {deleteAbsenceOfEmployee, updateAbsencesOfEmployee} from '../../../../../../actions/employee'
import {Dayjs} from 'dayjs'
import {Avatar} from '@mui/material'
type dialogProps = {
  show: boolean
  data: any
}
type Props = {
  show: dialogProps
  setShow: (v: dialogProps) => void
  getVacations?: () => void
}
const VacationDetailsModal: FC<Props> = ({show, setShow, getVacations}): ReactElement => {
  const {currentUserImage, execludedDates, openingHours, absencePolicies, absenceAdjustments} =
    useAppContext()
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [currentData, setCurrentData] = useState(show?.data)
  const [remarks, setRemarks] = useState(show?.data?.employeeAbsenceEmployeeComments)
  const [showDelete, setShowDelete] = useState(false)
  const intl = useIntl()
  const {currentUser} = useAuth()

  useEffect(() => {
    setRemarks(show?.data?.employeeAbsenceEmployeeComments)
    setCurrentData(show?.data)
  }, [show?.data])

  const handleClose = () => {
    setShow({show: false, data: null})
  }

  const saveChanges = async () => {
    setIsLoading(true)

    const data: any = {
      employeeAbsenceId: currentData.employeeAbsenceId,
      employeeAbsenceReasonId: currentData?.employeeAbsenceReasonId,
      employeeAbsenceName: currentData?.employeeAbsenceName,
      employeeAbsenceEmployeeComments: remarks,
      dayAbsenceType: currentData?.dayAbsenceType,
      employeeAbsenceFromDate: currentData?.employeeAbsenceFromDate,
      employeeAbsenceToDate: currentData.employeeAbsenceToDate,
      // employeeAbsencePolicyId: currentData.employeeAbsencePolicyId,
    }
    if (!currentData.reason?.deductAbsenceDaysQuota) {
      data.employeeAbsenceAdjustmentId = 0
      data.employeeAbsencePolicyId = 0
    } else if (currentData?.employeeAbsencePolicy?.employeeAbsenceAdjustmentId)
      data.employeeAbsenceAdjustmentId =
        currentData?.employeeAbsencePolicy?.employeeAbsenceAdjustmentId
    else if (currentData?.employeeAbsencePolicy?.employeeAbsencePolicyId)
      data.employeeAbsencePolicyId = currentData?.employeeAbsencePolicy?.employeeAbsencePolicyId

    const resp = await updateAbsencesOfEmployee(data)
    if (!!resp) {
      if (getVacations) getVacations()
      toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
      setIsLoading(false)
      handleClose()
    } else {
      toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
      setIsLoading(false)
    }
  }

  const handleDelete = async () => {
    if (show?.data?.employeeAbsenceId) {
      setIsDeleting(true)
      const resp = await deleteAbsenceOfEmployee(Number(show?.data?.employeeAbsenceId))

      if (resp?.status === 200) {
        if (getVacations) getVacations()
        toast.success(intl.formatMessage({id: 'COMMON.SUCCESS'}))
        setIsDeleting(false)
        handleClose()
      } else {
        toast.error(intl.formatMessage({id: 'COMMON.FAIL'}))
        setIsDeleting(false)
      }
    }
  }

  const shouldDisableDate = useCallback(
    (date: Dayjs) => {
      let dateExecluded = false
      execludedDates.map((item: any) => {
        if (
          moment(date.toDate()).isSameOrAfter(moment(item.start)) &&
          moment(date.toDate()).isSameOrBefore(moment(item.end))
        ) {
          dateExecluded = true
        }
      })
      if (!dateExecluded) {
        const day = date.day()

        if (openingHours.length === 0) {
          return day === 0 || day === 6
        } else {
          const exists = openingHours.find((item: any) => item.weekdayId === day)
          return exists ? false : true
        }
      } else {
        return true
      }
    },
    [openingHours, execludedDates]
  )

  return (
    <Modal
      isOpen={show.show}
      toggle={handleClose}
      fullscreen={true}
      zIndex={1000}
      onClosed={handleClose}
      fade
      modalClassName='shiftModal'
    >
      <ModalBody>
        <div className='d-flex w-100 justify-content-center '>
          <div className='d-flex  flex-column align-items-start  mw-400px w-100'>
            <div className='d-flex align-items-center mb-1 w-100'>
              <Avatar
                src={currentUserImage ?? '/media/avatars/blank.png'}
                alt={currentUser?.employee?.employeeName.charAt(0).toUpperCase()}
              />
              <div className='ms-4'>
                <h5 className='mb-0 ms-1'>{currentUser?.employee?.employeeName}</h5>
                {show?.data?.employeeApprovals?.length > 0 && (
                  <div className='badge badge-primary fw-semibold mt-1 ms-1 '>
                    {show?.data?.employeeApprovals[0]?.statusName}
                  </div>
                )}
              </div>
              <div className='ms-auto d-flex align-items-center'>
                <div
                  className={`d-flex align-items-center alert alert-info px-4 border-0 rounded mb-0 mx-1 py-1`}
                  role='alert'
                >
                  {show?.data?.numberOfDays} {intl.formatMessage({id: 'KIDLING.DAY'})}
                </div>

                <div
                  className={`d-flex align-items-center alert alert-info  px-4 border-0 rounded mb-0 py-1`}
                  role='alert'
                >
                  {/* {reason} */}
                  {show?.data?.reason?.requireApproval && (
                    <i className='fa-solid fa-circle-check text-primary me-1'></i>
                  )}
                  {show?.data?.reason?.employeeAbsenceReasonName}{' '}
                  <i className={` ${'fa-solid fa-umbrella-beach text-info ms-2'}  `}></i>
                </div>
              </div>
            </div>
            <div className='my-4 w-100'>
              <CustomTabs
                value={currentData?.numberOfDays > 0.5 ? 1 : 0}
                onChange={(val: any) => {
                  setCurrentData((prev: Date) => ({
                    ...prev,
                    dayAbsenceType: val,
                  }))
                }}
                options={[
                  intl.formatMessage({id: 'KIDLING.HALF_DAY'}),
                  intl.formatMessage({id: 'KIDLING.DAY'}),
                ]}
                disabled={
                  moment
                    .duration(
                      moment(show?.data?.employeeAbsenceFromDate)
                        .startOf('day')
                        .diff(moment().startOf('day'))
                    )
                    .asDays() <= 0
                }
                fullWidth
              />
            </div>

            <div className='row mt-4 w-100'>
              <div className='col-12 pe-0 me-0'>
                <p className='mb-0 text-body'>{intl.formatMessage({id: 'KIDLING.HOME.DATE'})}</p>
                <div className='d-flex align-items-center'>
                  <div className='d-flex bg-light rounded p-1 text-gray-600 me-2'>
                    <DateEditableCell
                      value={moment(currentData?.employeeAbsenceFromDate)}
                      onChange={(val: any) => {
                        setCurrentData((prev: Date) => ({
                          ...prev,
                          employeeAbsenceFromDate: moment(val).format('yyyy-MM-DDTHH:mm:ss'),
                        }))
                      }}
                      disabled={
                        moment
                          .duration(
                            moment(show?.data?.employeeAbsenceFromDate)
                              .startOf('day')
                              .diff(moment().startOf('day'))
                          )
                          .asDays() <= 0
                      }
                      shouldDisableDate={shouldDisableDate}
                    />
                  </div>
                  <i className='fa-solid fa-angles-right mx-2'></i>
                  <div className='d-flex bg-light rounded p-1 text-gray-600 ms-2'>
                    <DateEditableCell
                      value={moment(currentData?.employeeAbsenceToDate)}
                      onChange={(val: any) => {
                        setCurrentData((prev: Date) => ({
                          ...prev,
                          employeeAbsenceToDate: moment(val).format('yyyy-MM-DDTHH:mm:ss'),
                        }))
                      }}
                      minDate={moment(currentData?.employeeAbsenceFromDate).toDate()}
                      disabled={
                        moment
                          .duration(
                            moment(show?.data?.employeeAbsenceFromDate)
                              .startOf('day')
                              .diff(moment().startOf('day'))
                          )
                          .asDays() <= 0
                      }
                      shouldDisableDate={shouldDisableDate}
                    />
                  </div>
                </div>

                <p className='mb-0 mt-2 text-body'>
                  {intl.formatMessage({id: 'KIDLING.HOME.TITLE'})}
                </p>
                <div className='d-flex align-items-center'>
                  <div className='d-flex bg-light rounded p-1 text-gray-600   w-100'>
                    <TextEditableCell
                      rows={1}
                      value={currentData?.employeeAbsenceName}
                      onChange={(val: any) => {
                        setCurrentData((prev: Date) => ({
                          ...prev,
                          employeeAbsenceName: val,
                        }))
                      }}
                      disabled={
                        moment
                          .duration(
                            moment(show?.data?.employeeAbsenceFromDate)
                              .startOf('day')
                              .diff(moment().startOf('day'))
                          )
                          .asDays() <= 0
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='my-3'>
              {absencePolicies &&
                currentData?.reason?.deductAbsenceDaysQuota &&
                absencePolicies?.map((p: any, index: number) => (
                  <label
                    key={index}
                    onClick={() => {
                      setCurrentData((prev: Date) => ({
                        ...prev,
                        employeeAbsencePolicy: p,
                      }))
                    }}
                  >
                    <input
                      className={`form-check-input  m-2 me-0`}
                      type='radio'
                      name='vacationFilter'
                      checked={currentData?.employeeAbsencePolicy === p}
                    />

                    <span className='btn btn-sm btn-color-muted   px-4'>
                      {p?.employeeAbsencePolicyName}
                    </span>
                  </label>
                ))}
              {absenceAdjustments &&
                currentData?.reason?.deductAbsenceDaysQuota &&
                absenceAdjustments?.map((p: any, index: number) => (
                  <label
                    key={index}
                    onClick={() => {
                      setCurrentData((prev: Date) => ({
                        ...prev,
                        employeeAbsencePolicy: p,
                      }))
                    }}
                  >
                    <input
                      className={`form-check-input  m-2 me-0`}
                      type='radio'
                      name='vacationFilter'
                      checked={currentData?.employeeAbsencePolicy === p}
                    />

                    <span className='btn btn-sm btn-color-muted   px-4'>
                      {p?.employeeAbsenceEntitlementName}
                    </span>
                  </label>
                ))}
            </div>

            <div className=' col-12 my-3'>
              <label className=' form-label fs-6 fw-semibold text-body'>
                {intl.formatMessage({id: 'PARENT.Remarks'})}
              </label>
              <textarea
                className={'form-control  mb-3 mb-lg-0'}
                name='remarks'
                autoComplete='off'
                value={remarks}
                rows={3}
                onChange={(e: any) => setRemarks(e.target.value)}
                disabled={
                  moment
                    .duration(
                      moment(show?.data?.employeeAbsenceFromDate)
                        .startOf('day')
                        .diff(moment().startOf('day'))
                    )
                    .asDays() <= 0
                }
              />
            </div>
            {currentData?.employeeAbsenceFromDate &&
            moment
              .duration(
                moment(show?.data?.employeeAbsenceFromDate)
                  .startOf('day')
                  .diff(moment().startOf('day'))
              )
              .asDays() > 0 ? (
              <div className='d-flex align-items-center w-100'>
                <button
                  type='button'
                  className='btn btn-danger my-4 w-100 me-2'
                  onClick={() => setShowDelete(true)}
                >
                  {!isDeleting ? (
                    <i className='fa-solid fa-trash fs-3  '></i>
                  ) : (
                    <span className='indicator-progress ' style={{display: 'inline-block'}}>
                      {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
                <button
                  type='button'
                  className='btn btn-success my-4 w-100'
                  onClick={() => saveChanges()}
                >
                  {!isLoading ? (
                    <i className='fa-solid fa-check fs-3  '></i>
                  ) : (
                    <span className='indicator-progress ' style={{display: 'inline-block'}}>
                      {intl.formatMessage({id: 'KIDLING.HOME.PLEASE_WAIT'})}...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            ) : (
              <div className='alert alert-danger w-100'>
                {intl.formatMessage({
                  id: 'KIDLING.HR.CANNOT_EDIT_AFTER_PASSED',
                })}
              </div>
            )}
          </div>
        </div>
      </ModalBody>
      <SweetAlert
        show={showDelete}
        warning
        showCancel
        confirmBtnText={intl.formatMessage({id: 'COMMON.YES'})}
        cancelBtnText={intl.formatMessage({id: 'COMMON.CANCEL'})}
        confirmBtnBsStyle='danger'
        title={<ErrorText text={intl.formatMessage({id: 'KIDLING.MENU.ARE_YOU_SURE?'})} />}
        focusCancelBtn
        onConfirm={(e) => {
          setShowDelete(false)
          handleDelete()
        }}
        onCancel={() => setShowDelete(false)}
        style={{
          backgroundColor: 'var(--bs-body-bg)',
          boxShadow: '0 0.4rem 0.8rem -0.1rem rgb(0 32 128 / 10%), 0 0 0 1px var(--bs-body-bg)',
        }}
      />
    </Modal>
  )
}

export default VacationDetailsModal
